/*=========================================================================================
  File Name: i18n.js
  Description: i18n configuration file. Imports i18n data.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import VueI18n from 'vue-i18n'
import i18nData from './i18nData'
import tw from './tw'
import en from './en'
import cn from './cn'

Vue.use(VueI18n)

const message = {
  en: {
    ...i18nData.en,
    ...en
  },
  cn: {
    ...i18nData.cn,
    ...cn
  },
  tw: {
    ...i18nData.tw,
    ...tw
  },
}

export default new VueI18n({
  locale: 'tw', // set default locale
  messages: message,
  // messages: i18nData,
})
