import html2Canvas from "html2canvas";
import JsPDF from "jspdf";
/**
 * 用法：this.$getPdf("id","PDF名字");
 * @param {String} idStr 要打印的DOM区域 id
 * @param {String} title 导出的pdf文件名称
 */
const getPdf = function(idStr, title) {
  html2Canvas(document.querySelector("#" + idStr), {
    allowTaint: true
  }).then(function(canvas) {
    // let contentWidth = canvas.width;
    // let contentHeight = canvas.height;
    // let pageHeight = (contentWidth / 592.28) * 841.89;
    // let leftHeight = contentHeight;
    // let position = 0;
    // let imgWidth = 595.28;
    // let imgHeight = (592.28 / contentWidth) * contentHeight;
    // let pageData = canvas.toDataURL("image/jpeg", 1.0);
    // let PDF = new JsPDF("", "pt", "a4");
    // if (leftHeight < pageHeight) {
    //   PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
    // } else {
    //   while (leftHeight > 0) {
    //     PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
    //     leftHeight -= pageHeight;
    //     position -= 841.89;
    //     if (leftHeight > 0) {
    //       PDF.addPage();
    //     }
    //   }
    // }

    const PDF = new JsPDF("", "pt", "a4");
    const pageData = canvas.toDataURL("image/png");
    PDF.addImage(pageData, 'PNG', 0, 0, PDF.internal.pageSize.width, canvas.height * PDF.internal.pageSize.width / canvas.width);
    PDF.save(title + ".pdf");
  });
};

export default { getPdf };
