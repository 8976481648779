import _ from 'lodash'
import route from 'vue-router'
import Vue from 'vue'
import common from '@/utils/common.js'
import {
    login,
    logout,
    getInfo,
    fetchQueue,
    getProfile,
    updateExperience,
    fetchAllDoctor,
    fetchContact,
    saveContact,
    removeContact,
    fetchCallInfo,
    updateMyService,
    updateMyServiceLang,
    fetchPhotoPath,
    fetchEmrImage,
    fetchWords,
    addWord,
    editWord,
    delWord,
    fetchNotes,
    addNote,
    updateNote,
    delNote,
    fetchPersonBaseSign,
    fetchPersonBaseSign0,
    registerUser,
    fetchChatContactsDoctor,
    fetchChatContactsGroup,
    fetchEmployees,
    fetchToMeTodoList,
    fetchMyTodoList,
    fetchAgentReservation,
    crmMembers,
    updatePatientBadge,
    searchOpinion,
    fetchSpecialReservation,
    getLeave,
    getQueueDetailByQid,
    getQueueHistory,
    checkDeviceToken,
    getRxString,
    getDoctorStatus,
    fetchChatContacts,
    fetchChatData,
    fetchChatDataDoctor,
    saveChatData,
    fetchEMRBaseData,
    updateHistory,
    getHistoryPersonSick,
    updateDoctorStatus,
    updatecallinfostatus,
    fetchPersonSign,
    fetchPersonSign2,
    callout,
    callout2,
    updateServiceFee,
    updateDoctorUnit,
    getRoom,
    getRoomMembers,
    getVIPMembers,
    fetchICD10,
    fetchPartnerEnterprise,
    fetchRefsHospital,
    fetchHospital,
    addRefHospital,
    getMRIDbyqid,
    fetchQueueDetail,
    fetchMyOpinion,
    fetchOpinion,
    fetchMyLog,
    fetchOtherDayReservation,
    fetchemergencyReservation,
    fetchOpinionCount,
    getCountByTodo,
    updateMyNurse,
    getAllNurse,
    getAllNursebind,
    getAllDoctorbind,
    getUIAPartnrtList,
    getCountByUnreadMsg,
    getCountByUnreadNotify,
    getCountByUnreadSchedule,
    updateBadge,
    partnerPatientRecordAuthorize,
    getConfig,
    PatientStateFromMcu,
    fetchMedicalTitle,
    updateMedicalTitleId,
    sendRoomBySms,
    sendRoomByLINE,
    sendWeChat,
    fetchApptUndoneQuantity,
    fetchDietChartData,
    fetchSRQuantity,
    getMemberFilter,
    getMemberList,
    QueryDoctorListByPartner,
    ContactsForReservation,
    callByPiepie,
    getQueuesUndoneQuantity,
} from '../api/user'
import { app } from '../main.js'
const actions = {
    // Vertical NavMenu
    updateVerticalNavMenuWidth({ commit }, width) {
        commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },
    // VxAutoSuggest
    updateStarredPage({ commit }, payload) {
        commit('UPDATE_STARRED_PAGE', payload)
    },
    // The Navbar
    arrangeStarredPagesLimited({ commit }, list) {
        commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },
    arrangeStarredPagesMore({ commit }, list) {
        commit('ARRANGE_STARRED_PAGES_MORE', list)
    },
    // /////////////////////////////////////////////
    // UI
    // /////////////////////////////////////////////
    toggleContentOverlay({ commit }) {
        commit('TOGGLE_CONTENT_OVERLAY')
    },
    updateTheme({ commit }, val) {
        commit('UPDATE_THEME', val)
    },
    removeContact({}, payload) {
        return new Promise((resolve, reject) => {
            removeContact(payload)
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    appendContact({}, payload) {
        return new Promise((resolve, reject) => {
            saveContact(payload)
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchContact({ commit }) {
        return new Promise((resolve, reject) => {
            fetchContact()
                .then((res) => {
                    if (res.data != undefined) {
                        if (res.data.status === 'OK') {
                            var tmpcontacts = res.data.data
                            var tmpdata = []
                            for (var v in tmpcontacts) {
                                tmpdata.push(tmpcontacts[v])
                            }
                            commit('SET_CONTACTS', tmpdata)
                            commit('SET_CONTACTSALL', tmpcontacts)
                            resolve(res)
                        }
                    }
                    reject()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    fetchProxy({ commit }) {
        return new Promise((resolve, reject) => {
            const payload = {
                partnerid: localStorage.getItem('partnerid'),
                query: '',
            }
            ContactsForReservation(payload)
                .then((res) => {
                    // console.log('my contact ', res)
                    if (res.data != undefined) {
                        if (res.data.status === 'OK') {
                            var tmpcontacts = res.data.data
                            var tmpdata = []
                            for (var v in tmpcontacts) {
                                tmpdata.push(tmpcontacts[v])
                            }
                            commit('SET_PROXY', tmpdata)
                            resolve(res)
                        }
                    }
                    reject()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchAllDoctor({ commit }) {
        return new Promise((resolve, reject) => {
            fetchAllDoctor()
                .then((res) => {
                    if (res.data.status === 'OK') {
                        commit('SET_ALL_DOCTOR', res.data.data)
                        resolve(res)
                    }
                    reject()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateExperience({}, payload) {
        // console.log('Experience ', payload)
        return new Promise((resolve, reject) => {
            updateExperience(payload)
                .then((res) => {
                    if (res.data.status === 'OK') {
                        // commit('UPDATE_EXPERIENCE', payload)
                        resolve(res)
                    }
                    reject()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateMyService({}, payload) {
        return new Promise((resolve, reject) => {
            updateMyService({ data: payload })
                .then((res) => {
                    if (res.data.status === 'OK') {
                        resolve(res)
                    }
                    reject(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateMyNurse({}, payload) {
        return new Promise((resolve, reject) => {
            updateMyNurse(payload)
                .then((res) => {
                    if (res.data.status === 'OK') {
                        resolve(res)
                    }
                    reject(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateMyServiceLang({}, payload) {
        return new Promise((resolve, reject) => {
            updateMyServiceLang({ data: payload })
                .then((res) => {
                    if (res.data.status === 'OK') {
                        resolve(res)
                    }
                    reject(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateServiceFee({}, payload) {
        return new Promise((resolve, reject) => {
            updateServiceFee(payload)
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    //取 待辦事項
    fetchToMeTodoList({ commit }, page) {
        return new Promise((resolve, reject) => {
            fetchToMeTodoList(page)
                .then((res) => {
                    commit('SET_TODOLIST', res.data.items)
                    commit('SET_TOMETODOLIST_TOTAL', res.data.total)
                    commit('SET_TODO_UNFINISH_TOTAL', res.data.count)
                    commit('SET_TOMETODOLIST_PAGE', page)
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    //取 交辦事項
    fetchMyTodoList({ commit }, page) {
        return new Promise((resolve, reject) => {
            fetchMyTodoList(page)
                .then((res) => {
                    commit('SET_MYTODOLIST', res.data.items)
                    commit('SET_MYTODOLIST_TOTAL', res.data.total)
                    commit('SET_MYTODO_UNFINISH_TOTAL', res.data.count)
                    commit('SET_MYTODOLIST_PAGE', page)
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    //取 被邀請會診項目
    fetchMyOpinion({ commit }, page) {
        return new Promise((resolve, reject) => {
            fetchMyOpinion(page)
                .then((res) => {
                    // console.log('opinion ', res)
                    commit('SET_MYOPINION', res.data.items)
                    commit('SET_MYOPINION_TOTAL', res.data.total)
                    commit('SET_OPINION_ACTIVE_COUNT', res.data.opinion_status_total)
                    commit('SET_OPINION_ACTIVE_COUNT_INTOTAL', res.data.opinion_status_total)
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    //取 邀請會診項目
    fetchOpinion({ commit }, page) {
        return new Promise((resolve, reject) => {
            fetchOpinion(page)
                .then((res) => {
                    // console.log('opinion ',  res.data)
                    commit('SET_OPINION', res.data.items)
                    commit('SET_OPINION_TOTAL', res.data.total)
                    commit('SET_OPINION_UNFINISH_TOTAL', res.data.opinion_status_total)
                    commit('SET_OPINION_UNFINISH_TOTAL_INTOTAL', res.data.opinion_status_total)
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    //取 操作紀錄
    fetchMyLog({ commit }, page) {
        return new Promise((resolve, reject) => {
            fetchMyLog(page)
                .then((res) => {
                    // console.log('SET_MYLOG', res.data.data)
                    // console.log('SET_MYLOG_TOTAL', res.data.total)
                    commit('SET_MYLOG', res.data.data)
                    commit('SET_MYLOG_TOTAL', res.data.total)
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    //POST 搜尋日期 to 被邀請會診項目
    dateSearchMyOpinion({ commit, state }) {
        var alldata = {
            query_start_date_time: state.myopinion_start_date_time,
            query_end_date_time: state.myopinion_end_date_time,
            page: state.myOpinionPage,
        }
        searchOpinion(alldata, 1)
            .then((resp) => {
                commit('SET_MYOPINION', resp.data.data)
                commit('SET_MYOPINION_TOTAL', resp.data.total)
                commit('SET_OPINION_ACTIVE_COUNT', resp.data.opinion_status_total)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    //POST 搜尋日期 to 邀請會診項目
    dateSearchOpinion({ commit, state }) {
        var alldata = {
            query_start_date_time: state.opinion_start_date_time,
            query_end_date_time: state.opinion_end_date_time,
            page: state.OpinionPage,
        }
        searchOpinion(alldata, 2)
            .then((resp) => {
                commit('SET_OPINION', resp.data.data)
                commit('SET_OPINION_TOTAL', resp.data.total)
                commit('SET_OPINION_UNFINISH_TOTAL', resp.data.opinion_status_total)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    fetchEmployees({ commit }, payload) {
        return new Promise((resolve, reject) => {
            fetchEmployees(payload)
                .then((res) => {
                    commit('SET_PARTNER_EMPLOYEES', res.data.data)
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    //以選取的護理師資料
    fetchMyNurse({ commit }, payload) {
        return new Promise((resolve, reject) => {
            getAllNursebind(payload).then((res) => {
                if (res.data.status === 'OK') {
                    commit('SET_NURSE', res.data.data)
                    resolve(res)
                } else {
                    commit('SET_NURSE', [])
                }
            })
        })
    },
    //所有護理師資料
    fetchallNurse({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            getAllNurse().then((res) => {
                if (res.data.status === 'OK') {
                    commit('SET_ALL_NURSE', res.data.data)
                    resolve(res)
                }
            })
        })
    },
    // 取其他醫事人員已授權的班表
    fetchMyDoctor({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            getAllDoctorbind(payload).then((res) => {
                if (res.data.status === 'OK') {
                    commit('SET_DOCTOR', res.data.data)
                    resolve(res)
                } else {
                    commit('SET_DOCTOR', [])
                }
            })
        })
    },
    fetchProfile({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            getProfile()
                .then((res) => {
                    var language = localStorage.getItem('language')
                    if (res.data.status === 'OK') {
                        var tmpservice = []
                        var tmpserviceLang = []
                        var tmpserviceLangrs = []
                        if (res.data.data[0]) {
                            commit('SET_PROFILE', res.data.data[0])
                        } else {
                            commit('SET_PROFILE', { account: '', name: '' })
                        }
                        if (res.data.data[0].division) {
                            commit('SET_DIVISION', res.data.data[0].division)
                        } else {
                            commit('SET_DIVISION', [])
                        }
                        if (res.data.data[0].experience) {
                            commit('SET_EXPERIENCE', res.data.data[0].experience)
                        } else {
                            commit('SET_EXPERIENCE', {})
                        }
                        if (res.data.data[0].servicelang) {
                            tmpserviceLang = res.data.data[0].servicelang
                            tmpserviceLang.forEach(function (v) {
                                if (v.lang == 'zh_TW') {
                                    tmpserviceLangrs.push('繁體中文')
                                } else if (v.lang == 'zh_CN') {
                                    tmpserviceLangrs.push('简体中文')
                                } else if (v.lang == 'en') {
                                    tmpserviceLangrs.push('English')
                                } else if (v.lang == 'kh_KH') {
                                    tmpserviceLangrs.push('Cambodia')
                                } else if (v.lang == 'en_PH') {
                                    tmpserviceLangrs.push('Philippines')
                                } else if (v.lang == 'th_TH') {
                                    tmpserviceLangrs.push('Thailand')
                                } else if (v.lang == 'vi_VN') {
                                    tmpserviceLangrs.push('Việt Nam')
                                } else if (v.lang == 'en_BI') {
                                    tmpserviceLangrs.push('Bahasa Indonesia')
                                } else if (v.lang == 'en_BM') {
                                    tmpserviceLangrs.push('Bahasa Melayu')
                                }
                            })
                        }
                        if (res.data.data[0].onsite == 1) {
                            if (language == 'en') {
                                tmpservice.push('Out-patient')
                            } else if (language == 'zh_CN') {
                                tmpservice.push('到院看诊')
                            } else {
                                tmpservice.push('到院看診')
                            }
                        }
                        if (res.data.data[0].video == 1) {
                            if (language == 'en') {
                                tmpservice.push('Video')
                            } else if (language == 'zh_CN') {
                                tmpservice.push('视讯咨询')
                            } else {
                                tmpservice.push('視訊諮詢')
                            }
                        }
                        if (res.data.data[0].freeDiagnosis == 1) {
                            if (language == 'en') {
                                tmpservice.push('Onsite')
                            } else if (language == 'zh_CN') {
                                tmpservice.push('实地义诊')
                            } else {
                                tmpservice.push('實地義診')
                            }
                        }
                        if (res.data.data[0].freeDiagnosisOnsite == 1) {
                            if (language == 'en') {
                                tmpservice.push('VolunteerOnline')
                            } else if (language == 'zh_CN') {
                                tmpservice.push('为弱势民众视讯义诊')
                            } else {
                                tmpservice.push('為弱勢民眾視訊義診')
                            }
                        }
                        if (res.data.data[0].cn_service == 1) {
                            if (language == 'en') {
                                tmpservice.push('Mainland China Service')
                            } else if (language == 'zh_CN') {
                                tmpservice.push('提供大陆地区咨询')
                            } else {
                                tmpservice.push('提供大陸地區諮詢')
                            }
                        }
                        if (res.data.data[0].serviceunit) {
                            commit('SET_SERVICE_UNIT', res.data.data[0].serviceunit[0] == undefined ? '' : res.data.data[0].serviceunit[0])
                        }
                        commit('SET_MYSERVICE', tmpservice)
                        commit('SET_MYSERVICELANG', tmpserviceLangrs)
                        resolve(res)
                    }
                    reject()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateHistoryParson({ commit }, payload) {
        commit('SET_HISTORY_PERSON_SICK', payload)
    },
    fetchPartnerEnterprise({ commit }, payload) {
        return new Promise((resolve, reject) => {
            fetchPartnerEnterprise(payload)
                .then((res) => {
                    if (res.data.status === 'OK') {
                        commit('SET_PARTNER_ENTERPRISE', res.data.data)
                        resolve(res)
                    }
                    reject(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getHistoryPersonSick({ commit }, payload) {
        return new Promise((resolve, reject) => {
            getHistoryPersonSick(payload)
                .then((res) => {
                    if (res.data.status === 'OK') {
                        commit('SET_HISTORY_PERSON_SICK', res.data.data)
                        resolve(res)
                    }
                    reject(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 取生命與健康數值 (已被取代 無用)
    fetchPersonBaseSign0({ commit }, data) {
        return new Promise((resolve, reject) => {
            fetchPersonBaseSign0(data)
                .then((res) => {
                    if (res.data.status === 'OK') {
                        var tmpdata1 = res.data.sign1 == undefined ? [] : res.data.sign1
                        var tmpdata2 = res.data.sign2 == undefined ? [] : res.data.sign2
                        var tmpdata3 = res.data.sign3 == undefined ? [] : res.data.sign3
                        var tmpdata4 = res.data.sign4 == undefined ? [] : res.data.sign4
                        commit('SET_PERSON_BASE_SIGN', tmpdata1)
                        commit('SET_PERSON_SIGN_2', tmpdata2)
                        commit('SET_PERSON_SIGN_3', tmpdata3)
                        commit('SET_PERSON_SIGN_4', tmpdata4)
                        resolve(res)
                    }
                    reject(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchPersonBaseSign({ commit }, data) {
        return new Promise((resolve, reject) => {
            fetchPersonBaseSign(data)
                .then((res) => {
                    if (res.data.status === 'OK') {
                        resolve(res)
                    }
                    reject(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchPersonSign({ commit }, data) {
        return new Promise((resolve, reject) => {
            fetchPersonSign(data)
                .then((res) => {
                    if (res.data.status === 'OK') {
                        commit('SET_PERSON_SIGN', res.data.data)
                        resolve(res)
                    }
                    reject(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchPersonSign2({ commit }, data) {
        return new Promise((resolve, reject) => {
            fetchPersonSign2(data.spid, data.group)
                .then((res) => {
                    if (res.data.status === 'OK') {
                        commit('SET_PERSON_SIGN_2', res.data.data)
                        resolve(res)
                    }
                    reject(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 取醫療影像
    fetchEmrImage({ commit }, data) {
        return new Promise((resolve, reject) => {
            fetchEmrImage(data)
                .then((res) => {
                    if (res.data.status === 'OK') {
                        if (data.type == '1') {
                            commit('SET_PERSON_PHOTO', res.data.items)
                        }
                        if (data.type == '2') {
                            commit('SET_PERSON_DICOM', res.data.items)
                        }
                        if (data.type == '13') {
                            commit('SET_PERSON_EYE', res.data.items)
                        }
                        if (data.type == '12') {
                            commit('SET_PERSON_STI', res.data.items)
                        }
                        if (data.type == '4') {
                            commit('SET_ULTRA_SOUND', res.data.items)
                        }
                        if (data.type == '5') {
                            commit('SET_ECTROCARDIOGRAM', res.data.items)
                        }
                        resolve(res)
                    }
                    reject(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 取 掛號管理 代理掛號
    fetchAgentReservation({ commit }, data) {
        return new Promise((resolve, reject) => {
            fetchAgentReservation(data)
                .then((res) => {
                    // console.log('agent res ', res)
                    if (res.data.status == 'OK') {
                        var items = res.data.items
                        var unfinish = 0
                        items.forEach(function (v, i) {
                            if (v.finish == 0 && v.closed == 0) {
                                unfinish = unfinish + 1
                            }
                        })
                        commit('SET_AGENT_RESERVATION', items)
                        commit('SET_AGENT_RESERVATION_COUNT', unfinish)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 取 掛號管理 其他時段
    fetchOtherDayReservation({ commit }, data) {
        return new Promise((resolve, reject) => {
            fetchOtherDayReservation(data)
                .then((res) => {
                    // console.log('other day res ', res)
                    if (res.data.status == 'OK') {
                        commit('SET_OTHER_DAY_RESERVATION', res.data.items)
                        var tmpdata = res.data.items
                        var othernofinish = 0
                        tmpdata.forEach(function (v) {
                            if (v.finish == 0 && v.closed == 0) {
                                othernofinish += 1
                            }
                        })
                        commit('SET_OTHER_DAY_RESERVATION_COUNT', othernofinish)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 取 掛號管理 急診紀錄
    fetchemergencyReservation({ commit }, data) {
        return new Promise((resolve, reject) => {
            fetchemergencyReservation(data)
                .then((res) => {
                    // console.log('other day res ', res)
                    if (res.data.status == 'OK') {
                        commit('SET_EMERGENCY_RESERVATION', res.data.items)
                        var tmpdata = res.data.items
                        var othernofinish = 0
                        tmpdata.forEach(function (v) {
                            if (v.finish == 0 && v.closed == 0) {
                                othernofinish += 1
                            }
                        })
                        commit('SET_EMERGENCY_RESERVATION_COUNT', othernofinish)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 取 掛號管理 特約門診
    fetchSpecialReservation({ commit }, data) {
        return new Promise((resolve, reject) => {
            fetchSpecialReservation(data)
                .then((res) => {
                    // console.log('other day res ', res)
                    if (res.data.status == 'OK') {
                        commit('SET_SPECIAL_RESERVATION', res.data.items)
                        var tmpdata = res.data.items
                        var specialnofinish = 0
                        tmpdata.forEach(function (v) {
                            if (v.finish == 0 && v.closed == 0) {
                                specialnofinish += 1
                            }
                        })
                        commit('SET_SPECIAL_RESERVATION_COUNT', specialnofinish)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchEMRDATA({ commit }, data) {
        return new Promise((resolve, reject) => {
            fetchEMRBaseData(data)
                .then((res) => {
                    // console.log('ebm data ', res.data.history)
                    if (res.data.status === 'OK') {
                        var tmpdata = res.data.data
                        var historydata = res.data.history
                        var tmpperson_sick = []
                        var tmpparents_sick = []
                        var tmpparents_cancer = []
                        var tmpsensitive_matters = []
                        // console.log(res.data.data[0])
                        var patientData = {
                            pid: data.userPid,
                            mid: res.data.data[0].mid,
                            account: res.data.data[0].account,
                            name: res.data.data[0].name,
                            birthday: res.data.data[0].birthday,
                            sex: res.data.data[0].sex,
                        }
                        historydata.person_sick.forEach(function (v) {
                            if (v != null) {
                                tmpperson_sick.push(v)
                            }
                        })
                        historydata.parents_sick.forEach(function (v) {
                            if (v != null) {
                                tmpparents_sick.push(v)
                            }
                        })
                        historydata.parents_cancer.forEach(function (v) {
                            if (v != null) {
                                tmpparents_cancer.push(v)
                            }
                        })
                        historydata.sensitive_matters.forEach(function (v) {
                            if (v != null) {
                                tmpsensitive_matters.push(v)
                            }
                        })
                        commit('SET_EMR_BASE_DATA', tmpdata)
                        commit('SET_SELECTED_PATIENT_DATA', patientData)
                        commit('SET_EMR_PERSON_DATA', tmpperson_sick)
                        commit('SET_EMR_PARENTS_SICK_DATA', tmpparents_sick)
                        commit('SET_EMR_PARENTS_CANCER_DATA', tmpparents_cancer)
                        commit('SET_EMR_SENSITIVE_DATA', tmpsensitive_matters)
                        commit('SET_EMR_LISTTYPE_DATA', historydata.life_type)
                        resolve(res)
                    }
                    reject(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchQueue({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
            fetchQueue(data)
                .then((res) => {
                    if (res.data.status === 'OK') {
                        if (!res.data.data != undefined) {
                            var tmpdata = res.data.data
                            var nofinish = 0
                            var amnofinish = 0
                            var pmnofinish = 0
                            var nightnofinish = 0
                            var agentnofinish = 0
                            tmpdata.forEach(function (v) {
                                if (v.finish == 0 && v.closed == 0) {
                                    nofinish += 1
                                }
                                if (v.finish == 0 && v.closed == 0 && v.interval == 1) {
                                    amnofinish += 1
                                }
                                if (v.finish == 0 && v.closed == 0 && v.interval == 2) {
                                    pmnofinish += 1
                                }
                                if (v.finish == 0 && v.closed == 0 && v.interval == 3) {
                                    nightnofinish += 1
                                }
                                if (v.finish == 0 && v.closed == 0 && v.proxy != null) {
                                    agentnofinish += 1
                                }
                                if (v.e_name) {
                                    v.e_nameSubtitle = v.e_name.split(',')[0].length > 8 ? v.e_name.split(',')[0].slice(0, 8) + '...' : v.e_name.split(',')[0]
                                }
                            })
                            commit('SET_QUEUE', tmpdata)
                            commit('SET_QUEUE_TOTAL', res.data.total)
                            commit('SET_QUEUE_NOFINISH', nofinish)
                            if (amnofinish >= 0) {
                                commit('SET_AM_UNFINISH', amnofinish)
                            }
                            if (pmnofinish >= 0) {
                                commit('SET_PM_UNFINISH', pmnofinish)
                            }
                            if (nightnofinish >= 0) {
                                commit('SET_NIGHT_UNFINISH', nightnofinish)
                            }
                            if (agentnofinish >= 0) {
                                commit('SET_AGENT_UNFINISH', agentnofinish)
                            }
                            resolve(res)
                        }
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchQueueDetail({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            fetchQueueDetail(payload)
                .then((res) => {
                    commit('SET_QUEUE_DETAIL_PMEMO', res.data.data.p_memo)
                    commit('SET_CURRENT_PID', res.data.data.pid)
                    commit('SET_SMS_ROOM', res.data.data.sms_room == null ? 0 : res.data.data.sms_room)
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchCallInfo({ commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            fetchCallInfo(payload)
                .then((res) => {
                    if (res.data.status === 'OK') {
                        let allcallinfo = res.data.data
                        if (allcallinfo) {
                            commit('SET_CALLINFO_TOTAL', res.data.total)
                            commit('SET_CALLINFO', allcallinfo)
                        } else {
                            commit('SET_CALLINFO', [])
                        }
                        resolve(res)
                    } else {
                        reject()
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getICD10({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            fetchICD10()
                .then((res) => {
                    if (res.data.status === 'OK') {
                        commit('SET_ICD10', res.data.data)
                        resolve(res)
                    } else {
                        reject()
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateSelectICD10({ commit }, payload) {
        commit('SET_ICD10_SELECTED', payload)
    },
    updateDoctorUnit({ commit }, payload) {
        return new Promise((resolve, reject) => {
            updateDoctorUnit(payload)
                .then((res) => {
                    // console.log('call info res ', res)
                    if (res.data.status === 'OK') {
                        commit('SET_DOCTOR_UNIT', payload)
                        resolve(res)
                    } else {
                        reject()
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateHistory({}, payload) {
        return new Promise((resolve, reject) => {
            updateHistory(payload)
                .then(() => {
                    // console.log('update History ', res)
                    // if(res.data.status === 'OK'){
                    //   commit('SET_CALLINFO', res.data.data)
                    //   resolve(res)
                    // }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    setCallInfoSearchQuery({ commit }, query) {
        commit('SET_CALLINFO_SEARCH_QUERY', query)
    },
    setCRMMemberSearchQuery({ commit }, query) {
        commit('SET_QUERY_CRM_MEMBERS', query)
    },
    setCallinfofilter({ commit }, payload) {
        commit('SET_CALLINFO_FILTER', payload)
    },
    setMemberTotal({ commit }, payload) {
        commit('SET_MEMBER_TOTAL', payload)
    },
    getVIPMember({ commit }, payload) {
        return new Promise((resolve, reject) => {
            getVIPMembers(payload)
                .then((res) => {
                    if (res.data.status === 'success') {
                        var all = res.data.results.items
                        var total = res.data.results.total
                        commit('SET_ALL_VIPMEMBERS', all)
                        resolve(res)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updatePatientBadge({ commit }, payload) {
        // 更新民眾訊息已讀
        return new Promise((resolve, reject) => {
            updatePatientBadge(payload)
                .then((res) => {
                    // console.log('updatePatientBadge ', res)
                    if (res.data.status === 'OK') {
                        var tmpdata = res.data.item
                        resolve(tmpdata)
                    }
                    reject()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchChatContacts({ commit }, page) {
        return new Promise((resolve, reject) => {
            fetchChatContacts(page)
                .then((res) => {
                    // console.log('contact chat ', res)
                    if (res.data.status === 'OK') {
                        var tmpdata = res.data.item
                        //前端排序所有聊天室
                        tmpdata = _.sortBy(tmpdata, ['data.date_time']).reverse()
                        tmpdata = _.slice(tmpdata, 0, 8)
                        var rtmpdata = tmpdata
                        commit('SET_CHAT_CONTACT', rtmpdata)
                        resolve(rtmpdata)
                    }
                    reject()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchChatContactsDoctor({ commit }) {
        return new Promise((resolve, reject) => {
            fetchChatContactsDoctor()
                .then((res) => {
                    // console.log('contact chat doctor ', res)
                    if (res.data.status === 'OK') {
                        var tmpdata = res.data.item
                        let tmpdata2 = []
                        tmpdata.forEach(function (v, i) {
                            {
                                if (v.dname != '') {
                                    tmpdata2.push(v)
                                }
                            }
                        })
                        tmpdata2 = _.sortBy(tmpdata2, ['data.date_time'])
                        var rtmpdata = tmpdata2.reverse()
                        commit('SET_CHAT_CONTACT_DOCTOR', rtmpdata)
                        resolve(rtmpdata)
                    }
                    reject()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchChatContactsGroup({ commit }) {
        return new Promise((resolve, reject) => {
            fetchChatContactsGroup()
                .then((res) => {
                    // console.log('contact chat group ', res)
                    if (res.data.status === 'OK') {
                        var tmpdata = res.data.item
                        tmpdata = _.sortBy(tmpdata, ['msgdata.date_time'])
                        var rtmpdata = tmpdata.reverse()
                        commit('SET_CHAT_CONTACT_GROUP', rtmpdata)
                        resolve(rtmpdata)
                    } else {
                        reject()
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    setChatSearchQuery({ commit }, payload) {
        commit('SET_SEARCH_CONTACT', payload)
    },
    markSeenMessagesOfUser({ commit }, payload) {
        return new Promise((resolve, reject) => {
            fetchChatData(payload)
                .then((res) => {
                    if (res.data.status === 'OK') {
                        commit('SET_CHAT_DATA_OF_USER', res.data.chats)
                        resolve(res)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    markSeenMessagesOfDoctor({ commit }, payload) {
        return new Promise((resolve, reject) => {
            fetchChatDataDoctor(payload)
                .then((res) => {
                    // console.log('chat doctor data ', res)
                    if (res.data.status === 'OK') {
                        commit('SET_CHAT_DATA_OF_DOCTOR', res.data.chats)
                        resolve(res)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    markSeenMessagesOfGroup({ commit }, payload) {
        return new Promise((resolve, reject) => {
            fetchChatData(payload)
                .then((res) => {
                    // console.log('chat group data ', res)
                    if (res.data.status === 'OK') {
                        commit('SET_CHAT_DATA_OF_GROUP', res.data.chats)
                        resolve(res)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    sendChatMessage({ commit }, payload) {
        return new Promise((resolve, reject) => {
            saveChatData(payload)
                .then((res) => {
                    // console.log('sendChatt data ', res)
                    if (res.data.status === 'OK') {
                        commit('APPEND_CHAT_DATA', payload)
                        resolve(res)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // /////////////////////////////////////////////
    // User/Account
    // /////////////////////////////////////////////
    setVideoPath({ commit }, data) {
        commit('SET_VIDEO_PATH', data)
    },
    setRoomId({ commit }, data) {
        commit('SET_ROOM_ID', data)
    },
    setMR({ commit }, data) {
        return new Promise((resolve, reject) => {
            if (data.qid != 0) {
                getMRIDbyqid({ qid: data.qid }).then((res) => {
                    if (res.data.status == 'OK') {
                        commit('SET_MR_ID', res.data.data.mr_id)
                        resolve(res)
                    } else {
                        reject()
                    }
                })
            } else if (data.mrid != 0) {
                commit('SET_MR_ID', data.mrid)
                resolve()
            } else if (data.qid == 0) {
                commit('SET_MR_ID', 0)
                resolve()
            }
        })
    },
    toggleRightPanel({ commit }, payload) {
        commit('TOGGLE_RIGHT_PANEL', payload)
    },
    fetchDoctorStatus({ commit }) {
        getDoctorStatus().then((res) => {
            // console.log('doctor status ', res)
            if (res.data.status == 'OK') {
                commit('UPDATE_DOCTOR_STATUS', res.data.data.status)
            }
        })
    },
    // 刪除常用字句
    delWord({}, payload) {
        return new Promise((resolve, reject) => {
            delWord(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        resolve(res)
                    } else {
                        reject()
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 編輯常用字句
    editWord({}, payload) {
        return new Promise((resolve, reject) => {
            editWord(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        resolve(res)
                    } else {
                        reject()
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 新增常用字句
    addWord({}, payload) {
        return new Promise((resolve, reject) => {
            addWord(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        resolve(res)
                    } else {
                        reject()
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 取常用字句
    fetchWords({ commit }, payload) {
        console.log(payload)
        return new Promise((resolve, reject) => {
            fetchWords(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        // 主觀項目
                        if (res.data.type == 's') {
                            commit('SET_WORD_S', res.data.data)
                        }
                        // 客觀項目
                        if (res.data.type == 'o') {
                            commit('SET_WORD_O', res.data.data)
                        }
                        if (res.data.type == 'a') {
                            commit('SET_WORD_A', res.data.data)
                        }
                        if (res.data.type == 'p') {
                            commit('SET_WORD_P', res.data.data)
                        }
                        // 醫事人員建議
                        if (res.data.type == 'memop') {
                            commit('SET_WORD_MEMOP', res.data.data)
                        }
                        if (res.data.type == 'note') {
                            commit('SET_WORD_NOTE', res.data.data)
                        }
                        // 服務列表
                        if (res.data.type == 'service') {
                            commit('SET_SERVICELIST', res.data.data)
                        }
                        // 文字交談
                        if (res.data.type == 'wd') {
                            commit('SET_WORDSPEECHLIST', res.data.data)
                        }
                        // 飲食管理建議
                        if (res.data.type == 'diet') {
                            commit('SET_DIETLIST', res.data.data)
                        }
                        resolve(res)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 這個是tender的程式，但是在user.js沒有所以terminal報錯，先註解等tender回來問他
    // uploadHealthdataBatch({ commit }, payload){
    //   return new Promise((resolve, reject) => {
    //     uploadHealthdataBatch(payload).then(res => {
    //       if(res.data.status == 'OK'){
    //         resolve(res)
    //       }else{
    //         reject()
    //       }
    //     })
    //   .catch((error) => { reject(error) })
    //   })
    // },
    delNote({}, payload) {
        return new Promise((resolve, reject) => {
            delNote(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateNote({}, payload) {
        return new Promise((resolve, reject) => {
            updateNote(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        resolve(res)
                    } else {
                        resolve(res)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    addNote({}, payload) {
        return new Promise((resolve, reject) => {
            addNote(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        resolve(res)
                    } else {
                        reject()
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchNotes({ commit }, payload) {
        return new Promise((resolve, reject) => {
            fetchNotes(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        commit('SET_NOTES', res.data.data)
                        resolve(res)
                    } else {
                        reject()
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    fetchAllHospital({ commit, state }) {
        return new Promise((resolve, reject) => {
            if (state.allHospital.length != 0) {
                resolve()
            } else {
                fetchHospital()
                    .then((res) => {
                        if (res.data.status == 'OK') {
                            let listOfObjects = Object.keys(res.data.data).map((key) => {
                                return res.data.data[key]
                            })
                            commit('SET_ALL_HOSPITAL', listOfObjects)
                            resolve()
                        } else {
                            reject()
                        }
                    })
                    .catch((error) => {
                        reject(error)
                    })
            }
        })
    },
    fetchRefsHospital({ commit }) {
        return new Promise((resolve, reject) => {
            fetchRefsHospital()
                .then((res) => {
                    if (res.data.status == 'OK') {
                        commit('SET_REFER_HOSPITAL', res.data.data)
                        resolve(res)
                    } else {
                        reject()
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    addRefHospital({}, payload) {
        return new Promise((resolve, reject) => {
            addRefHospital(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        resolve(res)
                    } else {
                        reject()
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateDoctorStatus({ commit }, payload) {
        // console.log('update status ', payload)
        return new Promise((resolve, reject) => {
            updateDoctorStatus({ status: payload })
                .then((res) => {
                    commit('UPDATE_DOCTOR_STATUS', payload)
                    resolve(res)
                })
                .catch((error) => {
                    // console.log('login error', error)
                    reject(error)
                })
            // commit('UPDATE_DOCTOR_STATUS', payload)
        })
    },
    updatecallinfostatus({ commit }, payload) {
        return new Promise((resolve, reject) => {
            updatecallinfostatus(payload)
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    updateUserInfo({ commit }, payload) {
        commit('UPDATE_USER_INFO', payload)
    },
    setActiveChatUser({ commit }, payload) {
        commit('SET_ACTIVE_CHAT_USER', payload)
    },
    // doctor to patient
    callOut({}, payload) {
        return new Promise((resolve, reject) => {
            callout({
                qid: payload.queue_id,
                calltype: payload.calltype,
                from: payload.from,
                spid: payload.spid,
                partnerid: payload.partnerid,
            })
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    console.log('callout error', error)
                    reject(error)
                })
        })
    },
    // agent to patient
    callOut2({}, payload) {
        // by agent
        return new Promise((resolve, reject) => {
            // console.log('call2 out ', payload)
            callout2({
                sdid: payload.sdid,
                qid: payload.queue_id,
                calltype: payload.calltype,
                from: payload.from,
            })
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    console.log('callout error', error)
                    reject(error)
                })
        })
    },
    registerUser({}, payload) {
        return new Promise((resolve, reject) => {
            registerUser(payload)
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    console.log('reg error ', error)
                    reject(error)
                })
        })
    },
    // 登入
    login({}, userInfo) {
        return new Promise((resolve, reject) => {
            let payload = {
                id: userInfo.id.trim(),
                pwd: userInfo.pwd,
                is_doctor: 1,
                phoneType: userInfo.phoneType,
                voipToken: userInfo.voipToken,
                apnsToken: userInfo.apnsToken,
                ver: 2,
                app: 0,
                appLang: userInfo.appLang,
            }
            login(payload)
                .then((response) => {
                    if (response.data.status === 'OK') {
                        const token = response.data.token
                        localStorage.setItem('di_tokenId', token)
                        resolve(response)
                    } else if (response.data.status === 'unfinsh_verify' && response.data.token != '') {
                        const token = response.data.token
                        localStorage.setItem('di_tokenId', token)
                        localStorage.removeItem('refreshNewTime')
                        resolve(response)
                    } else {
                        resolve(response)
                    }
                    reject()
                })
                .catch((error) => {
                    console.log('login ERROR > ', error)
                    reject(error)
                })
        })
    },
    getInfo({ commit, state }) {
        return new Promise((resolve, reject) => {
            getInfo()
                .then((response) => {
                    const data = response.data.data
                    if (state.isDev) {
                    }
                    if (response.data.status == 'ERROR_TOKEN') {
                        reject('Verification failed, please Login again.')
                    }
                    if (response.status == 'ERROR') {
                        reject('DATA ERR')
                    }
                    const { family_name, name, avatar, doctortype, partner, verify, uiastaff, doctorEnable, did, mid, title, hisver } = data
                    var photoname = avatar.split(/(\\|\/)/g).pop()
                    if (app.$i18n.locale == 'en') {
                        var myname = name == 'undefined' ? '' + family_name : name + family_name
                    } else {
                        var myname = name == 'undefined' ? family_name + '' : family_name + name
                    }
                    var tmpavatar = ''
                    if (avatar == '') {
                        tmpavatar = process.env.VUE_APP_UPLOAD + '/doctor/photo/noimage.png'
                    } else {
                        tmpavatar = process.env.VUE_APP_UPLOAD + '/doctor/photo/' + photoname
                    }
                    if (hisver != '') {
                        if (localStorage.getItem('hisver') === null) {
                            localStorage.setItem('hisver', hisver)
                        } else if (hisver != localStorage.getItem('hisver')) {
                            localStorage.removeItem('hisver')
                            window.location.reload()
                        }
                    }
                    commit('SET_DID', did)
                    commit('SET_MID', mid)
                    commit('SET_NAME', myname)
                    commit('SET_AVATAR', tmpavatar)
                    commit('SET_DOCTYPE', doctortype)
                    commit('SET_TITLE', title)
                    localStorage.getItem('partnerid') ? commit('SET_PARTNER', localStorage.getItem('partnerid')) : commit('SET_PARTNER', partner)
                    commit('SET_VERIFY', verify)
                    commit('SET_UIA_STAFF', uiastaff)
                    commit('SET_DOCTOR_ENABLE', doctorEnable)
                    resolve(response)
                })
                .catch((error) => {
                    console.log('user info error', error)
                    reject(error)
                })
        })
    },
    fetchOpinionCount({ commit }) {
        return new Promise((resolve, reject) => {
            fetchOpinionCount()
                .then((res) => {
                    if (res.data.status == 'OK') {
                        commit('SET_OPINION_ACTIVE_COUNT', res.data.count)
                        resolve(res)
                    }
                    reject()
                })
                .catch((error) => {
                    console.log('fetch opinion error', error)
                    reject(error)
                })
        })
    },
    fetchTodoCount({ commit }) {
        return new Promise((resolve, reject) => {
            getCountByTodo()
                .then((res) => {
                    // console.log('fetch todo count')
                    if (res.data.status == 'OK') {
                        commit('SET_TODO_UNFINISH_TOTAL', res.data.count)
                        resolve(res)
                    }
                    reject()
                })
                .catch((error) => {
                    console.log('fetch todo count error', error)
                    reject(error)
                })
        })
    },
    crmMembers({ commit }, payload) {
        return new Promise((resolve, reject) => {
            crmMembers(payload)
                .then((res) => {
                    if (res.data.status === 'OK') {
                        commit('SET_CRM_MEMBERS', res.data.items)
                        resolve(res)
                    }
                    reject()
                })
                .catch((error) => {
                    console.log('crm error', error)
                    reject(error)
                })
        })
    },
    setDevice({ commit }, payload) {
        commit('SET_DEVICE', payload)
    },
    // setDeviceToken({ commit }, payload) {
    //   commit('SET_DEVICE_TOKEN', payload)
    // },
    getBankPhoto({ commit }) {
        return new Promise((resolve, reject) => {
            fetchPhotoPath({ type: 'B' }).then((res) => {
                if (res.data.status == 'OK') {
                    var bankfilename = 'nobankimg2.png'
                    if (res.data.filepath != '') {
                        bankfilename = res.data.filepath.split(/(\\|\/)/g).pop()
                    }
                    // console.log('bank bankfilename', bankfilename)
                    commit('SET_BANK_IMAGE', process.env.VUE_APP_UPLOAD + '/doctor/bank/' + bankfilename)
                    resolve(res)
                } else {
                    reject(res)
                }
            })
        })
    },
    getLicensePhoto({ commit }) {
        return new Promise((resolve, reject) => {
            fetchPhotoPath({ type: 'L' })
                .then((res) => {
                    // console.log('license res', res)
                    var licensefilename = 'nolicenseimg2.png'
                    if (res.data.status == 'OK') {
                        if (res.data.filepath != '') {
                            licensefilename = res.data.filepath.split(/(\\|\/)/g).pop()
                        }
                        commit('SET_LICENSE_IMAGE', process.env.VUE_APP_UPLOAD + '/doctor/license/' + licensefilename)
                        resolve(res)
                    } else {
                        reject(res)
                    }
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getRoomMembers({ commit, state }) {
        return new Promise((resolve, reject) => {
            var selectedRoomId = state.selectedRoomId
            if (selectedRoomId != 0) {
                getRoomMembers({ roomid: selectedRoomId })
                    .then((res) => {
                        if (res.data.status == 'OK') {
                            commit('SET_ROOM_MEMBERS', res.data.data)
                            resolve(res)
                        }
                        reject()
                    })
                    .catch((error) => {
                        reject(error)
                    })
            }
        })
    },
    getRoom({ commit }) {
        return new Promise((resolve, reject) => {
            getRoom()
                .then((res) => {
                    if (res.data.status == 'OK') {
                        commit('SET_ROOM', res.data.data)
                        resolve(res)
                    }
                    reject()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // user logout
    logout({ commit, state }) {
        return new Promise((resolve, reject) => {
            logout(state.device)
                .then((res) => {
                    // console.log('logout res ', res)
                    commit('SET_TOKEN', '')
                    commit('SET_NAME', '')
                    // commit('SET_DEVICE_TOKEN', '')
                    commit('REMOVE_DOCTYPE')
                    commit('REMOVE_MID')
                    commit('SET_DOCTYPE', '')
                    // if(state.device==3){
                    //   localStorage.removeItem('doc_access_token')
                    //   localStorage.removeItem('doc_access_apns')
                    // }
                    localStorage.removeItem('di_tokenId')
                    localStorage.removeItem('component_tab')
                    localStorage.removeItem('refresh_token')
                    localStorage.removeItem('member_search_data')
                    localStorage.removeItem('selectedpid')
                    localStorage.removeItem('refreshNewTime')
                    localStorage.removeItem('reservation_filter_index')
                    localStorage.removeItem('logourl')
                    localStorage.removeItem('partnerid')
                    localStorage.removeItem('partnerlist')
                    localStorage.removeItem('his_config')
                    localStorage.removeItem('apptVer')
                    localStorage.removeItem('cart_token')
                    resolve()
                })
                .catch((error) => {
                    console.log('logout ERROR ', error)
                    reject(error)
                })
        })
    },
    // remove token
    resetToken() {
        return new Promise((resolve) => {
            localStorage.removeItem('di_tokenId')
            resolve()
        })
    },
    setBlocks({ commit, state }, payload) {
        commit('SET_BLOCKS', payload)
    },
    // getShift(doctor_did) {
    //   return new Promise((resolve, reject) => {
    //     getShift(doctor_did).then((res) => {
    //       if(res.data.status=='OK'){
    //         resolve(res)
    //       }
    //       reject()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    // getLeave({},payload) {
    //   return new Promise((resolve, reject) => {
    //     getLeave(payload).then((res) => {
    //       if(res.data.status=='OK'){
    //         resolve(res)
    //       }
    //       reject()
    //     }).catch(error => {
    //       reject(error)
    //     })
    //   })
    // },
    // 取 會員總數
    getUIAPartner({ commit }, uiastaff) {
        return new Promise((resolve, reject) => {
            getUIAPartnrtList({ is_uia: uiastaff == true ? 1 : 0 })
                .then((res) => {
                    if (res.data.status == 'success') {
                        // var partnerlist = res.data.results
                        // var tmptotal = 0
                        // partnerlist.forEach(function(v){
                        //   tmptotal += parseInt(v.total)
                        // })
                        // commit('SET_MEMBER_TOTAL',tmptotal)
                        resolve(res)
                    }
                    reject()
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 取 未讀 待辦事項 數量
    getCountByTodo({ commit }) {
        return new Promise((resolve, reject) => {
            getCountByTodo()
                .then((res) => {
                    // console.log('Todo count : ', res.data.count)
                    if (res.data.status == 'OK') {
                        var todounfinish = res.data.count
                        commit('SET_TODO_UNFINISH_TOTAL', todounfinish)
                        resolve(res)
                    }
                    reject()
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    },
    // 取 未讀 聊天室 數量
    getCountByUnreadMsg({ commit }) {
        return new Promise((resolve, reject) => {
            getCountByUnreadMsg()
                .then((res) => {
                    // console.log('msg count : ', res)
                    if (res.data.status == 'OK') {
                        commit('SET_MSG_UNFINISH_TOTAL', res.data.count)
                        resolve(res)
                    }
                    reject()
                })
                .catch((error) => {
                    console.log('msg count : ' + error)
                    reject(error)
                })
        })
    },
    // 取 未讀 通知 數量
    getCountByUnreadNotify({ commit }) {
        return new Promise((resolve, reject) => {
            getCountByUnreadNotify()
                .then((res) => {
                    if (res.data.status == 'OK') {
                        commit('SET_NOTIFY_UNFINISH_TOTAL', res.data.count)
                        // 更新手機板app圖示的通知數
                        updateBadge(res.data.count)
                        resolve(res)
                    }
                    reject()
                })
                .catch((error) => {
                    console.log('notify count res err : ' + error)
                    reject(error)
                })
        })
    },
    // 取 未讀 我的預約 數量
    getCountByUnreadSchedule({ commit }) {
        return new Promise((resolve, reject) => {
            getCountByUnreadSchedule()
                .then((res) => {
                    if (res.data.status == 'OK') {
                        // console.log('schedule count : ', res)
                        commit('SET_SCHEDULE_UNFINISH_TOTAL', res.data.count)
                        resolve(res)
                    }
                    reject()
                })
                .catch((error) => {
                    console.log('schedule count reser : ' + error)
                    reject(error)
                })
        })
    },
    // 判斷病人是否授權
    partnerPatientRecordAuthorize({}, payload) {
        return new Promise((resolve, reject) => {
            partnerPatientRecordAuthorize(payload)
                .then((res) => {
                    if (res.data.status == 'success') {
                        resolve(res)
                    }
                    reject()
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    },
    // 取掛號主頁資料
    getQueueDetailByQid({}, payload) {
        return new Promise((resolve, reject) => {
            getQueueDetailByQid(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        resolve(res)
                    }
                    reject()
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    },
    // 取看診紀錄
    getQueueHistory({}, payload) {
        return new Promise((resolve, reject) => {
            getQueueHistory(payload)
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 取處方籤QRCode字串
    getRxString({}, payload) {
        return new Promise((resolve, reject) => {
            getRxString(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        resolve(res)
                    }
                    reject()
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    },
    // device_token資料的防呆機制，並回傳device_token資料
    checkDeviceToken({}, payload) {
        return new Promise((resolve, reject) => {
            checkDeviceToken(payload)
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 取HIS config
    getConfig({}, payload) {
        return new Promise((resolve, reject) => {
            getConfig(payload)
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    // 民眾、房間狀態
    PatientStateFromMcu({ commit }, payload) {
        return new Promise((resolve, reject) => {
            PatientStateFromMcu(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        // console.log("inroom",res.data.in_room)
                        // commit('SET_PATIENT_INROOM', res.data.in_room)
                        resolve(res)
                    }
                    reject()
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    },
    // 取醫事人員職稱
    fetchMedicalTitle({ commit }, payload) {
        return new Promise((resolve, reject) => {
            fetchMedicalTitle()
                .then((res) => {
                    if (res.data.status == 'OK') {
                        commit('SET_MEDICAL_TITLE_LIST', res.data.data)
                        resolve(res)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    },
    // 發送視訊邀請連結 sms_room 4:Line 2:wechat else:簡訊
    sendVideoLink({}, payload) {
        return new Promise((resolve, reject) => {
            if (payload.sms_room == 4) {
                sendRoomByLINE(payload)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            } else if (payload.sms_room == 2) {
                sendWeChat(payload)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            } else {
                sendRoomBySms(payload)
                    .then((res) => {
                        resolve(res)
                    })
                    .catch((error) => {
                        console.log(error)
                        reject(error)
                    })
            }
        })
    },
    // 取今日掛號未完診數量
    fetchApptUndoneQuantity({ commit }, partner_id = null) {
        return new Promise((resolve, reject) => {
            let payload = partner_id ? { partner_id_arr: [partner_id] } : null
            fetchApptUndoneQuantity(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        commit('SET_APPT_UNDONE_TOTAL', res.data.data[0].total)
                        commit('SET_AGENT_APPT_UNDONE_TOTAL', res.data.agentData[0].total)
                        resolve(res)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    },
    fetchDietChartData({ commit }, payload) {
        return new Promise((resolve, reject) => {
            fetchDietChartData(payload).then((res) => {
                if (res.data.status == 'OK') {
                    commit('SET_DIET_CHART_DATA', res.data.data)
                    resolve(res)
                }
                reject()
            })
        })
    },
    // 取服務記錄數量
    fetchSRQuantity({ commit }, payload) {
        return new Promise((resolve, reject) => {
            fetchSRQuantity(payload)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        commit('SET_CRM_OPEN_TOTAL', res.data.data.open)
                        commit('SET_CRM_CLOSE_TOTAL', res.data.data.close)
                        resolve(res)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    reject(error)
                })
        })
    },
    // 進入房間 isDev, device, room_id, qid, did, dname, doctorType, dmid
    enterRoom({ commit }, payload) {
        var jsonStr = {
            roomNumber: payload.room_id,
            queueID: payload.qid.toString(),
            videoCall: 'true',
            scheme_time: 0,
        }
        //網頁
        if (payload.device == 3) {
            var videopath = 'https://mcu.curdoctor.com.tw:3004/?f=true&v=true'
            videopath += payload.isDev == true ? '&test=true' : ''
            videopath += '&scheme_time='
            videopath += '&room=' + payload.room_id
            videopath += '&queue_id=' + payload.qid
            videopath += '&token=' + localStorage.getItem('doc_access_token')
            videopath += '&m=' + payload.doctorType + '-' + payload.dmid
            commit('SET_VIDEO_PATH', videopath)
            commit('TOGGLE_RIGHT_PANEL', true)
        }
        // android
        else if (payload.device == 2) {
            jsonStr.doctorName = payload.dname
            jsonStr.did = payload.did
            window.android.callRTC(JSON.stringify(jsonStr))
        }
        // ios
        else if (payload.device == 1) {
            jsonStr.patientName = payload.dname
            jsonStr.photo = ''
            window.webkit.messageHandlers.CallPatient.postMessage(JSON.stringify(jsonStr))
        }
    },
    // 通知
    notify({}, payload) {
        let color = payload.color ? payload.color : 'grey'
        let data = {
            color: color,
            time: 8000,
            position: 'top-center',
        }
        if (payload.title) data.title = payload.title
        if (payload.text) data.text = payload.text
        Vue.prototype.$vs.notify(data)
    },
    // 取所有單位會員數量
    getMembersTotal({ commit }, payload) {
        var unit = ''
        let temp = {
            partner_id: payload.partner_id,
        }
        getMemberFilter(temp).then((res) => {
            if (res.data.status === 'OK') {
                res.data.data.enterprise_list.forEach((v) => {
                    unit += v.enterprise_id + ','
                })
                unit = _.trim(unit, ',')
                if (!unit) {
                    commit('SET_MEMBER_TOTAL', 0)
                    return
                }

                let temp2 = {
                    partner_id: payload.partner_id,
                    eids: unit,
                }
                getMemberList(temp2).then((res) => {
                    if (res.data.status === 'OK') {
                        commit('SET_MEMBER_TOTAL', res.data.data.total)
                    }
                })
            }
        })
    },
    call_p2p({ state, commit, dispatch }, payload) {
        return new Promise((resolve, reject) => {
            const data = {
                partner_id: state.partner,
                is_doctor: payload.is_doctor,
                mid: payload.mid,
                caller: state.name,
                ring: payload.ring,
                device_type: localStorage.getItem('doc_access_phoneType'),
                device_token: localStorage.getItem('doc_access_token'),
                src: _.replace(_.lowerCase(_.split(route.fullPath, '/', 2)[1]), ' ', ''),
            }
            if (payload.queue_id) data.queue_id = payload.queue_id
            if (payload.sn_id) data.sn_id = payload.sn_id
            callByPiepie(data)
                .then((res) => {
                    const call_id = res.data.data.call_id
                    const room_id = res.data.data.room_id
                    const local_id = 'curdr_' + state.partner + '_' + state.mid + '_1'
                    const remote_id = 'curdr_' + state.partner + '_' + payload.mid + '_' + payload.is_doctor
                    commit('SET_CALL_ID', call_id)
                    commit('SET_ROOM_ID', room_id)
                    commit('SET_LOCAL_ID', local_id)
                    commit('SET_REMOTE_ID', remote_id)
                    commit('SET_CALLING', true)
                    dispatch('updateDoctorStatus', 2000)

                    if (payload.direction > 0) {
                        // 撥出
                        console.log(true, room_id, call_id, local_id, false, state.name, true, state.uuid)
                        p2p_call(true, room_id, call_id, local_id, false, state.name, true, state.uuid)
                    } else {
                        // 接聽
                        console.log(false, room_id, call_id, remote_id, false, state.name, false, state.uuid)
                        p2p_call(false, room_id, call_id, remote_id, false, state.name, false, state.uuid)
                    }

                    // 給Andy寫rtclog用的
                    localStorage.setItem('p2p_queueid', payload.queue_id ? payload.queue_id : 0)
                    localStorage.setItem('p2p_tomid', payload.mid)

                    /* 這一區不知道在寫三小
                    // remoteID = res.data.remote_Id // 可能是寫到Andy那邊的參數，不知道為什麼要從這邊改
                    // console.log('hereeeeeeeeeeeee p2p_tomid', this.dmid)
                    // let send_data = {
                    //     queue_id: res.data.queue_id,
                    // }
                    // p2pCallData = send_data
                    */

                    // if (this.$router.currentRoute.fullPath != '/emr2/' + this.spid + '/' + res.data.queue_id) {
                    //     this.$router.push('/emr2/' + this.spid + '/' + res.data.queue_id)
                    // }
                    resolve(res)
                })
                .catch((error) => {
                    console.log('callout error', error)
                    reject(error)
                })
        })
    },
    getQueuesUndoneQuantity({}, payload = {}) {
        return new Promise((resolve, reject) => {
            const data = {
                sdid: payload.sdid,
                proxy: payload.proxy,
                partner_id: payload.partner_id,
                start_date: payload.start_date,
                end_date: payload.end_date,
            }
            getQueuesUndoneQuantity(data)
                .then((res) => {
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    getTodayQueuesUndoneQuantity({ commit, state }) {
        return new Promise((resolve, reject) => {
            const data = {
                sdid: state.did,
                proxy: state.mid,
                partner_id: state.partner,
                start_date: common.getCurrentDate(),
                end_date: common.getCurrentDate(),
            }
            getQueuesUndoneQuantity(data)
                .then((res) => {
                    commit('SET_UNDONE_APPT_AM', res.data.data.general.am)
                    commit('SET_UNDONE_APPT_PM', res.data.data.general.pm)
                    commit('SET_UNDONE_APPT_NIGHT', res.data.data.general.night)
                    commit('SET_UNDONE_APPT_EMERGENCY', res.data.data.general.emergency)
                    commit('SET_UNDONE_APPT_SPEC', res.data.data.general.spec)
                    commit('SET_UNDONE_APPT_CS', res.data.data.general.cs)
                    commit('SET_UNDONE_APPT_TOTAL', res.data.data.general.total)
                    commit('SET_UNDONE_AGENT_APPT_AM', res.data.data.agent.am)
                    commit('SET_UNDONE_AGENT_APPT_PM', res.data.data.agent.pm)
                    commit('SET_UNDONE_AGENT_APPT_NIGHT', res.data.data.agent.night)
                    commit('SET_UNDONE_AGENT_APPT_EMERGENCY', res.data.data.agent.emergency)
                    commit('SET_UNDONE_AGENT_APPT_SPEC', res.data.data.agent.spec)
                    commit('SET_UNDONE_AGENT_APPT_CS', res.data.data.agent.cs)
                    commit('SET_UNDONE_AGENT_APPT_TOTAL', res.data.data.agent.total)
                    resolve(res)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },
    checkPatientInRoom({}, payload = {}) {
        const room_id = payload.room_id
        const partner_id = payload.partner_id
        const mid = payload.mid
        console.log(room_id, partner_id, mid)
        const ringString = JSON.stringify({
            p2pAction: 'RollCall',
            p2pCallData: { roomID: room_id },
        })
        const remoteID = 'curdr_' + partner_id + '_' + mid + '_0'
        p2p_getRemoteToken(remoteID, ringString).then((res) => {
            console.log('check', res)
        })
    },
}

export default actions
