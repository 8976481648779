export default {
	tw: {
		404: '404',
		500: '500',
		AccessControl: 'Access Control',
		AccountClosed: '此帳號已關閉',
		accountID: '身分證',
		AccountNameTxt: '戶名',
		AccountNoTxt: '帳號',
		accountPhone: '手機',
		AddAppointBtn: '預約',
		AddBtn: '新增',
		AddCase: '新增案件',
		AddData: '新增數據',
		AddFailed: '新增失敗',
		addGroup: '新增群組',
		AddGroupFailed: '新增群組失敗',
		AddGroupSuccessful: '新增群組成功',
		addMRStaff: '新增醫事人員',
		AddNote: '新增備註',
		AddSchedule: '新增我的預約',
		AddScheduleBtn: '新增預約',
		AddService: '新增服務項目',
		AddSuccess: '新增成功',
		AddWord: '新增常用字句',
		Advisory: '諮詢紀錄',
		AgentTime: '代理掛號',
		AgentTimeRWD: '代掛',
		agGridTable: 'agGrid Table',
		AgreenMentTitle: '醫護人員註冊合約',
		AIReportTxt: 'AI人工智能檢測報告僅供您參考，不能做為是否就醫的依據。若您有身體不適狀况，應盡速至醫療院所由醫師為您診察。',
		Alert: 'Alert',
		Alert2: '提示',
		AlertTxt: '警告',
		All: '全部',
		AllianceTitle: '遠距之愛',
		am: '上午',
		amMobile: '上',
		Analytics: 'Analytics',
		AnonymousTxt: '訪客',
		ApexCharts: 'Apex Charts',
		AppNum: '預約人數',
		appointment: '預約',
		appointment2: '預約掛號',
		appointment3: '檢視病歷',
		appointment4: '檢視影像',
		AppointTime: ' 預約掛號',
		Apps: 'Apps',
		ArrangeShifts: '休診設定',
		Authentication: 'Authentication',
		Authorization: '授權管理',
		Autocomplete: 'Autocomplete',
		Avatar: 'Avatar',
		BackBtn: '上一頁',
		Backlogin: '返回登入',
		Badge: 'Badge',
		BankAddressTxt: '地址',
		BankCodeTxt: '銀行代號',
		BankInfoTxt: '銀行資訊',
		BankTxt: '銀行',
		Basic: 'Basic',
		Birthday: '生日',
		BloodOx: '血氧',
		BodyTemper: '體溫',
		BranchTxt: '分行',
		Breadcrumb: 'Breadcrumb',
		BuildFailed: '建立失敗',
		BuildSuccessful: '建立成功',
		BusyStatus: '看診中',
		Button: 'Button',
		ButtonGroup: 'Button Group',
		Calendar: 'Calendar',
		CamiansaintTitle:'羅東聖母醫院',
		Call: '通話',
		CallBack: '中斷重播',
		CallBackRwd: '重播',
		CallTime: ' 撥打日期',
		Cancel: '取消',
		CancelDayOff: '取消請假',
		CancelRegister: '已取消掛號',
		CancelRegisteredNotice: '取消掛號通知',
		Cancelregistrationnotice: '取消報到通知',
		CancelSystemRegister: '逾時自動取消掛號',
		CannotDraw: '相關資料不足,無法製圖',
		Card: '卡片',
		CardActions: 'Card Actions',
		CardColors: 'Card Colors',
		CardID: '健保卡號',
		CareTxt: '照服員',
		Carousel: 'Carousel',
		Cart: 'Cart',
		Case: '案件',
		CaseDone: '案件完成',
		CaseTitle: '案件標題',
		CaseTxt: '個案管理師',
		ChangeMobile: '變更手機號碼',
		ChangeMobileNote: '輸入新手機號碼，取得驗證碼，進行驗證。',
		ChangeMobileNote2: '變更密碼,會重新登出, 再使用新密碼登入。',
		ChangeMobilePW: '變更手機密碼',
		ChangePW: '變更密碼',
		ChangeTime: '變更時間',
		Chart: '圖表',
		chartist: 'chartist',
		chartjs: 'chartjs',
		Charts: 'Charts',
		ChartsAndMaps: 'Charts & Maps',
		Chat: '聊天室',
		Checkbox: 'Checkbox',
		CheckDate: '檢查日期',
		CheckDoctorField: '請選擇醫師資料',
		CheckField: '請檢查相關欄位!',
		CheckIn: '報到',
		Checkout: 'Checkout',
		CheckPatientField: '請選擇民眾資料',
		ChengHsinTitle: '振興醫院',
		ChineseMD: '中醫',
		Chip: 'Chip',
		choiceCompanyusually: '請選擇常用字句',
		choiceDoctorMember: '請選擇醫師會員',
		choiceFilter: '請選擇篩選條件',
		choiceGroup: '請選擇組別',
		choicePatientDate: '請選擇日期',
		choicePatientDatetime: '請選擇日期與時間',
		choicePatientMember: '請搜尋民眾會員',
		choicePatientMember_1: '請輸入民眾姓名或身分證字號',
		choicePatientMember_2: '篩選符合民眾',
		choicePatientTime: '請選擇時間',
		chooseRegidentifyType: '選擇註冊身分',
		chooseRegType: '選擇註冊方式',
		chosen_forgin_or_not: '*選擇本國與國外銀行',
		ChosenContact: '存在聯絡人',
		CLCTitle: 'CLC CARE',
		clinicalTxt: '臨床心理師',
		Clipboard: 'Clipboard',
		CNs: '人民幣',
		Collapse: 'Collapse',
		Colors: 'Colors',
		ComingSoon: 'Coming Soon',
		Completed: '完成人數',
		Components: 'Components',
		Confirm: '確定',
		ConfirmBtn: '確認',
		ConfirmConsultationDone: '確定此筆掛號完成看診',
		ConfirmPassword: '再次輸入密碼',
		ConfirmReDial: '確定重播',
		confirmText_1: '請確定以下資訊:',
		confirmText_2: '預約醫師:',
		confirmText_3: '民眾大名:',
		confirmText_4: '付費方案:',
		confirmText_5: ' /元',
		confirmText_6: ' 分鐘',
		confirmText_7: '預約時間:',
		confirmText_8: '日, 時段:',
		confirmText_8_2: '時段:',
		confirmText_9: '注意：請提醒民眾在30分鐘內,付完款項。',
		ConnectFirst: '請先連線',
		ConnectionFailed: '連線異常',
		Consultation: '會診管理',
		Consultationnotice: '會診通知',
		ConsultBtn: '文檔會診',
		ConsultDescription: '邀請會診說明',
		ConsultDoctor: '掛號醫師',
		ConsultPlan: '選擇方案',
		ConsultPlan2: '輸入諮詢分鐘',
		ConsultProblem: '諮詢問題',
		ConsultTitle: '邀請文檔會診醫師',
		Contact: '聯絡人',
		ContactTxt: '聯絡資訊',
		ContainAndFinish: '保留看診',
		ContainAndFinishRwd: '保留',
		Content: '內容',
		Content2: '事項內容',
		ContentText: '內容說明',
		ContextMenu: 'Context Menu',
		Country: '國別',
		CreateDate: '建立日期',
		CRM: 'CRM 系統',
		crmNote: '客服系統',
		curtitle: '醫生馬上看',
		customerSuggest: '客服紀錄',
		CusTxt: '客服',
		Dashboard: '儀錶板',
		DataCanNotEmpty: '欄位不能空的!',
		DataContainOK: '成功中斷保留',
		DataErrCannotDraw: '載入資料錯誤,無法製圖',
		DataList: 'Data List',
		DataNotEnough: '資料不足',
		DataSaved: '資料已成功儲存',
		DataSaveFailed: '資料更新失敗',
		DataSaveOK: '資料已成功儲存',
		Datepicker: 'Datepicker',
		DatetimePicker: 'Datetime Picker',
		Del: '刪除',
		DeleteDataFailed: '資料刪除失敗',
		DeleteDataSuccessful: '資料已成功刪除',
		DeliverVerificationFailed: '驗證碼發送失敗',
		DeliverVerificationSuccessful: '驗證碼已發送至手機',
		demoText: 'Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.',
		demoTitle: 'Card Title',
		Description: '說明',
		Detail: '檢視內容',
		DetailBtn: '詳細資訊',
		DetailBtn: '詳細資訊',
		Dialogs: 'Dialogs',
		Diastolic_Pre: '血壓(舒張壓)',
		dicomdescription: '代表糖尿病眼底病變的 5 個嚴重度分級。(數值範圍 0~100)',
		dicomdescription1: '代表該民眾需要被轉介 就醫的可能性',
		dicomdescription2: '心電圖 Q 波至第一心音 ( S1 ) 的時間長度，可表示心臟射出血液的能力，正常值應低於 15%。EMAT ≧ 15% 時，表示可能有疑似心臟收縮功能不佳、心臟衰竭的風險。',
		dicomdescription3: '電活期',
		dicomdescription4: '心臟收縮功能的綜合指標，範圍在 0~10 個單位之間，正常值應低於5。SDI ≧ 5 時，表示可能有疑似心臟收縮功能缺損的風險。SDI ≧ 7.5 時，表示可能有疑似嚴重心臟收縮功能缺損的風險。',
		dicomdescription5: '縮縮不全指數',
		dicomdescription6: '心臟在舒張時產生的心音，範圍在 0~10 個單位之間，正常值應低於5。S3 ≧ 5時，表示可能疑似有心臟舒張功能受損、心臟衰竭的風險。',
		dicomdescription7: '第三心音',
		dicomdescription8: '心臟在舒張時產生的心音，範圍在 0~10 個單位之間，正常值應低於5。S4 ≧ 5時，表示可能有疑似主動脈硬化、缺血或左心室肥大的風險。',
		dicomdescription9: '第四心音',
		DietitianTxt: '營養師',
		DisabledMenu: 'Disabled Menu',
		Divider: 'Divider',
		doctorAccess: '授權班表名單',
		doctorBank: '存摺封面(匯入諮詢費)',
		doctorbankaccount: '銀行帳號',
		doctorbankaccountname: '銀行戶名',
		doctorbankaddress: '銀行地址',
		doctorbankbranch: '銀行分行',
		doctorbankinfo: '銀行資訊',
		doctorbankname: '銀行名稱',
		doctorbankswiftcode: '銀行代碼',
		doctorChinaDayFee: '大陸白天諮詢費率',
		doctorChinaNightFee: '大陸夜間和急診諮詢費率',
		doctorDayFee: '台灣白天諮詢費率',
		doctorDICOM: 'DICOM',
		doctorEducation: '學歷',
		doctorEE: '學經歷',
		doctorExperience: '經歷',
		doctorExpertise: '專長',
		DoctorInfo: '合作醫護人員',
		DoctorInfoChosen: '選取醫護人員',
		DoctorInfoNotChosen: '未選取醫護人員',
		DoctorInfosubtitle: '搜尋並勾選合作的醫護人員',
		doctorLang: '精通語言',
		doctorLicense: '執業執照(影本)',
		doctorLife: '生命與健康數值',
		doctorLifeValue: '主要生命數值',
		doctorNightFee: '台灣夜間和急診諮詢費率',
		DoctorNoService: '該醫師未開班，請重新搜尋',
		doctorNote: '備註',
		DoctorOffline: '醫師目前未上線',
		doctorPhoto: '個人照片',
		doctorReason: '問診原因',
		doctorRecord: '醫療影像',
		doctorReport: '生化檢驗',
		doctorservice: '服務內容',
		doctorServiceType: '服務方式',
		doctorsoap: '醫事人員S.O.A.P. 類型',
		doctorstatus: '審核狀態',
		DoctorStatusText: '合作醫師',
		doctorSuggest: '建議紀錄',
		doctortype: '醫事類別',
		doctorUnit: '服務單位',
		doctorValue2: '新陳代謝數值',
		doctorValue3: '體型數值',
		doctorValue4: '身心指數',
		DocTxt: '醫師',
		Documentation: 'Documentation',
		dollar: '元',
		downloadreport: '下載掛號名單',
		downloadtemplate: '下載模板',
		downloadtemplateexample: '下載模板範例',
		DragAndDrop: 'Drag & Drop',
		dragfile: '將檔案拖曳此處或<em>點擊</em>上傳',
		DropDown: 'DropDown',
		Ear: '耳道',
		EarlyMorning: '凌晨',
		echarts: 'echarts',
		Edit: '編輯',
		Edit: '編輯',
		EditPw: '修改密碼',
		EditSchedule: '編輯我的預約',
		edu1: '學歷1',
		edu2: '學歷2',
		edu3: '學歷3',
		Email: '信箱',
		EmergencyCI: '緊急聯絡人資訊',
		EmergencyContact: '緊急聯絡人',
		EmergencyContactNum: '緊急聯絡人電話',
		EmergencyRelation: '關係',
		emergencyTime: '急診紀錄',
		emergencyTimeRWD: '急診',
		endDate: '結束日期',
		EndTime: '結束時間',
		EnterCorrectPhoneNumber: '請輸入正確的手機號碼',
		EnterCorrectValue: '請輸入正確數值',
		EnterEndTime: '請輸入結束時間',
		EnterGroupName: '請輸入群組名稱',
		EnterMobile: '請輸入新手機號碼',
		enterPaid: '請輸入金額',
		EnterPasswordAtLeastSix: '請輸入至少6位數密碼',
		EnterRemarks: '請輸入備註內容',
		EnterSamePassword: '請輸入相同密碼',
		EnterSuggestedContent: '請輸入建議內容',
		enterText: '請輸入相關字句',
		EnterTitle: '請輸入標題',
		Enterverify: '請輸入驗證碼',
		Error: '錯誤',
		ExamTxt: '醫檢師',
		exceldownload: 'Excel下載',
		excelExport: 'Excel匯出',
		exp1: '經歷1',
		exp2: '經歷2',
		exp3: '經歷3',
		Export: 'Export',
		ExportSelected: 'Export Selected',
		Extensions: 'Extensions',
		Eye: '眼睛',
		Failed: '失敗',
		famCacel: '家族癌症病史',
		famCacel2: '過敏事項',
		famCacel3: '病史及敏感事項',
		famleSick: '家族慢性病史',
		FAQ: 'FAQ',
		FileName: '檔案名稱',
		filter: '過濾',
		filter2: '篩選',
		Finish: '完成',
		Finished: '已完診',
		FinishFirst: '請先完成此次看診',
		FinishTxt: '完診',
		FixedSchedule: '固定班表',
		ForgetPw: '忘記密碼',
		ForgotPassword: 'Forgot Password',
		FormElements: 'Form Elements',
		FormInputGroup: 'Form Input Group',
		FormLayouts: 'Form Layouts',
		FormsAndTable: 'Forms & Table',
		FormValidation: 'Form Validation',
		FormWizard: 'Form Wizard',
		FreqMenu: '常用選單',
		FriText: '週五',
		FullCalendar: 'Full Calendar',
		FuncTxt: '職能治療師',
		FunduscopyAI2Txt: '綠色：每年做一次眼底鏡檢驗 || 黃色：找眼科醫師檢查及諮詢 || 橘色：盡快找眼科醫師看診 || 紅色：立即找眼科醫師看診',
		FunduscopyAIReportTxt: '眼底鏡AI報告',
		GlouseAC: '血糖(飯前)',
		GlousePC: '血糖(飯後)',
		GoHospital: '到院掛號',
		GoogleMap: 'Google Map',
		Grid: 'Grid',
		Group: '群組',
		GroupName: '群組名稱',
		healthdataerror: '健康數據錯誤',
		HealthlinkCall: '遠盟通話',
		HealTxt: '健康管理師',
		Height: '身高(CM)',
		HSecTxt: '健康秘書',
		I18n: 'I18n',
		IAgreenMentText: '我同意',
		ID: '身分證字號',
		Import: '匯入',
		importmultiple: '多筆匯入',
		Info: '基本資料',
		Input: 'Input',
		InternalMD: '內科',
		Interval: '時段',
		Invoice: 'Invoice',
		KeelungChangGungMemorialHospital: '基隆長庚醫院',
		KnowledgeBase: 'Knowledge Base',
		LastDate: '最後更新日期',
		LateAtNight: '深夜',
		Later: '稍後',
		Left: '左',
		LeftEyeLabel: '左眼',
		LINEmsg: 'LINE 訊息',
		List: 'List',
		ListView: 'List View',
		Loading: 'Loading',
		LockScreen: 'Lock Screen',
		LogIn: '登入',
		Login: '登入',
		LoginFailed: '登入失敗',
		LoginFailed2: '登入金鑰有狀況,請與系統管理員聯繫!',
		LoginType: '選擇登入方式',
		Logout: '登出',
		lowerThanDefaultPrice: '金額少於該醫師預設費用',
		MainlandChinaService: '提供大陸地區諮詢',
		Maintenance: 'Maintenance',
		Man: '男',
		maxAppointment: '每診最大預約人數',
		medicalRule:'檔案命名規則',
		medicalRecord: '病歷',
		medicalReport: '一般患部照片',
		medicalReport0: '患部照片',
		medicalReport10: '咽喉',
		medicalReport11: '口腔',
		medicalReport12: '皮膚',
		medicalReport13: '心電心音',
		medicalReport14: '一般影片',
		medicalReport2: '健檢報告(PDF)',
		medicalReport3: 'DICOM',
		medicalReport4: '眼底鏡圖片',
		medicalReport4_1: '眼底鏡',
		medicalReport5: '超音波照片',
		medicalReport6: '心電圖',
		medicalReport6_1: '心電圖報告',
		medicalReport7: '五官鏡',
		medicalReport8: '耳道',
		medicalReport9: '鼻腔',
		MedicalStaff: '醫事人員',
		Member: '會員',
		MemberNotFind: '查無相關會員資訊',
		Members: '會員管理',
		MenuLevel2p1: 'Menu Level 2.1',
		MenuLevel2p2: 'Menu Level 2.2',
		MenuLevel3p1: 'Menu Level 3.1',
		MenuLevel3p2: 'Menu Level 3.2',
		MenuLevels: 'Menu Levels',
		Message: '訊息管理',
		Message2: '訊息',
		Minutes: '分',
		Miscellaneous: 'Miscellaneous',
		Mobile: '手機號碼',
		mobilecode: '未驗證',
		MobileVeri: '手機驗證',
		MobileVerifyCode: '手機驗證碼',
		Monitor: '遠距監看',
		MonText: '週一',
		month: '本月',
		More: '更多',
		MorningAndNight: '早夜',
		Mouth: '口腔',
		MR: '病歷',
		Msg: '簡訊',
		msg_is_recycle: '點擊收回此則訊息',
		msg_is_seen: '已讀',
		MsgDeliveredSuccessful: '傳送訊息成功',
		myid: '帳號',
		mypass: '密碼',
		myshift: '個人班表',
		Navbar: 'Navbar',
		NetworkConnectionFailed: '網路連線錯誤',
		NewPW: '請輸入新密碼',
		NewPWAgain: '再輸入一次新密碼',
		Newpwlimit6: '新密碼, 至少6位數',
		NewVersionReload: '後台主機系統已更新，請立即重新整理視窗已確保資料正確性',
		nextstep: '下一步',
		night: '晚上',
		nightMobile: '晚',
		noappointment: '目前無掛號資訊',
		NoConnection: '未連線',
		nodata: '目前查無資料',
		Nofile: '目前無相關檔案',
		nogroup: '目前無群組',
		NoInfo: '目前無相關資訊',
		None: '無',
		NoNote: '目前無備註資料',
		nonotify: '無通知紀錄',
		Nose: '鼻腔',
		NoSetReferralHospital: '目前無設定轉診單位',
		NotAuthorized: 'Not Authorized',
		notfounddoctorsoap: '找不到醫事人員S.O.A.P. 類型',
		Notificationofcompletion: '完成報到通知',
		Notifications: 'Notifications',
		Notify: '通知',
		notifytitle: '通知',
		NotStated: '未說明',
		NoUploadData: '目前無上傳資料',
		NoUser: '找不到使用者',
		NoVideo: '目前無視訊連線',
		Now: '目前',
		NTs: '新台幣',
		NumberInput: 'Number Input',
		Numberofpeople: '報到人數',
		NurTxt: '護理師',
		OfflineStatus: '離開',
		OKBtn: '確認',
		Online: '視訊諮詢',
		OnlineStatus: '待診',
		onlyexcelfile: '只接受excel格式文件',
		Onsite: '實地義診',
		OpenBtn: '開啟',
		Opinion: '會診紀錄',
		Opinion2: '被邀請會診項目',
		Opinion2rwd: '被邀請',
		Opinion3: '邀請會診項目',
		Opinion3rwd: '邀請',
		Options: '民眾選擇方案',
		OtherDesc: '其他說明',
		OtherInfo: '其他資訊',
		OtherLogin: '已在其他裝置登入',
		Others: 'Others',
		Othersubjects: '其他科別',
		OtherTime: '其他時段',
		OtherTimeRWD: '其他',
		OtherTxt: '其他',
		OutPatient: '到院看診',
		Pages: 'Pages',
		Pagination: 'Pagination',
		Password: '密碼',
		Patient: '看診人員',
		Patient2: '民眾',
		Patient3: '民眾',
		PatientNotFind: '找不到符合的患者資訊',
		PatientOffline: '民眾目前未上線',
		patientReq: '民眾需求',
		PayDoneNotice: '完成付款通知',
		pdfdownload: 'PDF下載',
		People: '人',
		PeopleNum: ' 人數',
		personalSick: '個人病史',
		PhaTxt: '藥師',
		photoSync: '醫療影像同步',
		healthReportSync: '健檢報告同步',
		photoGraphSync: '患部照片同步',
		PhyTxt: '物理治療師',
		PingChrisTitle:'屏東基督教醫院',
		pleaseClick: '請按',
		PleaseEnterCorrectData: '請輸入正確資料',
		PleaseSelect: '請選擇',
		plsentername: '請輸入帳號與密碼',
		pm: '下午',
		pmMobile: '下',
		PName: '姓',
		PName2: '名',
		PName3: '姓名',
		Popup: 'Popup',
		Preview: '預覽',
		preview_data: '預覽資料',
		prevstep: '上一步',
		Privacy: '註冊合約',
		Log: '操作紀錄',
		Process: '進行中',
		Processing2: '處理中',
		Profile: '個人資訊',
		Progress: 'Progress',
		PsyTxt: '諮商心理師',
		Pulse: '心跳',
		quarterDefinition: '＊本季說明 :ㄧ季定義為三個月分別 1-3 ｜ 4-6 ｜ 7-9 ｜ 10-12',
		questionAndAnswer: '就診問卷',
		QuillEditor: 'Quill Editor',
		Radio: 'Radio',
		RadioTxt: '醫事放射師',
		RaiseSupport: 'Raise Support',
		ReasonForConsultation: '需要',
		ReCall: '回撥',
		RecordFile: '錄影檔案',
		RecordTime: '掛號紀錄',
		RecordTimeRWD: '紀錄',
		Register: '註冊',
		RegisteredNotice: '掛號通知',
		registeredRecord: '掛號紀錄',
		RegisterMember: '註冊會員',
		RegisterNote: '請在此設定您的帳號與密碼',
		RegistrationAgain: '請重新掛號',
		RegTime: '掛號時間',
		Reload: '重新整理',
		ReloadPage: '確認是否關閉重新整理視窗?',
		RemberMeText: '記住帳號',
		remindBeforeReservation: '將於選定時間前三十分鐘提醒雙方',
		removeServiceUnitComfirm: '此操作將移除目前所屬服務單位',
		removeServiceUnitSuccessful: '服務單位移除成功!',
		RepeatLogin: '此帳號已在其他裝置登入,請重新登入',
		ReplayMsg: '回覆預約通知',
		reportValue: '理學與代謝症候群',
		reportValue2: '血脂肪與血糖',
		reportValue3: 'CBC/DC 血液常規',
		reportValue4: '肝膽功能與病毒',
		reportValue5: '細菌與免疫學',
		reportValue6: '腎功能與電解質',
		reportValue7: '尿液生化',
		reportValue8: '尿液常規',
		ReselectUser: '請重新選擇使用者',
		Reservation: '掛號管理',
		reservationInterface: '特約掛號',
		ResetPassword: 'Reset Password',
		RevDoctor: '掛號醫師',
		RevReason: '問診原因',
		Right: '右',
		RightEyeLabel: '右眼',
		SatText: '週六',
		Save: '儲存',
		SaveAndFinish: '儲存並完診',
		SaveAndFinishRwd: '完診',
		Schedule: '我的預約',
		scheduledOff: '排休',
		SciNo: '科研編號',
		Search: '搜尋',
		SearchUpload:'搜尋',
		searchAccount: '身分證/電話(國碼及手機)/居留證',
		searchBtn: '按鈕查詢',
		SearchContact: '搜尋聯絡人',
		searchMRStaff: '搜尋醫事人員',
		searchName: '請輸入姓名',
		searchNameAndAccount: '搜尋姓名或帳號',
		searchNameAndPhone: '搜尋姓名或電話',
		searchNameAndPhoneAndAccount: '搜尋姓名、電話或帳號',
		searchPatientName: '搜尋民眾姓名',
		SearchTotal: '共搜尋',
		season: '本季',
		Seconds: '秒',
		Select: 'Select',
		selecteEterprise: '請選擇企業',
		selecteGroup: '請選擇組別',
		SelectFile: '選擇檔案',
		selectFileNoticeMessage: '檔案大小不超過20MB',
		selectFilenumberlimit: '檔案數量不超過{num}個',
		selectFileRule: '只接受{data}結尾的檔案',
		SelectGroupMember: '請選擇群組會員',
		SelectMember: '選擇民眾會員姓名',
		SelectPlan: '請選擇方案',
		SelectRecipient: '請先選擇傳送對象',
		SelectUser: '請選擇使用者',
		Send: '傳送',
		SendMsg: '發送訊息',
		SendMsgFailed: '簡訊傳送失敗',
		SendMsgFailed: '傳送簡訊失敗',
		SendMsgSuccess: '簡訊傳送成功',
		SendNotify: '訊息通知',
		SendTo: '傳送對象',
		SendVerification: '寄發驗證碼',
		SendWeChatSuccessText: '傳送微信簡訊成功',
		Service: '服務列表',
		Servicesubtitle: '為在民眾掛號時詢問其所需的服務',
		SetOffDay: '請假成功',
		Setting: '系統設定',
		settingSetword: '常用字句設定',
		SetupTime: '建立時間',
		SetWord: '常用字句',
		SetWordsubtitle: '常用字句分為：主觀項目(S)、客觀項目(O)、醫事人員建議(memop)以及文字交談(wd)，可建立常用的罐頭語句供點選使用，以節省打字時間。',
		Shift: '班表設定',
		ShiftCalendar: '班表',
		shiftChangeNotice: '班表變更通知',
		ShiftsNote: '× 只能排休今天以後的日期(不含今天)',
		Shop: 'Shop',
		Sidebar: 'Sidebar',
		SimpleCalendar: 'Simple Calendar',
		Skin: '皮膚',
		Slider: 'Slider',
		SmsAppointText: '您將發送一個語音/視訊網址給會員，若三分鐘內對方未開啟本連結，本連結將會失效。',
		SmsAppointText2: '會員錯過通話服務，掛號已取消。',
		soap_Memop: '醫事人員建議',
		soap_O: '客觀項目',
		soap_S: '主觀項目',
		soap_WD: '文字交談',
		Someonecanceledtheregistration: '有人取消報到',
		SomeoneRegistered: '有人掛號',
		SomeoneReports: '有人完成報到',
		Someonerequestsaconsultation: '有人請求會診',
		Someonesendsamessage: '傳送訊息給您',
		SomeoneSendsAtodo: '有人傳送待辦事項',
		SomeoneShiftBeUpdate: '某人班表已更新',
		SpecialTime: '特約掛號',
		SpecialTimeRWD: '特診',
		StarRatings: 'Star Ratings',
		startDate: '開始日期',
		StartTime: '開始時間',
		Statistics: 'Statistics',
		Status: '狀態',
		Submit: '送出',
		SuccessTxt: '成功',
		SunText: '週日',
		Support: 'Support',
		Surgical: '外科',
		SwiftCodeTxt: 'Swift Code',
		Switch: 'Switch',
		switch_left_right: '上方標籤點擊左右滑動',
		Systolic_Pre: '血壓(收縮壓)',
		Table: '列表',
		Tabs: 'Tabs',
		Tailwind: 'Tailwind',
		Textarea: 'Textarea',
		AppointmentStatus: '預約狀況',
		ThisWeekSeeDoctor: '本周完成看診數',
		ThisWeekStatus: '本周預約狀況',
		Throat: '咽喉',
		ThumbView: 'Thumb View',
		ThuText: '週四',
		Time2: '撥打時間',
		time3: '次',
		TimeLabel: '時間',
		TimeLeft: '剩餘',
		TimeOut: '時段已過',
		TimeToSend: '預計發送時間',
		Tip: '提示',
		TipsPictureFileSize: '圖片大小不能超過 1000MB',
		TipsUploadFileFormat: '上傳文件只能是圖片格式',
		Title2: '社區科研中心',
		TmpNoData: '暫無數據',
		Toask: '交辦事項',
		toask: '交辦事項',
		today: '今日',
		Todo: 'Todo',
		Todolist: '待辦事項',
		Todonotice: '待辦通知',
		Tooltip: 'Tooltip',
		TotalAppoint: '預約人員',
		TotalMembers: '會員總數',
		TotalNum: '總數',
		TotalRecord: '筆',
		Transfer: '轉接醫師',
		TransferTxt: '轉介',
		Tree: 'Tree',
		TueText: '週二',
		Type: '類別',
		UI: 'UI',
		UnChosenContact: '新增聯絡人',
		UnfinishedText: '未完成掛號',
		UnFinishTxt: '未完診',
		UnknownPeriod: '不明時段',
		UnSay: '未說明',
		Update: '更新',
		UpdateFailed: '更新失敗',
		UpdateSuccessful: '更新成功',
		Upload: '醫療檔案上傳',
		UploadBtn: '上傳',
		uploadDICOM: 'DICOM',
		uploadEar: '耳朵',
		uploadECG: '心電圖',
		uploadECG2: '心電圖',
		uploadFace: '五官鏡',
		uploadfile: '上傳文件',
		UploadFinished: '上傳完成',
		uploadFunduscope: '眼底鏡圖片',
		Uploading: '上傳中',
		uploadNasal: '鼻腔',
		uploadOral: '口腔',
		uploadPhoto: '患部照片',
		uploadReport: '檢驗報告(PDF)',
		uploadSearchTitle: '輸入註冊會員之帳號',
		uploadSkin: '皮膚',
		uploadSingle:'醫療檔案上傳(單一會員)',
		uploadMultiple:'醫療檔案上傳(多會員)',
		uploadHealthdata:'健康數據上傳(多會員)',
		uploadSuperSound: '超音波照片',
		uploadThroat: '咽喉',
		UploadTimeLabel: '上傳時間',
		uploadVideo: '一般影片',
		UseMic: '無法進行通訊，請允許使用麥克風與攝影機?',
		User_Guide: '使用教學',
		VerificationCodeRequired: '手機驗證碼必填',
		Video: '視訊',
		videobtn: '使用影像',
		videoplay: '影片播放',
		view_details: '查看詳細資料',
		vipMember: 'VIP會員',
		Voice: '語音',
		voicebtn: '使用聲音',
		VolTxt: '志工師',
		VolunteerOnline: '為弱勢民眾視訊義診',
		Vuesax: 'Vuesax',
		Vuexy: 'Vuexy',
		WarningText1: '本人身為醫護人員充分瞭解醫師法第11條及政府主管機關對遠距診察的相關法令和規範，本人並同意遵守之。本人瞭解除了主管機關核准從事遠距醫療的特定地區，民眾及情況外，遠距視訊諮詢可做為會員到醫療院所就醫前和就醫後的輔助諮詢工具，提供會員更即時和方便的保健諮詢服務，以提升醫病關係並恊助建立家庭醫師制度。',
		WarningText1Title: '警語:',
		WarningText2: '本人了解並同意貴公司謹提供通訊平台供本人和客戶做咨詢服務，不負責處理任何因此諮詢服務所產生本人與客戶的糾紛，亦不必分攤或給付任何糾紛的賠償。',
		WarningText2Title: '平台免責權:',
		WarningText3: '本人了解並同意貴公司系統在諮詢過程執行全程的錄音或錄影，做為收取諮詢費用之依據參考。',
		WarningText3Title: '諮詢過程錄音錄影:',
		WarningText4: '本公司將會代醫護人員依其自訂之費率及服務秒數向會員收取諮詢費用，收款須先扣除3%的金流費用後，本公司將收取餘額的30%做為的平台服務費並開給發票，另70%轉帳給醫護人員。若會員不付款，本公司仍會保證支付給醫護人員以確保您們的權益，並將會在次月的10號結算前一個月份的諮詢費用，以銀行轉帳的方式匯款到醫護人員註冊時所登記的銀行帳戶，但銀行將會由其中收取15至30元不等的匯款費用。',
		WarningText4Title: '諮詢費及平台服務費:',
		WarningText5: '醫護人員瞭解並同意會員一旦與醫護人員預約做健康諮詢，即代表該會員授權該醫護人員及其所屬醫療院所相關醫護人員查看其健康諮詢相關的個資。並授權該醫護人員視必要性將其健康諮詢相關的個資轉授權給指定之轉診及會診的醫護人員及其所屬醫療院所。但醫護人員不得將會員相關的個資複製，傳送或是銷售給未經會員授權或非必要轉診及會診的其他第三者。',
		WarningText5Title: '客戶個資之使用:',
		WarningText6: '本公司為維護服務之品質, 對於不具有醫護人員執照和資格的會員，以及不當或惡意使用本服務之醫護人員，保有單方面取消該會員資格之決定權利。',
		WarningText6Title: '取消醫護人員會員權利:',
		WarningText7: '關於本公司對於您個人資料保護，請詳細閱讀本服務的隱私權聲明',
		WarningText71: '醫生馬上看為健康聯網資訊服務股份有限公司所經營，為了維護個人隱私權與支持個人資料保護，本公司謹以下列聲明向您說明所收集個人資料之目的，類別，利用範圍及方式，以及您所得行使之權利等事項；如果對於本服務的隱私權聲明，以下相關告知事項，或是與個人資料保護有關之相關事項有任何疑問，可以和本公司客服中心連絡，本公司將盡快回覆說明。',
		WarningText72: '適用範圍：本服務隱私權聲明及其所包含的告知事項，僅適用於服務所擁有及經營的網站。本服務網站可能包括許多連結、或是其他合作夥伴及商家，其有關隱私權聲明及個資保護有關之告知事項，請參閱各該連結網站、合作夥伴或是商家之網站。',
		WarningText73: '個人資料蒐集之目的與類別：本公司為了提供電子商務服務、售後服務、履行法定或合約義務、保護當事人及相關利害關係人之權益、行銷、客戶管理與服務、以及和與營業登記項目或組織章程鎖定之業務等目的，依照各服務之性質，有可能會收集您的姓名、聯絡方式（包含但不限於電話、email及地址等）、未完成收款或付款所需之資料、IP地址、所在地點、上傳的病歷、醫療圖像、健康檢查、影音以及其他得以直接或間接辨識使用者身分之個人資料。此外，為提升服務品質，本公司會依照所提供服務之性質，記錄使用者的IP地址、及在本服務相關網站或App內的瀏覽活動（例如，使用者說使用的軟硬體、所點選的網頁、下載的APP）等資料，但是這些資料經工作流量分析和網路行為調查，以便於改善本服務的相關網站的服務品質，不會和特定個人相聯繫。',
		WarningText74: '個人資料的利用：服務所蒐集的足以識別使用者身份的個人資料，包含但不限於商業登記資料、法定代理人姓名、地址、身分證字號；若為自然人時，則為個人姓名、地址、身分證字號等相關資料，僅提供本公司於內部依照蒐集之目的進行處理和利用，除非事先說明、或為完成提供服務或履行合約義務之必要、或依照相關法令規定或有權主管機關之命令或要求，否則本公司不會將涉及使用者身分的個人資料提供給第三人，包括境內及境外，或作收集目的之外之使用。在法令所定應保存之期限內，本公司會持續保管、處理及利用相關資料。',
		WarningText75: '資料安全：本公司將以與產業標準之合理技術和程序，維護個人資料之安全。',
		WarningText76: '資料當事人之權利：資料當事人可以請求查詢、閱覧本人的個資或要求給予複製本。若您的個資有變更、或發現您的個資不正確，可以向本公司要求修改或更正。當收集個人資料之目的消失或期限屆滿時，應可要求刪除、停止處理或利用個人資料。但因本公司執行業務所得必須者，不在此限。',
		WarningText77: 'Cookie : 為了便利使用者，本公司網站可能會讀取儲存在使用者電腦中的cookie資料。使用者可經由瀏覽器的設定、取消、或限制此項功能，但可能因此無法使用部分網站功能。若你想知道如何取消、或限制此項功能，請與本公司客服中心連絡。',
		WarningText78: '影響：如果您不願提供各項服務或交易所要求的相關個人資料予本公司，並且同意本公司就該等個人資料依法令規定、以及隱私權聲明、及其相關告知內容為相關之個人資料蒐集、處理、利用及國際傳輸，本公司將尊重您的決定，但依照各該服務之性質或條件，您可能因此無法使用該等服務或完成相關交易，本公司並保留是否同意提供該等相關服務或完成相關交易之權利。',
		WarningText79: '修訂之權利：本公司有權隨時修訂本隱私權聲明及相關告知事項，於變更與修訂後公佈在本公司之網站及APP內，不另行個別通知，您可以隨時在公司網站及APP內詳閱修訂後的隱私權聲明及相關告知事項。',
		WarningText7Title: '個資安全及隱私權聲明:',
		WeChatCall: '微信通話',
		WedText: '週三',
		week: '本週',
		Week: '本週',
		WishList: 'Wish List',
		Woman: '女',
		word: '字數 300 字以內',
		WrongData: '資料錯誤',
		WrongPassword: '密碼錯誤',
		WrongPasswordOverFour: '密碼輸入錯誤超過4次，請等待15分鐘，在嘗試登入。',
		year: '本年',
		YouCopyTitle: '拷貝檔案路徑',
		YouCopyTxt: '你已拷貝檔案路徑',
		medicalStaffNoGroup: '此醫事人員無所屬照護組別',
		localeBank: '本國銀行',
		foreignBank: '國外銀行',
		AllPatientList: '所有患者名單列表',
		PE_Report: '健檢報告',
		resetPasswordOrNot: '是否前往重新設定新密碼',
		closeWindow: '關閉視窗',
		sendInvitationSuccess: '已傳送相關資料給會診醫師',
		invalidVerifyCodeTimeLimit: '手機驗證碼有效期限為5分鐘',
		unbindLineWarningText1: '是否解除綁定『LINE通知』功能',
		unbindLineWarningText2: '此操作將解除二次驗證功能，您將無法透過LINE收到任何通知。',
		bindSuccess: '綁定成功',
		bindNotYet: '尚未綁定',
		bindLINE: '綁定Line',
		unbindLINE: '解除綁定',
		recieveNewMyTodoList: '收到新的待辦事項',
		todoListDone: '有完成的交辦事項',
		loading: '加載中',
		noMore: '沒有更多',
		changeShiftWarningText1: '此次班表更動將會影響',
		changeShiftWarningText2: '診掛號，您將需要自行通知已掛號民眾做取消掛號的動作，是否仍要更動班表？',
		doctorLeave: '醫師請假',
		plan: '方案',
		divisions: '科別',
		contactAgent: '聯繫代理人',
		sex: '性別',
		age: '年齡',
		noThisPatientAuthorization: '尚未取得該民眾的授權',
		openNewWindow: '開新分頁',
		dontOpenNewWindow: '不開新分頁',
		doctorCallBackObject: '醫師回撥對象',
		PingChrisTitle: '屏東基督教醫院',
		notFoundService: '找不到服務列表類型',
        serviceType: '服務列表類型',
        serviceSetting: '服務列表設定',
		patientNoAuth: '病歷未授權',
		DelContactRemind: '你確定要刪除此聯絡人',
		CamiansaintTitle: '羅東聖母醫院',
		choosePartnerGroup: '請選擇醫事機構',
		partnerWhichMemberBelong: '此會員所隸屬的醫院或機構',
		joinEnterprise: '加入單位',
		advancedMember: '高級會員',
		medicationHistory: '藥歷查詢',
		checkImage: '檢查影像',
		radiography: '放射影像',
		patientList: '患者名單',
		changePartner: '切換機構',
		todayAppointment: '今日待診',
		chatRoomMsg: '聊天室訊息',
		todayGeneralIdea: '本日概況',
		weekGeneralIdea: '本週概況',
		monthGeneralIdea: '本月概況',
		todayAppointmentUint: '診',
		chatRoomMsgUint: '則',
		toDoUint: '項',
		Ask: '交辦',
		apply: '套用',
		noSetWord: '沒有常用字句可使用，請前往系統設定->常用字句進行建立',
		createNow: '馬上建立',
		pastRecords: '過去建議紀錄',
		Authorize:'新增授權',
		ManageShift:'管理班表',
		ContactPatient:'與掛號民眾通話',
		ITRI_AI: '工研院_AI',
		fertilityReport: '生殖檢驗報告',
		Weight:'體重(KG)',
		language: {
			"zh_tw": '繁體中文',
			"en": '英文',
			"zh_cn": '簡體中文'
		},
		mobilecode: {
			"0": '未驗證',
			"1": '已驗證'
		},
		divisions1: [
			'家庭醫學科',
			'呼吸胸腔科',
			'腎臟內科',
			'肝臟內科',
			'心臟內科',
			'腸胃內科',
			'神經內科',
			'血液腫瘤科',
			'風濕免疫科',
			'內分泌科'
		],
		DocdorEnableText: {
			0: {
				0: '歡迎加入醫生馬上看，請完成個人資料的填寫，並等待專員審核上線，謝謝您!',
				1: '歡迎加入醫生馬上看，請完成個人資料的填寫，並等待專員審核上線，謝謝您!',
				32: '歡迎加入基隆長庚，請完成個人資料的填寫，並等待專員審核上線，謝謝您!',
				1575: '歡迎加入智慧之愛公益聯盟，請完成個人資料的填寫，並等待專員審核上線，謝謝您!',
				1577: '歡迎加入振興醫療，請完成個人資料的填寫，並等待專員審核上線，謝謝您!',
				2085: '歡迎加入振興醫療，請完成個人資料的填寫，並等待專員審核上線，謝謝您!'
			},
			1: {
				0: '歡迎加入醫生馬上看，請完成手機號碼驗證，謝謝您!',
				1: '歡迎加入醫生馬上看，請完成手機號碼驗證，謝謝您!',
				32: '歡迎加入基隆長庚，請完成手機號碼驗證，謝謝您!',
				1575: '歡迎加入智慧之愛公益聯盟，請完成手機號碼驗證，謝謝您!',
				1577: '歡迎加入振興醫療，請完成手機號碼驗證，謝謝您!',
				2085: '歡迎加入振興醫療，請完成手機號碼驗證，謝謝您!'
			}
		},
		errormessage: {
			recycleMsg: '已收回此筆資料',
			confirmrecycleMsg: '收回此則訊息將無法復原!是否確認收回此筆資料？'
		},
		el: {
			colorpicker: {
				confirm: '確認',
				clear: '清空'
			},
			datepicker: {
				now: '現在',
				today: '今天',
				cancel: '取消',
				clear: '清空',
				confirm: '確認',
				selectDate: '選擇日期',
				selectTime: '選擇時間',
				selectDateTime:'選擇日期時間',
				startDate: '開始日期',
				startTime: '開始時間',
				endDate: '結束日期',
				endTime: '結束時間',
				prevYear: '前一年',
				nextYear: '後一年',
				prevMonth: '上個月',
				nextMonth: '下個月',
				year: '年',
				month1: '1 月',
				month2: '2 月',
				month3: '3 月',
				month4: '4 月',
				month5: '5 月',
				month6: '6 月',
				month7: '7 月',
				month8: '8 月',
				month9: '9 月',
				month10: '10 月',
				month11: '11 月',
				month12: '12 月',
				week: '周次',
				weeks: {
					sun: '日',
					mon: '一',
					tue: '二',
					wed: '三',
					thu: '四',
					fri: '五',
					sat: '六'
				},
				months: {
					jan: '一月',
					feb: '二月',
					mar: '三月',
					apr: '四月',
					may: '五月',
					jun: '六月',
					jul: '七月',
					aug: '八月',
					sep: '九月',
					oct: '十月',
					nov: '十一月',
					dec: '十二月'
				}
			},
			select: {
				loading: '加載中',
				noMatch: '無匹配資料',
				noData: '無資料',
				placeholder: '請選擇'
			},
			cascader: {
				noMatch: '無匹配資料',
				loading: '加載中',
				placeholder: '請選擇',
				noData: '無資料'
			},
			pagination: {
				goto: '前往',
				pagesize: '項/頁',
				total: '共 {total} 項',
				pageClassifier: '頁'
			},
			messagebox: {
				title: '提示',
				confirm: '確定',
				cancel: '取消',
				error: '輸入的資料不符規定!'
			},
			upload: {
				deleteTip: '按 delete 鍵可刪除',
				delete: '刪除',
				preview: '查看圖片',
				continue: '繼續上傳'
			},
			table: {
				emptyText: '暫無資料',
				confirmFilter: '篩選',
				resetFilter: '重置',
				clearFilter: '全部',
				sumText: 'Sum'
			},
			tree: {
			  	emptyText: '暫無資料'
			},
			transfer: {
				noMatch: '無匹配資料',
				noData: '無資料',
				titles: ['List 1', 'List 2'],
				filterPlaceholder: 'Enter keyword',
				noCheckedFormat: '{total} items',
				hasCheckedFormat: '{checked}/{total} checked'
			},
			image: {
			  	error: '加載失敗'
			},
			pageHeader: {
			  	itle: '返回'
			},
			popconfirm: {
				confirmButtonText: 'Yes',
				cancelButtonText: 'No'
			},
		},
		message: {
			data_not_input: '資料未輸入',
			upload_success: '上傳成功',
			upload_error: '上傳失敗',
			upload_file_error: '上傳失敗!請檢查檔案大小或檔案格式!',
			data_apdate_success: '資料更新成功',
			search_full_message: '請於搜尋列表輸入完整的身分證/電話/居留證!',
			confirm_delete_record: '確認刪除此筆資料',
			send_success: '傳送成功',
			delete_success: '刪除成功',
			send_error: '傳送失敗',
			delete_error: '刪除失敗',
			register_again: '重新掛號',
			register_success: '掛號成功',
			register_error: '掛號失敗',
			register_finish: '完成看診',
		},
		upload:{
			report_batch:{
				rule:{
					1: 'A12XXXX789_20220225.jpg，以登入帳號為開頭，並使用底線"_"與原檔名分開',
					2: 'A12XXXX789_20220225.pdf，以登入帳號為開頭，並使用底線"_"與原檔名分開，需為pdf檔',
					3: 'A12XXXX789_20220225.DCM，以登入帳號為開頭，並使用底線"_"與原檔名分開，需為DCM檔',
					4: 'A12XXXX789_L_20220225.jpg，以登入帳號為開頭，左右眼為第二資訊(L:左眼 R:右眼)，並分別使用底線"_"與原檔名分開',
					14: 'A12XXXX789_20220225.mov，以登入帳號為開頭，並使用底線"_"與原檔名分開，需為mov檔',
				}
			},
			healthdata_batch: {
				title_note: '檔案可以重新上傳;若有相同人與相同量測日期的資料、會以新資料為準，不會重複上傳',
				table_title_note: '部分資料未上傳，提醒如下',
				title:{
					'sheet': '工作表名稱',
					'row': '行數',
					'col': '列數',
					'warning': '未上傳原因'
				},
				examplenote:'(原民會專用)'
			}
		}
	},
	en: {
		404: '404',
		500: '500',
		AccessControl: 'Access Control',
		AccountClosed: 'The account was closed',
		accountID: 'ID',
		AccountNameTxt: 'Account Name',
		AccountNoTxt: 'Account No',
		accountPhone: 'Phone',
		AddAppointBtn: '',
		AddBtn: 'Add',
		AddCase: 'Add Case',
		AddData: 'Add Data',
		AddFailed: 'Failed',
		addGroup: 'Add Group',
		AddGroupFailed: 'Fail to add a group',
		AddGroupSuccessful: 'Successfully to add a group',
		addMRStaff: 'Add Medical staff',
		AddNote: 'Add Note',
		AddSchedule: 'Add Schedule',
		AddScheduleBtn: 'Add Schedule',
		AddService: 'Add Service',
		AddSuccess: 'Success',
		AddWord: 'Add Word',
		Advisory: 'Records',
		AgentTime: 'Make an Appt.',
		AgentTimeRWD: 'Appointment',
		agGridTable: 'agGrid Table',
		AgreenMentTitle: 'RegistrationAgreement and Privacy Policy',
		AIReportTxt: 'AI report is only for reference, It\'s not qulified for you to decide if you are healthy nr not. You are recommended to go to see a doctor if you don\'t feel comfortable or have any concern about your health.',
		Alert: 'Alert',
		Alert2: 'Alert',
		AlertTxt: 'Alert',
		All: 'All',
		AllianceTitle: 'Wiselove',
		am: 'AM',
		amMobile: 'AM',
		Analytics: 'Analytics',
		AnonymousTxt: 'Anonymous',
		ApexCharts: 'Apex Charts',
		AppNum: 'Appts',
		appointment: 'Appointment',
		appointment2: 'Reservation',
		appointment3: 'View MR',
		appointment4: 'Video',
		AppointTime: ' Appointment',
		Apps: 'Apps',
		ArrangeShifts: 'Arrange Shifts',
		Authentication: 'Authentication',
		Authorization: 'Authorization',
		Autocomplete: 'Autocomplete',
		Avatar: 'Avatar',
		BackBtn: 'Back',
		Backlogin: 'Back Login',
		Badge: 'Badge',
		BankAddressTxt: 'Address',
		BankCodeTxt: 'Bank Code',
		BankInfoTxt: 'Bank Info',
		BankTxt: 'Bank',
		Basic: 'Basic',
		Birthday: 'Birthday',
		BloodOx: 'Blood Oxygen',
		BodyTemper: 'Body Temperature',
		BranchTxt: 'Branch',
		Breadcrumb: 'Breadcrumb',
		BuildFailed: 'failed',
		BuildSuccessful: 'Successful',
		BusyStatus: 'Busy',
		Button: 'Button',
		ButtonGroup: 'Button Group',
		Calendar: 'Calendar',
		CamiansaintTitle:'Camilian Saint',
		Call: 'Call',
		CallBack: 'Call Back',
		CallBackRwd: 'Call Back',
		CallTime: ' Calldatetime',
		Cancel: 'Cancel',
		CancelDayOff: 'Cancel day off setting',
		CancelRegister: 'Cancel Registered',
		CancelRegisteredNotice: 'Cancel Registered',
		Cancelregistrationnotice: 'Cancel registration notice',
		CancelSystemRegister: 'System Cancel Registered',
		CannotDraw: 'Insufficient information, Can not Draw',
		Card: 'Card',
		CardActions: 'Card Actions',
		CardColors: 'Card Colors',
		CardID: 'Card ID',
		CareTxt: 'Care Worker',
		Carousel: 'Carousel',
		Cart: 'Cart',
		Case: 'Case',
		CaseDone: 'Done',
		CaseTitle: 'Case Title',
		CaseTxt: 'Case management',
		ChangeMobile: 'Change Mobile!',
		ChangeMobileNote: 'Enter a new phone number, get a verification code.',
		ChangeMobileNote2: 'Change Password will be Logout, use New Password Login。',
		ChangeMobilePW: 'Change Mobile Password',
		ChangePW: 'Change Password',
		ChangeTime: 'Change',
		Chart: 'Chart',
		chartist: 'chartist',
		chartjs: 'chartjs',
		Charts: 'Charts',
		ChartsAndMaps: 'Charts & Maps',
		Chat: 'Chat',
		Checkbox: 'Checkbox',
		CheckDate: 'Check Date',
		CheckDoctorField: 'Please Check Doctor Info',
		CheckField: 'Please Check Field!',
		CheckIn: 'Check In',
		Checkout: 'Checkout',
		CheckPatientField: 'Please Check Patient Info',
		ChengHsinTitle: 'Cheng-Hsin Hospital',
		ChineseMD: 'Chinese Medicine',
		Chip: 'Chip',
		choiceCompanyusually: 'Please select common word',
		choiceDoctorMember: 'Please select an Doctor Member.',
		choiceFilter: 'Please select filter.',
		choiceGroup: 'Please select an group.',
		choicePatientDate: 'Please select a Date.',
		choicePatientDatetime: 'Please select an Datetime.',
		choicePatientMember: 'Please select an Patient Member.',
		choicePatientMember_1: 'Please select an Patient Member ID or Patient Name.',
		choicePatientMember_2: 'Please Filter an Patient',
		choicePatientTime: 'Please select a time.',
		chooseRegidentifyType: 'Choose Register Identify',
		chooseRegType: 'Choose Register Type',
		chosen_forgin_or_not: 'Domestic And Foreign Banks',
		ChosenContact: 'Exist Contact',
		CLCTitle: 'CLC CARE',
		clinicalTxt: 'Clinical Psychologist',
		Clipboard: 'Clipboard',
		CNs: 'CN',
		Collapse: 'Collapse',
		Colors: 'Colors',
		ComingSoon: 'Coming Soon',
		Completed: 'Finished',
		Components: 'Components',
		Confirm: 'Confirm',
		ConfirmBtn: 'Confirm',
		ConfirmConsultationDone: 'Is the consultation done?',
		ConfirmPassword: 'Confirm Password',
		ConfirmReDial: 'Confirm to re-dial',
		confirmText_1: 'Please confirm the following information',
		confirmText_2: 'Appointments Doctor: ',
		confirmText_3: 'Patient Name: ',
		confirmText_4: 'Payment plan: ',
		confirmText_5: '/',
		confirmText_6: 'min',
		confirmText_7: 'Reservation Time: ',
		confirmText_8: 'day, Period: ',
		confirmText_8_2: 'Period: ',
		confirmText_9: 'Notice：Please remind the patient to complete the payment within 30 minutes。',
		ConnectFirst: 'Please go online first',
		ConnectionFailed: 'Network connection failed',
		Consultation: 'Consultation',
		Consultationnotice: 'Consultation notice',
		ConsultBtn: 'Consult',
		ConsultDescription: 'Invite Consult Description',
		ConsultDoctor: 'Doctor',
		ConsultPlan: 'Select Plan',
		ConsultPlan2: 'Select Plan',
		ConsultProblem: 'Request',
		ConsultTitle: 'Invite Consult doctor',
		Contact: 'Contact',
		ContactTxt: 'More',
		ContainAndFinish: 'Contain',
		ContainAndFinishRwd: 'Contain',
		Content: 'Content',
		Content2: 'Content',
		ContentText: 'Content',
		ContextMenu: 'Context Menu',
		Country: 'Country',
		CreateDate: 'CreateDate',
		CRM: 'CRM',
		crmNote: 'CRM',
		curtitle: 'CURDr.',
		customerSuggest: 'Suggest',
		CusTxt: 'Customer Service',
		Dashboard: 'Dashboard',
		DataCanNotEmpty: 'Field Can Not Empty!',
		DataContainOK: 'Successfully Container',
		DataErrCannotDraw: 'Data Error, Can not Draw',
		DataList: 'Data List',
		DataNotEnough: 'Data is not enough',
		DataSaved: 'Data saved',
		DataSaveFailed: 'Save failed',
		DataSaveOK: 'Successfully saved!',
		Datepicker: 'Datepicker',
		DatetimePicker: 'Datetime Picker',
		Del: 'Delete',
		DeleteDataFailed: 'Fail to delete data',
		DeleteDataSuccessful: 'Data deleted',
		DeliverVerificationFailed: 'Fail to deliver verification code',
		DeliverVerificationSuccessful: 'Verification code delievred',
		demoText: 'Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.',
		demoTitle: 'Card Title',
		Description: 'Description',
		Detail: 'Detail',
		DetailBtn: 'Detail',
		DetailBtn: 'Detail',
		Dialogs: 'Dialogs',
		Diastolic_Pre: 'Diastolic Pressure',
		dicomdescription: '5 levels of retinopathy acused by diabetes',
		dicomdescription1: 'means the level of need of further medical treatment.',
		dicomdescription2: 'The length of time from the Q wave of the electrocardiogram to the first heart sound (S1) can indicate the ability of the heart to eject blood. The normal value should be less than 15%. When EMAT ≧ 15%, Indicates a possible risk of suspected poor systolic function and heart failure.',
		dicomdescription3: 'EMAT',
		dicomdescription4: 'A comprehensive index of systolic function, ranging from 0 to 10 units, the normal value should be less than 5. When SDI ≧ 5 means possible risk of suspected systolic impairment. SDI ≧ 7.5 indicates a possible risk of suspected severe systolic impairment.',
		dicomdescription5: 'Systolic disfunction index',
		dicomdescription6: 'The heart sound produced by the heart during diastole, the range is between 0 and 10 units, and the normal value should be less than 5. When S3 ≧ 5, it means maybe suspected of impaired diastolic function, risk of heart failure.',
		dicomdescription7: 'S3',
		dicomdescription8: 'The heart sound produced by the heart during diastole, the range is between 0 and 10 units, and the normal value should be less than 5. When S4 ≧ 5, it means maybe at risk of suspected aortic sclerosis, ischemia, or left ventricular hypertrophy.',
		dicomdescription9: 'S4',
		DietitianTxt: 'Dietitian',
		DisabledMenu: 'Disabled Menu',
		Divider: 'Divider',
		doctorAccess: 'Authorized Agent',
		doctorBank: 'Bankbook Cover',
		doctorbankaccount: 'A/C No.',
		doctorbankaccountname: 'A/C Name',
		doctorbankaddress: 'Address',
		doctorbankbranch: 'Branch',
		doctorbankinfo: 'Bank Info',
		doctorbankname: 'Bank Name',
		doctorbankswiftcode: 'SwiftCode',
		doctorChinaDayFee: 'Day Rate - China',
		doctorChinaNightFee: 'Emg\'t & Night Rate - China',
		doctorDayFee: 'Day Rate',
		doctorDICOM: 'DICOM',
		doctorEducation: 'Education',
		doctorEE: 'Education / Experience',
		doctorExperience: 'Experience',
		doctorExpertise: 'Specialty',
		DoctorInfo: 'Doctor Info',
		DoctorInfoChosen: 'Select a medical staff',
		DoctorInfoNotChosen: 'No medical staff selected',
		DoctorInfosubtitle: 'Search and select those doctors and nurses you want to work with.',
		doctorLang: 'Service Lagaunge',
		doctorLicense: 'Medical License',
		doctorLife: 'Life & Health',
		doctorLifeValue: 'Vital Sign',
		doctorNightFee: 'Emg\'t & Night Rate',
		DoctorNoService: 'The doctor doesn\'t offer service, please find another doctor.',
		doctorNote: 'Notes',
		DoctorOffline: 'Doctor is not online now',
		doctorPhoto: 'Photo',
		doctorReason: 'Service List',
		doctorRecord: 'Picture & Report',
		doctorReport: 'Lab. Reports',
		doctorservice: 'My Service',
		doctorServiceType: 'Service Type',
		doctorsoap: 'Doctor S.O.A.P. type.',
		doctorstatus: 'Status',
		DoctorStatusText: 'Coop. Doctor',
		doctorSuggest: 'Consulting Records',
		doctortype: 'Type',
		doctorUnit: 'Institution',
		doctorValue2: 'Metabolism',
		doctorValue3: 'Body Shape',
		doctorValue4: 'Heart Rate Variability',
		DocTxt: 'Doctor',
		Documentation: 'Documentation',
		dollar: '',
		downloadreport: 'Dowload',
		downloadtemplate: 'Download template',
		downloadtemplateexample: 'Download sample template.',
		DragAndDrop: 'Drag & Drop',
		dragfile: 'Drag the file here or <em>click</em> upload.',
		DropDown: 'DropDown',
		Ear: 'Ear',
		EarlyMorning: 'Early Morning',
		echarts: 'echarts',
		Edit: 'Edit',
		Edit: 'Edit',
		EditPw: 'Edit Password',
		EditSchedule: 'Edit Schedule',
		edu1: 'Education1',
		edu2: 'Education2',
		edu3: 'Education3',
		Email: 'Email',
		EmergencyCI: 'Emergency contact information',
		EmergencyContact: 'Emergency Contact',
		EmergencyContactNum: 'Emergency Contact Number',
		EmergencyRelation: 'Relationship',
		emergencyTime: 'emergency record',
		emergencyTimeRWD: 'emergency',
		endDate: 'End Date',
		EndTime: 'End Time',
		EnterCorrectPhoneNumber: 'Please enter correct mobile phone number',
		EnterCorrectValue: 'enter the correct value',
		EnterEndTime: 'Please enter the end time',
		EnterGroupName: 'Please enter the group name',
		EnterMobile: 'Enter Phone Number',
		enterPaid: 'Please enter price',
		EnterPasswordAtLeastSix: 'Please enter a password with at least 6 digits',
		EnterRemarks: 'Please enter the remarks',
		EnterSamePassword: 'Please enter the same password again',
		EnterSuggestedContent: 'Please Enter Suggested Content',
		enterText: 'Enter Text Please',
		EnterTitle: 'Please enter a subject',
		Enterverify: 'Enter verification code',
		Error: 'Error',
		ExamTxt: 'Medical examiner',
		exceldownload: 'Download Excel',
		excelExport: 'Export as Excel',
		exp1: 'Experience1',
		exp2: 'Experience2',
		exp3: 'Experience3',
		Export: 'Export',
		ExportSelected: 'Export Selected',
		Extensions: 'Extensions',
		Eye: 'Eye',
		Failed: 'Failed',
		famCacel: 'Family Cancer History',
		famCacel2: 'Allergy',
		famCacel3: 'Medical/Disease History',
		famleSick: 'Family Chronicle Disease History',
		FAQ: 'FAQ',
		filter: 'Filter',
		filter2: 'Filter',
		Finish: 'Done',
		Finished:'Finished',
		FinishFirst: 'Please complete first!',
		FinishTxt: 'Finished',
		FixedSchedule: 'Fixed Schedule',
		ForgetPw: 'Forget Password',
		ForgotPassword: 'Forgot Password',
		FormElements: 'Form Elements',
		FormInputGroup: 'Form Input Group',
		FormLayouts: 'Form Layouts',
		FormsAndTable: 'Forms & Table',
		FormValidation: 'Form Validation',
		FormWizard: 'Form Wizard',
		FreqMenu: 'Frequently',
		FriText: 'Fri.',
		FullCalendar: 'Full Calendar',
		FuncTxt: 'Functional therapist',
		FunduscopyAI2Txt: 'Green : Do inspection once a year. || Yellow : Go to see a ophthalmologist for further diagnose. || Orange : Go to see a ophthalmologist as soon as possible. || Red : Go to see a ophthalmologist immediately.',
		FunduscopyAIReportTxt: 'Funduscopy AI Report',
		GlouseAC: 'Glouse-AC',
		GlousePC: 'Glouse-PC',
		GoHospital: 'In Hospital',
		GoogleMap: 'Google Map',
		Grid: 'Grid',
		Group: 'Group',
		GroupName: 'Group Name',
		healthdataerror: 'Health Data Error',
		HealthlinkCall: 'Healthlink Call',
		HealTxt: 'Health manager',
		Height: 'Height',
		HSecTxt: 'Health secretary',
		I18n: 'I18n',
		IAgreenMentText: 'I Agree',
		ID: 'ID',
		Import: 'Import',
		importmultiple: 'Import multiple records',
		Info: 'Info',
		Input: 'Input',
		InternalMD: 'Internal Medicine',
		Interval: 'Interval',
		Invoice: 'Invoice',
		KeelungChangGungMemorialHospital: 'Keelung Chang Gung Memorial Hospital',
		KnowledgeBase: 'Knowledge Base',
		LastDate: 'Last update date',
		LateAtNight: 'Late at Night',
		Later: 'Later',
		Left: 'Left',
		LeftEyeLabel: 'Left Eye',
		LINEmsg: 'LINE message',
		List: 'List',
		ListView: 'List View',
		Loading: 'Loading',
		LockScreen: 'Lock Screen',
		LogIn: 'Login',
		Login: 'Login',
		LoginFailed: 'Login Failed',
		LoginFailed2: 'Login Failed',
		LoginType: 'Login Type',
		Logout: 'Logout',
		lowerThanDefaultPrice: 'The amount is less than the doctor\'s preset fee',
		MainlandChinaService: 'Mainland China Service',
		Maintenance: 'Maintenance',
		Man: 'Man',
		maxAppointment: 'Max. Appoin\'ts',
		medicalRecord: 'Medical Record',
		medicalReport: 'Photograph',
		medicalReport0: 'Photograph',
		medicalReport10: 'Throat',
		medicalReport11: 'Oral',
		medicalReport12: 'Skin',
		medicalReport13: 'Phono',
		medicalReport14: 'Video',
		medicalReport2: 'PE Report (PDF)',
		medicalReport3: 'DICOM',
		medicalReport4: 'Funduscope',
		medicalReport4_1: 'Funduscope',
		medicalReport5: 'Ultrasonograph',
		medicalReport6: 'ECG',
		medicalReport6_1: 'ECG Report',
		medicalReport7: 'Face diagnostic scope',
		medicalReport8: 'Ear',
		medicalReport9: 'Nasal',
		MedicalStaff: 'Medical staff',
		Member: 'Member',
		MemberNotFind: 'Can not find the member\'s data',
		Members: 'Member',
		MenuLevel2p1: 'Menu Level 2.1',
		MenuLevel2p2: 'Menu Level 2.2',
		MenuLevel3p1: 'Menu Level 3.1',
		MenuLevel3p2: 'Menu Level 3.2',
		MenuLevels: 'Menu Levels',
		medicalRule:'uploadRule',
		Message: 'Message',
		Message2: 'Message',
		Minutes: 'minutes',
		Miscellaneous: 'Miscellaneous',
		Mobile: 'Mobile',
		mobilecode: 'Phone is not verified',
		MobileVeri: 'Verification',
		MobileVerifyCode: 'Phone Verification Code.',
		Monitor: 'Remote Monitor',
		MonText: 'Mon.',
		month: 'Month',
		More: 'More',
		MorningAndNight: 'Morning and Night',
		Mouth: 'Mouth',
		MR: 'EMR',
		Msg: 'Message',
		msg_is_recycle: 'Click to recycle this message',
		msg_is_seen: 'Read',
		MsgDeliveredSuccessful: 'Message delivered',
		myid: 'ID / Phone',
		mypass: 'Password',
		myshift: 'My Shift',
		Navbar: 'Navbar',
		NetworkConnectionFailed: 'Network connection failed',
		NewPW: 'New Password',
		NewPWAgain: 'New Password Again',
		Newpwlimit6: 'New password, at least 6',
		NewVersionReload: 'The background host system has been updated, please refresh the window immediately to ensure the correctness of the data.',
		nextstep: 'Next',
		night: 'NIGHT',
		nightMobile: 'NIGHT',
		noappointment: 'Currently no reservation',
		NoConnection: 'Not online yet',
		nodata: 'No data found.',
		Nofile: 'Currently no relavent file',
		nogroup: 'No Group',
		NoInfo: 'Currently no relavent data',
		None: 'None',
		NoNote: 'Currently no note data',
		nonotify: 'No Notification',
		Nose: 'Nose',
		NoSetReferralHospital: 'Not yet set referral hospital',
		NotAuthorized: 'Not Authorized',
		notfounddoctorsoap: 'Can not find medical staff\'s S.O.A.P type',
		Notificationofcompletion: 'Notification of completion',
		Notifications: 'Notifications',
		Notify: 'Notification',
		notifytitle: 'Notification',
		NotStated: 'Not Stated',
		NoUploadData: 'Currently no upload data',
		NoUser: 'Can not find the user',
		NoVideo: 'Currently no video link',
		Now: 'Now',
		NTs: 'NT',
		NumberInput: 'Number Input',
		Numberofpeople: 'Check-ins',
		NurTxt: 'Nurse',
		OfflineStatus: 'Offline',
		OKBtn: 'OK',
		Online: 'Video Chat',
		OnlineStatus: 'Online',
		onlyexcelfile: 'Only Excel file is acceptable.',
		Onsite: 'Onsite',
		OpenBtn: 'Open',
		Opinion: 'Consultation',
		Opinion2: 'Be Invited',
		Opinion2rwd: 'Be Invited',
		Opinion3: 'To Invite',
		Opinion3rwd: 'To Invite',
		Options: 'Options',
		OtherDesc: 'Others',
		OtherInfo: 'Other',
		OtherLogin: 'Signed in from another device',
		Others: 'Others',
		Othersubjects: 'Other Subjects',
		OtherTime: 'Other Time',
		OtherTimeRWD: 'Other',
		OtherTxt: 'Other',
		OutPatient: 'Out-patient',
		Pages: 'Pages',
		Pagination: 'Pagination',
		Password: 'Password',
		Patient: 'Appointments',
		Patient2: 'Patient',
		Patient3: 'Patient',
		PatientNotFind: 'Can not find eligible patient ',
		PatientOffline: 'Patient is not online now',
		patientReq: 'Patient requirement',
		PayDoneNotice: 'Paid',
		pdfdownload: 'PDF Downlaod',
		People: '',
		PeopleNum: ' people',
		personalSick: 'Personal Medical History',
		PhaTxt: 'Pharmacist',
		photoSync: 'Screen Share',
		healthReportSync: 'Screen Share',
		photoGraphSync: 'Screen Share',
		PhyTxt: 'Physical therapist',
		PingChrisTitle:'ptch hospital',
		pleaseClick: 'Please Click',
		PleaseEnterCorrectData: 'Please enter correct data',
		PleaseSelect: 'Please select',
		plsentername: 'Please Enter Account And Password!',
		pm: 'PM',
		pmMobile: 'PM',
		PName: 'First Name',
		PName2: 'Last Name',
		PName3: 'Name',
		Popup: 'Popup',
		Preview: 'Preview',
		preview_data: 'Preview data',
		prevstep: 'Previous',
		Privacy: 'Agreement',
		Log: 'Log record',
		Process: 'Processing',
		Processing2: 'Processing',
		Profile: 'About Me',
		Progress: 'Progress',
		PsyTxt: 'Consultant Psychologist',
		Pulse: 'Pulse',
		quarterDefinition: '* Quarter definition : Yearly 4 quarters are 1-3 ｜ 4-6 ｜ 7-9 ｜ 10-12',
		questionAndAnswer: 'Question',
		QuillEditor: 'Quill Editor',
		Radio: 'Radio',
		RadioTxt: 'Medical radiologist',
		RaiseSupport: 'Raise Support',
		ReasonForConsultation: 'Reason',
		ReCall: 'Recall',
		RecordFile: 'Record File',
		RecordTime: 'Registered Record',
		RecordTimeRWD: 'Record',
		Register: 'Register',
		RegisteredNotice: 'Registered Notice',
		registeredRecord: 'Registered Record',
		RegisterMember: 'Register',
		RegisterNote: 'Setup your Name And Password',
		RegistrationAgain: 'Please make registration again.',
		RegTime: 'Register',
		Reload: 'Reload',
		ReloadPage: 'Reload Page?',
		RemberMeText: 'Remember Me',
		remindBeforeReservation: 'Both parties will be reminded 30 minutes before the selected time.',
		removeServiceUnitComfirm: 'The operation will remove the belonged service institution',
		removeServiceUnitSuccessful: 'Remove the belonged service institution done.',
		RepeatLogin: 'This account is already logged in on another device, please log in again',
		ReplayMsg: 'Reply to Notice',
		reportValue: 'Body & vital signs',
		reportValue2: 'Blood fat & sugar',
		reportValue3: 'CBC / DC Blood',
		reportValue4: 'Hepatobiliary & virus',
		reportValue5: 'Bacterial & immunological',
		reportValue6: 'Renal electrolytes',
		reportValue7: 'Urine Biochemical',
		reportValue8: 'Urine',
		ReselectUser: 'Please select users again',
		Reservation: 'Appointment',
		reservationInterface: 'Reservation',
		ResetPassword: 'Reset Password',
		RevDoctor: 'Doctor',
		RevReason: 'Reason',
		Right: 'Right',
		RightEyeLabel: 'Right Eye',
		SatText: 'Sat.',
		Save: 'Save',
		SaveAndFinish: 'Save And Finish',
		SaveAndFinishRwd: 'Finish',
		Schedule: 'Scheduled',
		scheduledOff: 'Scheduled Off',
		SciNo: 'No',
		Search: 'Search',
		SearchUpload:'Search a member',
		searchAccount: 'ID / Phone / ROC',
		searchBtn: '',
		SearchContact: 'Search Contact',
		searchMRStaff: 'Search Medical staff',
		searchName: 'Please enter a name.',
		searchNameAndAccount: 'Search Name Or Account',
		searchNameAndPhone: 'Search Name',
		searchNameAndPhoneAndAccount: 'Search Name, Phone Or Account',
		searchPatientName: 'Search patient name.',
		SearchTotal: 'Total',
		season: 'Season',
		Seconds: 'seconds',
		Select: 'Select',
		selecteEterprise: 'Please Select',
		selecteGroup: 'Please Select',
		SelectFile: 'Select File',
		selectFileNoticeMessage: 'File limit 20 MB',
		selectFilenumberlimit: 'Maximum number of files is {num}',
		selectFileRule: 'Only accept files ending with {data}',
		SelectGroupMember: 'Please select members of the group',
		SelectMember: 'Select',
		SelectPlan: 'Please choose a plan',
		SelectRecipient: 'Please select a recipient',
		SelectUser: 'Please select users',
		Send: 'Send',
		SendMsg: 'Send Message',
		SendMsgFailed: 'Failed',
		SendMsgFailed: 'Fail to deliver message',
		SendMsgSuccess: 'Success',
		SendNotify: 'Notify',
		SendTo: 'Select',
		SendVerification: 'Send Verification Code',
		SendWeChatSuccessText: 'Send WeChat Success',
		Service: 'Service List',
		Servicesubtitle: 'Provide a service list for patients to select when they make an appointment.',
		SetOffDay: 'Off day is set',
		Setting: 'Setting',
		settingSetword: 'Set frequent used words',
		SetupTime: 'Setup time',
		SetWord: 'Can Words',
		SetWordsubtitle: 'Set up frequent use canned words will save your typing time. Canned words type : Subject、Object、Advice and Text chat.',
		Shift: 'Shift',
		ShiftCalendar: 'shift schedule',
		shiftChangeNotice: 'shift update notice',
		ShiftsNote: 'Can only be scheduled for dates after today.(Excluding today)',
		Shop: 'Shop',
		Sidebar: 'Sidebar',
		SimpleCalendar: 'Simple Calendar',
		Skin: 'Skin',
		Slider: 'Slider',
		SmsAppointText: 'You are sending an audio/Video call webpage to the member,If this call is not connected within three minutes, the registration will be cancelled.',
		SmsAppointText2: 'The member missed the call, the registration has been cancelled.',
		soap_Memop: 'Medical Staff Advice',
		soap_O: 'Objectivity',
		soap_S: 'subjectivity',
		soap_WD: 'Word Chat',
		Someonecanceledtheregistration: 'Someone canceled the registration',
		SomeoneRegistered: 'Someone Registered',
		SomeoneReports: 'Someone Reports',
		Someonerequestsaconsultation: 'Someone requests a consultation',
		Someonesendsamessage: 'Some one sends a message',
		SomeoneSendsAtodo: 'Someone sends a to-do',
		SomeoneShiftBeUpdate: 'Someone\'s Shift schedule been updated',
		SpecialTime: 'Special Clinic',
		SpecialTimeRWD: 'Special Clinic',
		StarRatings: 'Star Ratings',
		startDate: 'Start Date',
		StartTime: 'Start Time',
		Statistics: 'Statistics',
		Status: 'Status',
		Submit: 'Submit',
		SuccessTxt: 'Success',
		SunText: 'Sun.',
		Support: 'Support',
		Surgical: 'Surgical',
		SwiftCodeTxt: 'Swift Code',
		Switch: 'Switch',
		switch_left_right: 'switch left and right',
		Systolic_Pre: 'Systolic Pressure',
		Table: 'Table',
		Tabs: 'Tabs',
		Tailwind: 'Tailwind',
		Textarea: 'Textarea',
		AppointmentStatus: 'Appointments',
		ThisWeekSeeDoctor: 'Weekly Diagnosis',
		ThisWeekStatus: 'Weekly Appointments',
		Throat: 'Throat',
		ThumbView: 'Thumb View',
		ThuText: 'Thu.',
		Time2: 'Time',
		time3: '',
		TimeLabel: 'Time',
		TimeLeft: 'Time left ',
		TimeOut: 'Time Out',
		TimeToSend: 'Send time',
		Tip: 'Hinet',
		TipsPictureFileSize: 'Picture file can not over 20MB',
		TipsUploadFileFormat: 'Upload file must be a picture format',
		Title2: 'Community Science Research Center',
		TmpNoData: 'No Data',
		Toask: 'To Ask',
		toask: 'To Ask',
		today: 'Today',
		Todo: 'Todo',
		Todolist: 'To-Dos',
		Todonotice: 'Todo notice',
		Tooltip: 'Tooltip',
		TotalAppoint: 'Appointments',
		TotalMembers: 'Total Members',
		TotalNum: 'Total',
		TotalRecord: '',
		Transfer: 'Transfer',
		TransferTxt: 'Transfer',
		Tree: 'Tree',
		TueText: 'Thu.',
		Type: 'Type',
		UI: 'UI',
		UnChosenContact: 'Add Contact',
		UnfinishedText: 'Unfinished',
		UnFinishTxt: 'Unfinished',
		UnknownPeriod: 'Unknown',
		UnSay: 'Not Description',
		Update: 'Update',
		UpdateFailed: 'Update Failed',
		UpdateSuccessful: 'Update done',
		Upload: 'Upload File',
		UploadBtn: 'Upload File',
		uploadDICOM: 'DICOM',
		uploadEar: 'Ear',
		uploadECG: 'ECG & Phono',
		uploadECG2: 'ECG & Phono',
		uploadFace: 'Face diagnostic scope',
		uploadfile: 'Upload file',
		UploadFinished: 'Upload done',
		uploadFunduscope: 'Funduscope',
		Uploading: 'Uploading',
		uploadNasal: 'Nasal',
		uploadOral: 'Oral',
		uploadPhoto: 'Photograph',
		uploadReport: 'Inspection (PDF)',
		uploadSearchTitle: 'A/C ',
		uploadSkin: 'Skin',
		uploadSuperSound: 'Ultrasonograph',
		uploadThroat: 'Throat',
		UploadTimeLabel: 'Upload Time',
		uploadVideo: 'Video',
		uploadSingle:'Upload Files (for a single member)',
		uploadMultiple:'Upload Files (for multiple members)',
		uploadHealthdata:'Health data upload (multiple members)',
		UseMic: 'Please allow microphone and camera',
		User_Guide: 'User Guide',
		VerificationCodeRequired: 'Verification code is required',
		Video: 'Video',
		videobtn: 'Use video',
		videoplay: 'videoplay',
		view_details: 'View details',
		vipMember: 'VIP Member',
		Voice: 'Voice',
		voicebtn: 'Use voice',
		VolTxt: 'Volunteer',
		VolunteerOnline: 'Volunteer online service for vulnerable people',
		Vuesax: 'Vuesax',
		Vuexy: 'Vuexy',
		WarningText1: 'Each country has its specific regulation about what and how a physiciancan do healthcare for a customer by means of telecommunication. As a physician, I agree to comply to the regulation. Tele-healthcare service is more suitableforgeneral healthcare consultation, or as a supplement before or after giving a treatment to a customer.',
		WarningText1Title: 'Warning:',
		WarningText2: 'I understand Curdoctor only provides a communication platform for healthcare practitioners to server their customers. It does not represent a healthcare practitioner or a medical institution and does not participate in the healthcare or medical activity between a customer and a healthcare practitioner either. Therefore, I agree to indemnify Curdoctor against any disputes and claim with a customer as a result of using of this platform.',
		WarningText2Title: 'Indemnification of Curdoctor:',
		WarningText3: 'I agree and authorize Curdoctor to record of my consultation service as an evidence for collection of consultation fee.',
		WarningText3Title: 'Authorize Record:',
		WarningText4: 'I agree and authorize Curdoctor to collect consultation fee from customer according to the rate I set. I agree to share 30% of the net fee income, after deducting 3% financial process fee, to Curdoctor. Curdoctor should transfer the monthly balance after deducting bank’s wire transfer fee to my designated bank account by15th day of next month.',
		WarningText4Title: 'Service fee:',
		WarningText5: 'I understand once the customer initiates a consultation with me, it means customer authorizes me and the other necessary assistants, including consultation doctors, nurses, to check his/her data. I guaranty I’ll not copy, transfer or sell his/her data to unauthorized or unnecessary third parties.',
		WarningText5Title: 'Use of customer data:',
		WarningText6: 'In order to maintain the quality of service, I understand and agree Curdoctor have the right to cancel my membership unilaterally if I use the service improperly or maliciously.',
		WarningText6Title: 'Cancellation of membership right:',
		WarningText7: 'For personal data protection, please read the Privacy Statement of the service with caution.',
		WarningText71: 'CURDr. APP is operated by Curdoctor InformationServices Corp. In order to maintain personal privacy and support the protection of personal data, the Company wishes to explain to you the purpose, type, scope and manner of the personal data collection, and the rights you earn. If you have any question in relation to the privacy of the Service, Please contact the Company\'s customer service center, and the Company will reply to you as soon as possible.',
		WarningText72: 'Scope of application: The Privacy Statement of the Service and thenotices contained therein apply only to this APP. ThisAPP may include many links, or other partners, including but not limited to third-party payment institions and merchants. Please refer to each linked website, partner, or merchant\'s website for information about the privacy statement and individual protection.',
		WarningText73: 'Purpose and category of personal data collection : For the purposes of providinghealthcare service match making,e-commerce services, after-sales service, fulfilling statutory or contractual obligations, protecting the interests of related parties, marketing, customer management and services, and business in  registration items or articles of association,the Company will collect your name, contact information (including but not limited to telephone, email,address, etc.), incomplete collection or payment information, and other personaldata that directly or indirectly identifies the user\'s body in accordance with the nature of each service. In addition, in order to improve the quality of service, theCompany will record the user\'s IP address, as well as the browsing activities within the App (e.g., the hardware and software used , the selected web page, the downloaded APP). But these data id only used for improvement of the quality of service by traffic analysis and behavior survey. It will not be linked toa specific individual.',
		WarningText74: 'Use of personal data: Personal data collected bythis APP Service is sufficient toidentify the user,including but not limited to commercial registration information, legal representative name, address, identity card number, if a natural person, are personal name, address, ID card number and other relevant information. It is only used for the Company\'s internal processing, and use in accordance with the purposes of collection, and provide to other partners and merchants within a reasonable scope of collection, processing, storage,transmissionanduse of such data for authorization of application of payment, etc. The Company will not provide personal data relating to the identity of the user to third parties, both domestic and oversea, or for use for purposes other than the purpose of the collection, unless stated in advance, or necessary to complete the service or fulfill contractual obligations, or by order or request of the competent authority in accordance with the relevant laws and regulations or the competent authority. The Company will keep, process and use the relevant information for the period according to regulations.',
		WarningText75: 'Data Security: The Company will maintain the security of personal data in reasonable technology and procedures with industry standards.',
		WarningText76: 'The rights of the data party : the data party may request inquiries or browsing ofthe party’s data. The Company may charge the necessary fees for the costs required for processing. If your personal data has changed or you find that your data is incorrect, you may request a change or correction from the Company. When the purpose of collecting personal data disappears or the period expires, itmay be requested to delete, stop processing or using the personal data. However, it is not include those required for the Company\'s business practice.',
		WarningText77: 'Cookies: For the convenience of the user, the Company\'s website may readcookies stored in the user\'s device.If you would like to know how to cancel or limit this feature, please contact our customer service center. ',
		WarningText78: 'Impact: If you do not wish to provide the relevant personal data required for the services or transactions to the Company, and agree to the Company follows legal requirement, the privacy statement, and its related notices for the collection, processing, utilization and international transmission of the relevant personal data. the Company will respect your decision, but in accordance with the nature or conditions of each service, you may not be able to use such services or complete the relevant transactions. The Company reserves the right if to provide such such services or complete the relevant transactions.',
		WarningText79: 'Right of amendment : The Company reserves the right to revise this Privacy Statement and related notices at any time, and post a notice in the Company\'s website and APP after the change and revision, without personal notice You may at any time check the revised privacy statement and related notices in the Company\'s website and APP.',
		WarningText7Title: 'Security and Privacy Statement:',
		WeChatCall: 'WeChat Call',
		WedText: 'Wed.',
		week: 'Week',
		Week: 'Week',
		WishList: 'Wish List',
		Woman: 'Woman',
		word: 'words limit 300',
		WrongData: 'Wrong data',
		WrongPassword: 'Wrong password',
		Weight:'Weight(KG)',
		WrongPasswordOverFour: 'Wrong password over 4 times, please try again 15 minutes later. ',
		year: 'Year',
		YouCopyTitle: 'Copy File Path ',
		YouCopyTxt: 'You just copied',
		medicalStaffNoGroup: 'The medical staff deesn\'t belonged to any care group yet.',
		localeBank: 'Taiwan\'s bank',
		foreignBank: 'Non-Taiwan bank',
		AllPatientList: 'All patient list',
		PE_Report: 'PE Report',
		resetPasswordOrNot: 'If you want to reset your password?',
		closeWindow: 'Close the window.',
		sendInvitationSuccess: 'Already sent related data to consultation doctor.',
		invalidVerifyCodeTimeLimit: 'The verification code is valid for 5 minutes.',
		unbindLineWarningText1: 'If you want to cancel binding with "LINE Notification" ?',
		unbindLineWarningText2: 'System will cancel double verification function. You will not receive any notification message from LINE.',
		bindSuccess: 'Bind successfully',
		bindNotYet: 'Not yet to bind',
		bindLINE: 'Bind with LINE',
		unbindLINE: 'Cancel binding',
		recieveNewMyTodoList: 'Receive a new assignment',
		todoListDone: 'Finished assignment',
		loading: 'Loading',
		noMore: 'No more',
		changeShiftWarningText1: 'The change will affect',
		changeShiftWarningText2: 'registered patients. You have to notify those patients about the change. Do you want to make the change?',
		doctorLeave: 'Doctor is off.',
		plan: 'Plan',
		divisions: 'Division',
		contactAgent: 'Contact caregiver',
		sex: 'Sex',
		age: 'Age',
		noThisPatientAuthorization: 'Not get patient\'s authorization yet',
		openNewWindow: 'Open new window',
		dontOpenNewWindow: 'Stay in the window',
		doctorCallBackObject: 'Whom to call',
		PingChrisTitle: 'Pingtung Christian Hospital',
		notFoundService: 'No matched service type',
		serviceType: 'Service type',
		serviceSetting: 'Set service type',
		patientNoAuth: 'Not get patient\'s authorization yet',
		DelContactRemind: 'Are you sure to delete the contact person?',
		CamiansaintTitle: 'Camilians Saint Mary\'s Hospital Loudong',
		choosePartnerGroup: 'Please select an institution',
		partnerWhichMemberBelong: 'The member\'s belonged institution',
		joinEnterprise: 'Add a group',
		advancedMember: 'VIP member',
		medicationHistory: 'Medication history',
		checkImage: 'Medical pictures',
		radiography: 'Radiograph',
		patientList: '患者名單',
		changePartner: '切換機構',
		todayAppointment: 'Appoints',
		chatRoomMsg: 'Chat Messages',
		todayGeneralIdea: 'Today',
		weekGeneralIdea: 'This Week',
		monthGeneralIdea: 'This Month',
		todayAppointmentUint: '',
		chatRoomMsgUint: '',
		toDoUint: '',
		Ask: 'Assign',
		apply: 'Apply',
		noSetWord: 'No Can Words available',
		createNow: 'Create Now',
		pastRecords: 'Past Records',
		Authorize:'Authorize',
		ManageShift:'ManageShift',
		ContactPatient:'Contact Appt.Patient',
		ITRI_AI: 'ITRI_AI',
		fertilityReport: 'fertility Report',
		language: {
			"zh_tw": 'Traditional Chinese',
			"en": 'English',
			"zh_cn": 'Simplified Chinese'
		},
		mobilecode: {
			"0": 'Phone is not verified',
			"1": 'Phone is verified'
		},
		divisions1: [
			'家庭醫學科',
			'呼吸胸腔科',
			'腎臟內科',
			'肝臟內科',
			'心臟內科',
			'腸胃內科',
			'神經內科',
			'血液腫瘤科',
			'風濕免疫科',
			'內分泌科'
		],
		DocdorEnableText: {
			0: {
				0: 'Welcome to join CurDr., Please fullfil your data and submit for approval. Thank you very much.',
				1: 'Welcome to join CurDr., Please fullfil your data and submit for approval. Thank you very much.',
				32: 'Welcome to join Chang-Geng., Please fullfil your data and submit for approval. Thank you very much.',
				1575: 'Welcome to join 智慧之愛公益聯盟., Please fullfil your data and submit for approval. Thank you very much.',
				1577: 'Welcome to join 振興醫療. Please fullfil your data and submit for approval. Thank you very much.',
				2085: 'Welcome to join 振興醫療. Please fullfil your data and submit for approval. Thank you very much.'
			},
			1: {
				0: 'Welcome to join CurDr., Please fullfil your mobile verify. Thank you very much.',
				1: 'Welcome to join CurDr., Please fullfil your mobile verify. Thank you very much.',
				32: 'Welcome to join 基隆長庚., Please fullfil your mobile verify. Thank you very much.',
				1575: 'Welcome to join 智慧之愛公益聯盟., Please fullfil your mobile verify. Thank you very much.',
				1577: 'Welcome to join 振興醫療., Please fullfil your mobile verify. Thank you very much.',
				2085: 'Welcome to join 振興醫療., Please fullfil your mobile verify. Thank you very much.'
			}
		},
		errormessage: {
			recycleMsg: 'This Message has been recycle',
			confirmrecycleMsg: 'This Message will able to recycle! Are you sure to recycle This Message?'
		},
		el: {
			colorpicker: {
				confirm: 'OK',
				clear: 'Clear'
			},
			datepicker: {
				now: 'Now',
				today: 'Today',
				cancel: 'Cancel',
				clear: 'Clear',
				confirm: 'OK',
				selectDate: 'Select date',
				selectTime: 'Select time',
				selectDateTime:'Select date and time',
				startDate: 'Start Date',
				startTime: 'Start Time',
				endDate: 'End Date',
				endTime: 'End Time',
				prevYear: 'Previous Year',
				nextYear: 'Next Year',
				prevMonth: 'Previous Month',
				nextMonth: 'Next Month',
				year: '',
				month1: 'January',
				month2: 'February',
				month3: 'March',
				month4: 'April',
				month5: 'May',
				month6: 'June',
				month7: 'July',
				month8: 'August',
				month9: 'September',
				month10: 'October',
				month11: 'November',
				month12: 'December',
				week: 'week',
				weeks: {
					sun: 'Sun',
					mon: 'Mon',
					tue: 'Tue',
					wed: 'Wed',
					thu: 'Thu',
					fri: 'Fri',
					sat: 'Sat'
				},
				months: {
					jan: 'Jan',
					feb: 'Feb',
					mar: 'Mar',
					apr: 'Apr',
					may: 'May',
					jun: 'Jun',
					jul: 'Jul',
					aug: 'Aug',
					sep: 'Sep',
					oct: 'Oct',
					nov: 'Nov',
					dec: 'Dec'
				}
			},
			select: {
				loading: 'Loading',
				noMatch: 'No matching data',
				noData: 'No data',
				placeholder: 'Select'
			},
			cascader: {
				noMatch: 'No matching data',
				loading: 'Loading',
				placeholder: 'Select',
				noData: 'No data'
			},
			pagination: {
				goto: 'Go to',
				pagesize: '/page',
				total: 'Total {total}',
				pageClassifier: ''
			},
			messagebox: {
				title: 'Message',
				confirm: 'OK',
				cancel: 'Cancel',
				error: 'Illegal input'
			},
			upload: {
				deleteTip: 'press delete to remove',
				delete: 'Delete',
				preview: 'Preview',
				continue: 'Continue'
			},
			table: {
				emptyText: 'No Data',
				confirmFilter: 'Confirm',
				resetFilter: 'Reset',
				clearFilter: 'All',
				sumText: 'Sum'
			},
			tree: {
			  	emptyText: 'No Data'
			},
			transfer: {
				noMatch: 'No matching data',
				noData: 'No data',
				titles: ['List 1', 'List 2'], // to be translated
				filterPlaceholder: 'Enter keyword', // to be translated
				noCheckedFormat: '{total} items', // to be translated
				hasCheckedFormat: '{checked}/{total} checked' // to be translated
			},
			image: {
			  	error: 'FAILED'
			},
			pageHeader: {
				title: 'Back' // to be translated
			},
			popconfirm: {
				confirmButtonText: 'Yes',
				cancelButtonText: 'No'
			}
		},
		message: {
			data_not_input: 'No data entered',
			upload_success: 'Upload successful',
			upload_error: 'Upload failed',
			upload_file_error: 'Upload failed. Please check file size and format.',
			data_apdate_success: 'Data update successful.',
			search_full_message: 'Please enter the complete ID, Phone or Residence card number in the search box.',
			confirm_delete_record: 'Confirm delete one record',
			send_success: 'Transmission successful.',
			delete_success: 'Delete successful',
			send_error: 'Transmission failed.',
			delete_error: 'Delete error',
			register_again: 'Please register again.',
			register_success: 'Registration successful.',
			register_error: 'Registration failed.',
			register_finish: 'Complete consultation',
		},
		upload:{
			report_batch:{
				rule:{
					1:'Files should be named as {patient_id}_{my filename}. Ex., A123456789_myphoto.jpg',
					2:'Files should be in PDF format and named as {patient_id}_{my filename}. Ex., A123456789_myfile.pdf',
					3:'Files should be in DCM format and named as {patient_id}_{my filename}. Ex., A123456789_myfile.dcm',
					4:'Files should be named as {patient_id}_{L or R}_{my filename}, such A123456789_L_myfile.jpg for a left eye image, or A123456789_R_myfile.jpg for a right eye image.',
					14:'Files should be in MOV format and named as {patient_id}_{my filename}. Ex., A123456789_myfile.mov',
				}
			},
			healthdata_batch: {
				title_note: 'Files can be re-uploaded; if there are data for the same person and the same measurement date, the new data will prevail and will not be uploaded again',
				table_title_note: 'Some information has not been uploaded, the reminder is as follows',
				title:{
					'sheet': 'sheet name',
					'row': 'Rows',
					'col': 'Columns',
					'warning': 'Reason for not uploading'
				},
				examplenote:'(for Aboriginal Association only)'
			}
		}
	},
	de: {
		Dashboard: 'Instrumententafel',
		eCommerce: 'eCommerce',
		Apps: 'Apps',
		Todo: 'Machen',
		Chat: 'Plaudern',
		Email: 'Email',
		Calendar: 'Kalender',
		FullCalendar: 'Calendrier completVollständiger Kalender',
		SimpleCalendar: 'Einfacher Kalender',
		Shop: 'Geschäft',
		Cart: 'Wagen',
		WishList: 'Wunschzettel',
		Checkout: 'Auschecken',
		UI: 'UI',
		DataList: 'Datenliste',
		ListView: 'Listenansicht',
		ThumbView: 'Daumenansicht',
		Grid: 'Gitter',
		Vuexy: 'Vuexy',
		Vuesax: 'Vuesax',
		Tailwind: 'Tailwind',
		Colors: 'Farben',
		Card: 'Karte',
		Basic: 'Basic',
		Statistics: 'Statistiken',
		Analytics: 'Analytics',
		CardActions: 'Kartenaktionen',
		CardColors: 'Kartenfarben',
		Table: 'Tabelle',
		agGridTable: 'agGrid-Tabelle',
		Components: 'Komponenten',
		Alert: 'Warnen',
		Avatar: 'Benutzerbild',
		Badge: 'Abzeichen',
		Breadcrumb: 'Breadcrumb',
		Button: 'Taste',
		ButtonGroup: 'Schaltflächengruppe',
		Chip: 'Chip',
		Collapse: 'Zusammenbruch',
		Dialogs: 'Dialoge',
		Divider: 'Teiler',
		DropDown: 'Dropdown-Liste',
		List: 'Liste',
		Loading: 'Wird geladen',
		Navbar: 'Navbar',
		Notifications: 'Benachrichtigungen',
		Pagination: 'Seitennummerierung',
		Popup: 'Pop-up',
		Progress: 'Fortschritt',
		Sidebar: 'Seitenleiste',
		Slider: 'Schieberegler',
		Tabs: 'Tabs',
		Tooltip: 'QuickInfo',
		Upload: 'Hochladen',
		FormsAndTable: 'Formulare und Tabelle',
		FormElements: 'Formularelemente',
		Select: 'Wählen',
		Switch: 'Schalter',
		Checkbox: 'Ankreuzfeld',
		Radio: 'Radio',
		Input: 'Eingang',
		NumberInput: 'Nummer eingeben',
		Textarea: 'Textbereich',
		FormLayouts: 'Formularlayouts',
		FormWizard: 'Formzauberer',
		FormValidation: 'Formularvalidierung',
		FormInputGroup: 'Formulareingabegruppe',
		Pages: 'Seiten',
		Authentication: 'Authentifizierung',
		Login: 'Anmeldung',
		Register: 'Registrieren',
		ForgotPassword: 'Passwort vergessen',
		ResetPassword: 'Passwort zurücksetzen',
		LockScreen: 'Bildschirm sperren',
		Miscellaneous: 'Verschiedenes',
		ComingSoon: 'Demnächst',
		Error: 'Error',
		404: '404',
		500: '500',
		NotAuthorized: 'Nicht berechtigt',
		Maintenance: 'Instandhaltung',
		Profile: 'Profil',
		Invoice: 'Rechnung',
		FAQ: 'FAQ',
		Search: 'Suche',
		KnowledgeBase: 'Wissensbasis',
		ChartsAndMaps: 'Diagramme und Karten',
		Charts: 'Diagramme',
		ApexCharts: 'Apex Charts',
		chartjs: 'chartjs',
		echarts: 'echarts',
		chartist: 'chartist',
		GoogleMap: 'Google Karte',
		Extensions: 'Erweiterungen',
		QuillEditor: 'Quill-Editor',
		DragAndDrop: 'Ziehen und loslassen',
		Datepicker: 'Datumsauswahl',
		DatetimePicker: 'Datum / Uhrzeit-Auswahl',
		AccessControl: 'Zugangskontrolle',
		I18n: 'I18n',
		Carousel: 'Karussell',
		Clipboard: 'Zwischenablage',
		ContextMenu: 'Kontextmenü',
		StarRatings: 'Sterne Bewertungen',
		Autocomplete: 'Autovervollständigung',
		Tree: 'Baum',
		Import: 'Einführen',
		Export: 'Export',
		ExportSelected: 'Auswahl exportieren',
		Others: 'Andere',
		MenuLevels: 'Menüebenen',
		MenuLevel2p1: 'Menüebene 2.1',
		MenuLevel2p2: 'Menüebene 2.2',
		MenuLevel3p1: 'Menüebene 3.1',
		MenuLevel3p2: 'Menüebene 3.2',
		DisabledMenu: 'Deaktiviertes Menü',
		Support: 'Unterstützung',
		Documentation: 'Dokumentation',
		RaiseSupport: 'Unterstützung erheben',
		demoTitle: 'Kartentitel',
		demoText: 'Kuchen Sesam Schnaps Cupcake Lebkuchen Dänisch Ich liebe Lebkuchen. Apfelkuchen-Torte-Jujuben chupa chups muffin halvah Lutscher. Schokoladenkuchenhaferkuchen Tiramisu-Marzipanzuckerpflaume. Donut süße Kuchen Hafer Kuchen Dragée Obstkuchen Zuckerwatte Zitrone Tropfen.',
	},
	fr: {
		Dashboard: 'Tableau de bord',
		eCommerce: 'commerce électronique',
		Apps: 'Applications',
		Todo: 'Faire',
		Chat: 'Bavarder',
		Email: 'Email',
		Calendar: 'Calendrier',
		FullCalendar: 'Calendrier complet',
		SimpleCalendar: 'Calendrier simple',
		Shop: 'Boutique',
		Cart: 'Chariot',
		WishList: 'Liste de souhaits',
		Checkout: 'Check-out',
		UI: 'UI',
		DataList: 'Liste de données',
		ListView: 'Voir la liste',
		ThumbView: 'Thumb View',
		Grid: 'la grille',
		Vuexy: 'Vuexy',
		Vuesax: 'Vuesax',
		Tailwind: 'Tailwind',
		Colors: 'Couleurs',
		Card: 'Carte',
		Basic: 'De base',
		Statistics: 'Statistiques',
		Analytics: 'Analytique',
		CardActions: 'Actions de carte',
		CardColors: 'Couleurs de la carte',
		Table: 'Table',
		agGridTable: 'tableau agGrid',
		Components: 'Composants',
		Alert: 'Alerte',
		Avatar: 'Avatar',
		Badge: 'Badge',
		Breadcrumb: 'Breadcrumb',
		Button: 'Bouton',
		ButtonGroup: 'Groupe de boutons',
		Chip: 'Puce',
		Collapse: 'Effondrer',
		Dialogs: 'Dialogues',
		Divider: 'Diviseur',
		DropDown: 'Menu déroulant',
		List: 'liste',
		Loading: 'Chargement',
		Navbar: 'Barre de navigation',
		Notifications: 'Les notifications',
		Pagination: 'Pagination',
		Popup: 'Apparaitre',
		Progress: 'Le progrès',
		Sidebar: 'Barre latérale',
		Slider: 'Curseur',
		Tabs: 'Onglets',
		Tooltip: 'Info-bulle',
		Upload: 'Télécharger',
		FormsAndTable: 'Formulaires et tableau',
		FormElements: 'Éléments de formulaire',
		Select: 'Sélectionner',
		Switch: 'Commutateur',
		Checkbox: 'Case à cocher',
		Radio: 'Radio',
		Input: 'Contribution',
		NumberInput: 'Nombre d\'entrée',
		Textarea: 'Textarea',
		FormLayouts: 'Dispositions de formulaire',
		FormWizard: 'Assistant de formulaire',
		FormValidation: 'Validation du formulaire',
		FormInputGroup: 'Groupe de saisie de formulaire',
		Pages: 'Pages',
		Authentication: 'Authentification',
		Login: 'S\'identifier',
		Register: 'registre',
		ForgotPassword: 'Mot de passe oublié',
		ResetPassword: 'réinitialiser le mot de passe',
		LockScreen: 'Écran verrouillé',
		Miscellaneous: 'Divers',
		ComingSoon: 'Arrive bientôt',
		Error: 'Erreur',
		404: '404',
		500: '500',
		NotAuthorized: 'Pas autorisé',
		Maintenance: 'Entretien',
		Profile: 'Profil',
		Invoice: 'Facture d\'achat',
		FAQ: 'FAQ',
		Search: 'Chercher',
		KnowledgeBase: 'Base de connaissances',
		ChartsAndMaps: 'Graphiques Et Cartes',
		Charts: 'Graphiques',
		ApexCharts: 'Apex Charts',
		chartjs: 'chartjs',
		echarts: 'echarts',
		chartist: 'chartist',
		GoogleMap: 'Google Carte',
		Extensions: 'Extensions',
		QuillEditor: 'Éditeur de piquants',
		DragAndDrop: 'Drag & Drop',
		Datepicker: 'Sélecteur de date',
		DatetimePicker: 'Sélecteur de date / heure',
		AccessControl: `Contrôle d'accès`,
		I18n: 'I18n',
		Carousel: 'Carrousel',
		Clipboard: 'Presse-papiers',
		ContextMenu: 'Menu contextuel',
		StarRatings: 'Classement par étoiles',
		Autocomplete: 'Terminé automatiquement',
		Tree: 'Arbre',
		Import: 'Importation',
		Export: 'Exportation',
		ExportSelected: 'Exporter sélectionné',
		Others: 'Autre',
		MenuLevels: 'Niveaux de menu',
		MenuLevel2p1: 'Niveau de menu 2.1',
		MenuLevel2p2: 'Niveau de menu 2.2',
		MenuLevel3p1: 'Niveau de menu 3.1',
		MenuLevel3p2: 'Niveau de menu 3.2',
		DisabledMenu: 'Menu désactivé',
		Support: 'Soutien',
		Documentation: 'Documentation',
		RaiseSupport: 'Augmenter le soutien',
		demoTitle: 'Titre de la carte',
		demoText: `Le gâteau au sésame s'enclenche au petit pain au pain d'épices danois J'adore le pain d'épices. Tarte aux pommes et jujubes chupa chups muffin halvah sucette. Tiramisu gâteau au chocolat gâteau d'avoine tiramisu prune sucre. Donut tarte sucrée gâteau à l'avoine dragée gouttes de fruits gâteau au citron en barbe à papa.`,
	},
	pt: {
		Dashboard: 'painel de controle',
		eCommerce: 'comércio eletrônico',
		Apps: 'APPS',
		Todo: 'Façam',
		Chat: 'Bate-papo',
		Email: 'O email',
		Calendar: 'Calendário',
		FullCalendar: 'Calendário Completo',
		SimpleCalendar: 'Calendário Simples',
		Shop: 'fazer compras',
		Cart: 'Carrinho',
		WishList: 'Lista de Desejos',
		Checkout: 'Confira',
		UI: 'UI',
		DataList: 'Lista de dados',
		ListView: 'Exibição de lista',
		ThumbView: 'Thumb View',
		Grid: 'Grade',
		Vuexy: 'Vuexy',
		Vuesax: 'Vuesax',
		Tailwind: 'Tailwind',
		Colors: 'Cores',
		Card: 'Cartão',
		Basic: 'Basic',
		Statistics: 'Estatisticas',
		Analytics: 'Analytics',
		CardActions: 'Ações do Cartão',
		CardColors: 'Cores do cartão',
		Table: 'Mesa',
		agGridTable: 'Tabela AgGrid',
		Components: 'Componentes',
		Alert: 'Alerta',
		Avatar: 'Avatar',
		Badge: 'Crachá',
		Breadcrumb: 'Breadcrumb',
		Button: 'Botão',
		ButtonGroup: 'Grupo de botões',
		Chip: 'Lasca',
		Collapse: 'Colapso',
		Dialogs: 'Diálogos',
		Divider: 'Divisor',
		DropDown: 'Suspenso',
		List: 'Lista',
		Loading: 'Carregando',
		Navbar: 'Navbar',
		Notifications: 'Notificações',
		Pagination: 'Paginação',
		Popup: 'Aparecer',
		Progress: 'Progresso',
		Sidebar: 'Barra Lateral',
		Slider: 'Controle Deslizante',
		Tabs: 'Guias',
		Tooltip: 'dica de ferramenta',
		Upload: 'Envio',
		FormsAndTable: 'Formulários e tabela',
		FormElements: 'Elementos do formulário',
		Select: 'Selecione',
		Switch: 'Interruptor',
		Checkbox: 'Caixa de seleção',
		Radio: 'Rádio',
		Input: 'Entrada',
		NumberInput: 'Entrada numérica',
		Textarea: 'Textarea',
		FormLayouts: 'Layouts de formulário',
		FormWizard: 'Assistente de Formulário',
		FormValidation: 'Validação de Formulário',
		FormInputGroup: 'Grupo de entrada de formulário',
		Pages: 'PÁGINAS',
		Authentication: 'Autenticação',
		Login: 'Entrar',
		Register: 'Registo',
		ForgotPassword: 'Esqueceu A Senha',
		ResetPassword: 'Redefinir Senha',
		LockScreen: 'Tela de bloqueio',
		Miscellaneous: 'Diversos',
		ComingSoon: 'Em breve',
		Error: 'Erro',
		404: '404',
		500: '500',
		NotAuthorized: 'Não autorizado',
		Maintenance: 'Manutenção',
		Profile: 'Perfil',
		Invoice: 'Fatura',
		FAQ: 'Perguntas frequentes',
		Search: 'Procurar',
		KnowledgeBase: 'Base de Conhecimento',
		ChartsAndMaps: 'Gráficos E Mapas',
		Charts: 'Gráficos',
		ApexCharts: 'Apex Charts',
		chartjs: 'chartjs',
		echarts: 'echarts',
		chartist: 'chartist',
		GoogleMap: 'Google Mapa',
		Extensions: 'Extensão',
		QuillEditor: 'Quill-Editor',
		DragAndDrop: 'Arraste e solte',
		Datepicker: 'Datepicker',
		DatetimePicker: 'Seletor de data e hora',
		AccessControl: 'Controle de acesso',
		I18n: 'I18n',
		Carousel: 'Carrossel',
		Clipboard: 'Prancheta',
		ContextMenu: 'Menu contextual',
		StarRatings: 'Classificações por estrelas',
		Autocomplete: 'autocompletar',
		Tree: 'Árvore',
		Import: 'Importar',
		Export: 'Exportar',
		ExportSelected: 'Exportar Selecionado',
		Others: 'Outras',
		MenuLevels: 'Níveis de Menu',
		MenuLevel2p1: 'Nível de menu 2.1',
		MenuLevel2p2: 'Nível de menu 2.2',
		MenuLevel3p1: 'Nível de menu 3.1',
		MenuLevel3p2: 'Nível de menu 3.2',
		DisabledMenu: 'Menu desativado',
		Support: 'Apoio, suporte',
		Documentation: 'Documentação',
		RaiseSupport: 'Levantar Suporte',
		demoTitle: 'Título do cartão',
		Ask:'Ask',
		demoText: `O sésamo do bolo agarra dinamarquês do pão-de-espécie do queque eu amo o pão-de-espécie. Torta de torta de maçã jujuba chupa chups muffin halvah pirulito. Ameixa do açúcar do maçapão do tiramisu do bolo da aveia do bolo de chocolate. Bolo de aveia de torta doce rosquinha dragée fruitcake algodão doce gotas de limão.`,
	},
	cn: {
		404: '404',
		500: '500',
		AccessControl: 'Access Control',
		AccountClosed: '此帐号已关闭',
		accountID: '身分证',
		AccountNameTxt: '户名',
		AccountNoTxt: '帐号',
		accountPhone: '手机',
		AddAppointBtn: '预约',
		AddBtn: '新增',
		AddCase: '新增案件',
		AddData: '新增数据',
		AddFailed: '新增失败',
		addGroup: '新增群组',
		AddGroupFailed: '新增群组失败',
		AddGroupSuccessful: '新增群组成功',
		addMRStaff: '新增医事人员',
		AddNote: '新增备注',
		AddSchedule: '新增我的预约',
		AddScheduleBtn: '新增预约',
		AddService: '新增服务项目',
		AddSuccess: '新增成功',
		AddWord: '新增常用字句',
		Advisory: '咨询纪录',
		AgentTime: '代理挂号',
		AgentTimeRWD: '代挂',
		agGridTable: 'agGrid Table',
		AgreenMentTitle: '医护人员注册合约',
		AIReportTxt: 'AI人工智能检测报告仅供您参考，不能做为是否就医的依据。若您有身体不适状况，应尽速至医疗院所由医师为您诊察。',
		Alert: 'Alert',
		Alert2: '提示',
		AlertTxt: '警告',
		All: '全部',
		AllianceTitle: '远距之爱',
		am: '上午',
		amMobile: '上',
		Analytics: 'Analytics',
		AnonymousTxt: '访客',
		ApexCharts: 'Apex Charts',
		AppNum: '预约人数',
		appointment: '预约',
		appointment2: '预约挂号',
		appointment3: '检视病历',
		appointment4: '检视影像',
		AppointTime: ' 预约挂号',
		Apps: 'Apps',
		ArrangeShifts: '休诊设定',
		Authentication: 'Authentication',
		Authorization: '授权管理',
		Autocomplete: 'Autocomplete',
		Avatar: 'Avatar',
		BackBtn: '上一页',
		Backlogin: '返回登入',
		Badge: 'Badge',
		BankAddressTxt: '地址',
		BankCodeTxt: '银行代号',
		BankInfoTxt: '银行资讯',
		BankTxt: '银行',
		Basic: 'Basic',
		Birthday: '生日',
		BloodOx: '血氧',
		BodyTemper: '体温',
		BranchTxt: '分行',
		Breadcrumb: 'Breadcrumb',
		BuildFailed: '建立失败',
		BuildSuccessful: '建立成功',
		BusyStatus: '看诊中',
		Button: 'Button',
		ButtonGroup: 'Button Group',
		Calendar: 'Calendar',
		CamiansaintTitle:'罗东圣母医院',
		Call: '通话',
		CallBack: '中断重播',
		CallBackRwd: '重播',
		CallTime: '拨打日期',
		Cancel: '取消',
		CancelDayOff: '取消请假',
		CancelRegister: '已取消挂号',
		CancelRegisteredNotice: '取消挂号通知',
		Cancelregistrationnotice: '取消报到通知',
		CancelSystemRegister: '逾时自动取消挂号',
		CannotDraw: '相关资料不足,无法制图',
		Card: '卡片',
		CardActions: 'Card Actions',
		CardColors: 'Card Colors',
		CardID: '健保卡号',
		CareTxt: '照服员',
		Carousel: 'Carousel',
		Cart: 'Cart',
		Case: '案件',
		CaseDone: '个案完成',
		CaseTitle: '案件标题',
		CaseTxt: '个案管理师',
		ChangeMobile: '变更手机号码',
		ChangeMobileNote: '输入新手机号码，取得验证码，进行验证。',
		ChangeMobileNote2: '变更密码,会重新登出, 再使用新密码登入。',
		ChangeMobilePW: '变更手机密码',
		ChangePW: '变更密码',
		ChangeTime: '变更时间',
		Chart: '图表',
		chartist: 'chartist',
		chartjs: 'chartjs',
		Charts: 'Charts',
		ChartsAndMaps: 'Charts & Maps',
		Chat: '聊天室',
		Checkbox: 'Checkbox',
		CheckDate: '检查日期',
		CheckDoctorField: '请选择医师资料',
		CheckField: '请检查相关栏位!',
		CheckIn: '报到',
		Checkout: 'Checkout',
		CheckPatientField: '请选择民众资料',
		ChengHsinTitle: '振兴医院',
		ChineseMD: '中医',
		Chip: 'Chip',
		choiceCompanyusually: '请选择常用字句',
		choiceDoctorMember: '请选择医师会员',
		choiceFilter: '请选择筛选条件',
		choiceGroup: '请选择组别',
		choicePatientDate: '请选择日期',
		choicePatientDatetime: '请选择日期与时间',
		choicePatientMember: '请选择民众会员',
		choicePatientMember_1: '请输入民众姓名或身分证字号',
		choicePatientMember_2: '筛选符合民众',
		choicePatientTime: '请选择时间',
		chooseRegidentifyType: '选择注册身分',
		chooseRegType: '选择注册方式',
		chosen_forgin_or_not: '*选择本国与国外银行',
		ChosenContact: '存在联络人',
		CLCTitle: 'CLC CARE',
		clinicalTxt: '临床心理师',
		Clipboard: 'Clipboard',
		CNs: '人民币',
		Collapse: 'Collapse',
		Colors: 'Colors',
		ComingSoon: 'Coming Soon',
		Completed: '完成人数',
		Components: 'Components',
		Confirm: '确定',
		ConfirmBtn: '确认',
		ConfirmConsultationDone: '确定此笔挂号完成看诊',
		ConfirmPassword: '再次输入密码',
		ConfirmReDial: '确定重播',
		confirmText_1: '请确定以下资讯:',
		confirmText_2: '预约医师:',
		confirmText_3: '民众大名:',
		confirmText_4: '付费方案:',
		confirmText_5: ' /元',
		confirmText_6: ' 分钟',
		confirmText_7: '预约时间:',
		confirmText_8: '日, 时段:',
		confirmText_8_2: '时段:',
		confirmText_9: '注意：请提醒民众在30分钟内,付完款项。',
		ConnectFirst: '请先连线',
		ConnectionFailed: '连线异常',
		Consultation: '会诊管理',
		Consultationnotice: '会诊通知',
		ConsultBtn: '文档会诊',
		ConsultDescription: '邀请会诊说明',
		ConsultDoctor: '挂号医师',
		ConsultPlan: '选择方案',
		ConsultPlan2: '选择咨询分钟数',
		ConsultProblem: '咨询问题',
		ConsultTitle: '邀请文档会诊医师',
		Contact: '联络人',
		ContactTxt: '联络资讯',
		ContainAndFinish: '保留看诊',
		ContainAndFinishRwd: '保留',
		Content: '内容',
		Content2: '事项内容',
		ContentText: '内容说明',
		ContextMenu: 'Context Menu',
		Country: '国别',
		CreateDate: '建立日期',
		CRM: 'CRM 系统',
		crmNote: '客服系统',
		curtitle: '医生马上看',
		customerSuggest: '客服纪录',
		CusTxt: '客服',
		Dashboard: '仪表板',
		DataCanNotEmpty: '栏位不能空的!',
		DataContainOK: '成功中断保留',
		DataErrCannotDraw: '载入资料错误,无法制图',
		DataList: 'Data List',
		DataNotEnough: '资料不足',
		DataSaved: '资料已成功储存',
		DataSaveFailed: '资料更新失败',
		DataSaveOK: '资料已成功储存',
		Datepicker: 'Datepicker',
		DatetimePicker: 'Datetime Picker',
		Del: '删除',
		DeleteDataFailed: '资料删除失败',
		DeleteDataSuccessful: '资料已成功删除',
		DeliverVerificationFailed: '验证码发送失败',
		DeliverVerificationSuccessful: '验证码已发送至手机',
		demoText: 'Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.',
		demoTitle: 'Card Title',
		Description: '说明',
		Detail: '检视内容',
		DetailBtn: '详细资讯',
		DetailBtn: '详细资讯',
		Dialogs: 'Dialogs',
		Diastolic_Pre: '血压(舒张压)',
		dicomdescription: '代表糖尿病眼底病变的 5 个严重度分级。 (数值范围 0~100)',
		dicomdescription1: '代表该民众需要被转介 就医的可能性',
		dicomdescription2: '心电图 Q 波至第一心音 ( S1 ) 的时间长度，可表示心脏射出血液的能力，正常值应低于 15%。 EMAT ≧ 15% 时，表示可能有疑似心脏收缩功能不佳、心脏衰竭的风险。',
		dicomdescription3: '电活期',
		dicomdescription4: '心脏收缩功能的综合指标，范围在 0~10 个单位之间，正常值应低于5。 SDI ≧ 5 时，表示可能有疑似心脏收缩功能缺损的风险。 SDI ≧ 7.5 时，表示可能有疑似严重心脏收缩功能缺损的风险。',
		dicomdescription5: '缩缩不全指数',
		dicomdescription6: '心脏在舒张时产生的心音，范围在 0~10 个单位之间，正常值应低于5。 S3 ≧ 5时，表示可能疑似有心脏舒张功能受损、心脏衰竭的风险。',
		dicomdescription7: '第三心音',
		dicomdescription8: '心脏在舒张时产生的心音，范围在 0~10 个单位之间，正常值应低于5。 S4 ≧ 5时，表示可能有疑似主动脉硬化、缺血或左心室肥大的风险。',
		dicomdescription9: '第四心音',
		DietitianTxt: '营养师',
		DisabledMenu: 'Disabled Menu',
		Divider: 'Divider',
		doctorAccess: '授权班表名单',
		doctorBank: '存折封面(汇入咨询费)',
		doctorbankaccount: '银行帐号',
		doctorbankaccountname: '银行户名',
		doctorbankaddress: '银行地址',
		doctorbankbranch: '银行分行',
		doctorbankinfo: '银行资讯',
		doctorbankname: '银行名称',
		doctorbankswiftcode: '银行代码',
		doctorChinaDayFee: '大陆白天咨询费率',
		doctorChinaNightFee: '大陆夜间和急诊咨询费率',
		doctorDayFee: '台湾白天咨询费率',
		doctorDICOM: 'DICOM',
		doctorEducation: '学历',
		doctorEE: '学经历',
		doctorExperience: '经历',
		doctorExpertise: '专长',
		DoctorInfo: '合作医护人员',
		DoctorInfoChosen: '选取医护人员',
		DoctorInfoNotChosen: '未选取医护人员',
		DoctorInfosubtitle: '搜寻并勾选合作的医护人员',
		doctorLang: '精通语言',
		doctorLicense: '执业执照(影本)',
		doctorLife: '生命与健康数值',
		doctorLifeValue: '主要生命数值',
		doctorNightFee: '台湾夜间和急诊咨询费率',
		DoctorNoService: '该医师未开班，请重新搜寻',
		doctorNote: '备注',
		DoctorOffline: '医师目前未上线',
		doctorPhoto: '个人照片',
		doctorReason: '问诊原因',
		doctorRecord: '医疗影像',
		doctorReport: '生化检验',
		doctorservice: '服务内容',
		doctorServiceType: '服务方式',
		doctorsoap: '医事人员S.O.A.P. 类型',
		doctorstatus: '审核状态',
		DoctorStatusText: '合作医师',
		doctorSuggest: '建议纪录',
		doctortype: '医事类别',
		doctorUnit: '服务单位',
		doctorValue2: '新陈代谢数值',
		doctorValue3: '体型数值',
		doctorValue4: '身心指数',
		DocTxt: '医师',
		Documentation: 'Documentation',
		dollar: '元',
		downloadreport: '下载挂号名单',
		downloadtemplate: '下载模板',
		downloadtemplateexample: '下载模板范例',
		DragAndDrop: 'Drag & Drop',
		dragfile: '将档案拖曳此处或<em>点击</em>上传',
		DropDown: 'DropDown',
		Ear: '耳道',
		EarlyMorning: '凌晨',
		echarts: 'echarts',
		Edit: '编辑',
		Edit: '编辑',
		EditPw: '修改密码',
		EditSchedule: '编辑我的预约',
		edu1: '学历1',
		edu2: '学历2',
		edu3: '学历3',
		Email: '信箱',
		EmergencyCI: '紧急联络人资讯',
		EmergencyContact: '紧急联络人',
		EmergencyContactNum: '紧急联络人电话',
		EmergencyRelation: '关系',
		emergencyTime: '急诊纪录',
		emergencyTimeRWD: '急诊',
		endDate: '结束日期',
		EndTime: '结束时间',
		EnterCorrectPhoneNumber: '请输入正确的手机号码',
		EnterCorrectValue: '请输入正确数值',
		EnterEndTime: '请输入结束时间',
		EnterGroupName: '请输入群组名称',
		EnterMobile: '请输入新手机号码',
		enterPaid: '请输入金额',
		EnterPasswordAtLeastSix: '请输入至少6位数密码',
		EnterRemarks: '请输入备注内容',
		EnterSamePassword: '请输入相同密码',
		EnterSuggestedContent: '请输入建议内容',
		enterText: '请输入相关字句',
		EnterTitle: '请输入标题',
		Enterverify: '请输入验证码',
		Error: '错误',
		ExamTxt: '医检师',
		exceldownload: 'Excel下载',
		excelExport: 'Excel汇出',
		exp1: '经历1',
		exp2: '经历2',
		exp3: '经历3',
		Export: 'Export',
		ExportSelected: 'Export Selected',
		Extensions: 'Extensions',
		Eye: '眼睛',
		Failed: '失败',
		famCacel: '家族癌症病史',
		famCacel2: '过敏事项',
		famCacel3: '病史及敏感事项',
		famleSick: '家族慢性病史',
		FAQ: 'FAQ',
		filter: '过滤',
		filter2: '筛选',
		Finish: '完成',
		Finished: '已完诊',
		FinishFirst: '请先完成此次看诊',
		FinishTxt: '完诊',
		FixedSchedule: '固定班表',
		ForgetPw: '忘记密码',
		ForgotPassword: 'Forgot Password',
		FormElements: 'Form Elements',
		FormInputGroup: 'Form Input Group',
		FormLayouts: 'Form Layouts',
		FormsAndTable: 'Forms & Table',
		FormValidation: 'Form Validation',
		FormWizard: 'Form Wizard',
		FreqMenu: '常用选单',
		FriText: '周五',
		FullCalendar: 'Full Calendar',
		FuncTxt: '职能治疗师',
		FunduscopyAI2Txt: '绿色：每年做一次眼底镜检验 || 黄色：找眼科医师检查及咨询 || 橘色：尽快找眼科医师看诊 || 红色：立即找眼科医师看诊',
		FunduscopyAIReportTxt: '眼底镜AI报告',
		GlouseAC: '血糖(饭前)',
		GlousePC: '血糖(饭后)',
		GoHospital: '到院挂号',
		GoogleMap: 'Google Map',
		Grid: 'Grid',
		Group: '群组',
		GroupName: '群组名称',
		healthdataerror: '健康数据错误',
		HealthlinkCall: '远盟通话',
		HealTxt: '健康管理师',
		Height: '身高(CM)',
		HSecTxt: '健康秘书',
		I18n: 'I18n',
		IAgreenMentText: '我同意',
		ID: '身分证字号',
		Import: '汇入',
		importmultiple: '多笔汇入',
		Info: '基本资料',
		Input: 'Input',
		InternalMD: '内科',
		Interval: '时段',
		Invoice: 'Invoice',
		KeelungChangGungMemorialHospital: '基隆长庚医院',
		KnowledgeBase: 'Knowledge Base',
		LastDate: '最后更新日期',
		LateAtNight: '深夜',
		Later: '稍后',
		Left: '左',
		LeftEyeLabel: '左眼',
		LINEmsg: 'LINE 讯息',
		List: 'List',
		ListView: 'List View',
		Loading: 'Loading',
		LockScreen: 'Lock Screen',
		LogIn: '登入',
		Login: '登入',
		LoginFailed: '登入失败',
		LoginFailed2: '登入金钥有状况,请与系统管理员联系!',
		LoginType: '选择登入方式',
		Logout: '登出',
		lowerThanDefaultPrice: '金额少于该医师预设费用',
		MainlandChinaService: '提供大陆地区咨询',
		Maintenance: 'Maintenance',
		Man: '男',
		maxAppointment: '每诊最大预约人数',
		medicalRule:'档案命名规则',
		medicalRecord: '病历',
		medicalReport: '一般患部照片',
		medicalReport0: '患部照片',
		medicalReport10: '咽喉',
		medicalReport11: '口腔',
		medicalReport12: '皮肤',
		medicalReport13: '心电心音',
		medicalReport14: '一般影片',
		medicalReport2: '健检报告(PDF)',
		medicalReport3: 'DICOM',
		medicalReport4: '眼底镜图片',
		medicalReport4_1: '眼底镜',
		medicalReport5: '超音波照片',
		medicalReport6: '心电图',
		medicalReport6_1: '心电图报告',
		medicalReport7: '五官镜',
		medicalReport8: '耳道',
		medicalReport9: '鼻腔',
		MedicalStaff: '医事人员',
		Member: '会员',
		MemberNotFind: '查无相关会员资讯',
		Members: '会员管理',
		MenuLevel2p1: 'Menu Level 2.1',
		MenuLevel2p2: 'Menu Level 2.2',
		MenuLevel3p1: 'Menu Level 3.1',
		MenuLevel3p2: 'Menu Level 3.2',
		MenuLevels: 'Menu Levels',
		Message: '讯息管理',
		Message2: '讯息',
		Minutes: '分',
		Miscellaneous: 'Miscellaneous',
		Mobile: '手机号码',
		mobilecode: '未验证',
		MobileVeri: '手机验证',
		MobileVerifyCode: '手机验证码',
		Monitor: '远距监看',
		MonText: '周一',
		month: '本月',
		More: '更多',
		MorningAndNight: '早夜',
		Mouth: '口腔',
		MR: '病历',
		Msg: '简讯',
		msg_is_recycle: '点击收回此则讯息',
		msg_is_seen: '已读',
		MsgDeliveredSuccessful: '传送讯息成功',
		myid: '帐号',
		mypass: '密码',
		myshift: '个人班表',
		Navbar: 'Navbar',
		NetworkConnectionFailed: '网路连线错误',
		NewPW: '请输入新密码!',
		NewPWAgain: '再输入一次新密码',
		Newpwlimit6: '新密码, 至少6位数',
		NewVersionReload: '后台主机系统已更新，请立即重新整理视窗已确保资料正确性',
		nextstep: '下一步',
		night: '晚上',
		nightMobile: '晚',
		noappointment: '目前无挂号资讯',
		NoConnection: '未连线',
		nodata: '目前查无资料',
		Nofile: '目前无相关档案',
		nogroup: '目前无群组',
		NoInfo: '目前无相关资讯',
		None: '无个人病史',
		NoNote: '目前无备注资料',
		nonotify: '无通知纪录',
		Nose: '鼻腔',
		NoSetReferralHospital: '目前无设定转诊单位',
		NotAuthorized: 'Not Authorized',
		notfounddoctorsoap: '找不到医事人员S.O.A.P. 类型',
		Notificationofcompletion: '完成报到通知',
		Notifications: 'Notifications',
		Notify: '通知',
		notifytitle: '通知',
		NotStated: '未说明',
		NoUploadData: '目前无上传资料',
		NoUser: '找不到使用者',
		NoVideo: '目前无视讯连线',
		Now: '目前',
		NTs: '新台币',
		NumberInput: 'Number Input',
		Numberofpeople: '报到人数',
		NurTxt: '护理师',
		OfflineStatus: '离开',
		OKBtn: '确认',
		Online: '视讯咨询',
		OnlineStatus: '待诊',
		onlyexcelfile: '只接受excel格式文件',
		Onsite: '实地义诊',
		OpenBtn: '开启',
		Opinion: '会诊纪录',
		Opinion2: '被邀请会诊项目',
		Opinion2rwd: '被邀请',
		Opinion3: '邀请会诊项目',
		Opinion3rwd: '邀请',
		Options: '民众选择方案',
		OtherDesc: '其他说明',
		OtherInfo: '其他资讯',
		OtherLogin: '已在其他装置登入',
		Others: 'Others',
		Othersubjects: '其他科别',
		OtherTime: '其他时段',
		OtherTimeRWD: '其他',
		OtherTxt: '其他',
		OutPatient: '到院看诊',
		Pages: 'Pages',
		Pagination: 'Pagination',
		Password: '密码',
		Patient: '看诊人员',
		Patient2: '民众',
		Patient3: '民众',
		PatientNotFind: '找不到符合的患者资讯',
		PatientOffline: '民众目前未上线',
		patientReq: '民众需求',
		PayDoneNotice: '完成付款通知',
		pdfdownload: 'PDF下载',
		People: '人',
		PeopleNum: ' 人数',
		personalSick: '个人病史',
		PhaTxt: '药师',
		photoSync: '医疗影像同步',
		healthReportSync: '健检报告同步',
		photoGraphSync: '患部照片同步',
		PhyTxt: '物理治疗师',
		PingChrisTitle:'屏东基督教医院',
		pleaseClick: '请按',
		PleaseEnterCorrectData: '请输入正确资料',
		PleaseSelect: '请选择',
		plsentername: '请输入帐号与密码',
		pm: '下午',
		pmMobile: '下',
		PName: '姓',
		PName2: '名',
		PName3: '姓名',
		Popup: 'Popup',
		Preview: '预览',
		preview_data: '预览资料',
		prevstep: '上一步',
		Privacy: '注册合约',
		Log: '操作纪录',
		Process: '进行中',
		Processing2: '处理中',
		Profile: '个人资讯',
		Progress: 'Progress',
		PsyTxt: '咨商心理师',
		Pulse: '心跳',
		quarterDefinition: '＊本季说明 :ㄧ季定义为三个月分别 1-3 ｜ 4-6 ｜ 7-9 ｜ 10-12',
		questionAndAnswer: '就诊问卷',
		QuillEditor: 'Quill Editor',
		Radio: 'Radio',
		RadioTxt: '医事放射师',
		RaiseSupport: 'Raise Support',
		ReasonForConsultation: '需要',
		ReCall: '回拨',
		RecordFile: '录影档案',
		RecordTime: '挂号纪录',
		RecordTimeRWD: '纪录',
		Register: '注册',
		RegisteredNotice: '挂号通知',
		registeredRecord: '挂号纪录',
		RegisterMember: '注册会员',
		RegisterNote: '请在此设定您的帐号与密码',
		RegistrationAgain: '请重新挂号',
		RegTime: '挂号时间',
		Reload: '重新整理',
		ReloadPage: '确认是否关闭重新整理视窗?',
		RemberMeText: '记住帐号',
		remindBeforeReservation: '将于选定时间前三十分钟提醒双方',
		removeServiceUnitComfirm: '此操作将移除目前所属服务单位',
		removeServiceUnitSuccessful: '服务单位移除成功!',
		RepeatLogin: '此帐号已在其他装置登入,请重新登入',
		ReplayMsg: '回覆预约通知',
		reportValue: '理学与代谢症候群',
		reportValue2: '血脂肪与血糖',
		reportValue3: 'CBC/DC 血液常规',
		reportValue4: '肝胆功能与病毒',
		reportValue5: '细菌与免疫学',
		reportValue6: '肾功能与电解质',
		reportValue7: '尿液生化',
		reportValue8: '尿液常规',
		ReselectUser: '请重新选择使用者',
		Reservation: '挂号管理',
		reservationInterface: '特约挂号',
		ResetPassword: 'Reset Password',
		RevDoctor: '挂号医师',
		RevReason: '问诊原因',
		Right: '右',
		RightEyeLabel: '右眼',
		SatText: '周六',
		Save: '储存',
		SaveAndFinish: '储存并完诊',
		SaveAndFinishRwd: '完诊',
		Schedule: '我的预约',
		scheduledOff: '排休',
		SciNo: '科研编号',
		Search: '搜寻',
		searchAccount: '身分证/电话(国码及手机)/居留证',
		searchBtn: '按钮查询',
		SearchContact: '搜寻联络人',
		searchMRStaff: '搜寻医事人员',
		searchName: '请输入姓名',
		searchNameAndAccount: '搜寻姓名或帐号',
		searchNameAndPhone: '搜寻姓名或电话',
		searchNameAndPhoneAndAccount: '搜寻姓名、电话或帐号',
		searchPatientName: '搜寻民众姓名',
		SearchTotal: '共搜寻',
		SearchUpload: '搜寻',
		season: '本季',
		Seconds: '秒',
		Select: 'Select',
		selecteEterprise: '请选择企业',
		selecteGroup: '请选择组别',
		SelectFile: '选择档案',
		selectFileNoticeMessage: '档案大小不超过1000MB',
		selectFilenumberlimit: '档案数量不超过{num}个',
		selectFileRule: '只接受{data}结尾的档案',
		SelectGroupMember: '请选择群组会员',
		SelectMember: '选择民众会员姓名',
		SelectPlan: '请选择方案',
		SelectRecipient: '请先选择传送对象',
		SelectUser: '请选择使用者',
		Send: '传送',
		SendMsg: '发送讯息',
		SendMsgFailed: '简讯传送失败',
		SendMsgFailed: '传送简讯失败',
		SendMsgSuccess: '简讯传送成功',
		SendNotify: '讯息通知',
		SendTo: '传送对象',
		SendVerification: '寄发验证码',
		SendWeChatSuccessText: '传送微信简讯成功',
		Service: '服务列表',
		Servicesubtitle: '为在民众挂号时询问其所需的服务',
		SetOffDay: '请假成功',
		Setting: '系统设定',
		settingSetword: '常用字句设定',
		SetupTime: '建立时间',
		SetWord: '常用字句',
		SetWordsubtitle: '常用字句分为：主观项目(S)、客观项目(O)、医事人员建议(memop)以及文字交谈(wd)，可建立常用的罐头语句供点选使用，以节省打字时间。',
		Shift: '班表设定',
		ShiftCalendar: '班表',
		shiftChangeNotice: '班表变更通知',
		ShiftsNote: '× 只能排休今天以后的日期(不含今天)',
		Shop: 'Shop',
		Sidebar: 'Sidebar',
		SimpleCalendar: 'Simple Calendar',
		Skin: '皮肤',
		Slider: 'Slider',
		SmsAppointText: '您将发送一个语音/视讯网址给会员，若三分钟内对方未开启本连结，本连结将会失效。',
		SmsAppointText2: '会员错过通话服务，挂号已取消。',
		soap_Memop: '医事人员建议',
		soap_O: '客观项目',
		soap_S: '主观项目',
		soap_WD: '文字交谈',
		Someonecanceledtheregistration: '有人取消报到',
		SomeoneRegistered: '有人挂号',
		SomeoneReports: '有人完成报到',
		Someonerequestsaconsultation: '有人请求会诊',
		Someonesendsamessage: '有人传送讯息',
		SomeoneSendsAtodo: '有人传送待办事项',
		SomeoneShiftBeUpdate: '某人班表已更新',
		SpecialTime: '特约门诊',
		SpecialTimeRWD: '特诊',
		StarRatings: 'Star Ratings',
		startDate: '开始日期',
		StartTime: '开始时间',
		Statistics: 'Statistics',
		Status: '状态',
		Submit: '送出',
		SuccessTxt: '成功',
		SunText: '周日',
		Support: 'Support',
		Surgical: '外科',
		SwiftCodeTxt: 'Swift Code',
		Switch: 'Switch',
		switch_left_right: '上方标签点击左右滑动',
		Systolic_Pre: '血压(收缩压)',
		Table: '列表',
		Tabs: 'Tabs',
		Tailwind: 'Tailwind',
		Textarea: 'Textarea',
		AppointmentStatus: '预约状况',
		ThisWeekSeeDoctor: '本周完成看诊数',
		ThisWeekStatus: '本周预约状况',
		Throat: '咽喉',
		ThumbView: 'Thumb View',
		ThuText: '周四',
		Time2: '拨打时间',
		time3: '次',
		TimeLabel: '时间',
		TimeLeft: '剩余',
		TimeOut: '时段已过',
		TimeToSend: '预计发送时间',
		Tip: '提示',
		TipsPictureFileSize: '图片大小不能超过 20MB',
		TipsUploadFileFormat: '上传文件只能是图片格式',
		Title2: '社区科研中心',
		TmpNoData: '暂无数据',
		Toask: '交办事项',
		toask: '交办事项',
		today: '今日',
		Todo: 'Todo',
		Todolist: '待办事项',
		Todonotice: '待办通知',
		Tooltip: 'Tooltip',
		TotalAppoint: '预约人员',
		TotalMembers: '会员总数',
		TotalNum: '总数',
		TotalRecord: '笔',
		Transfer: '转接医师',
		TransferTxt: '转介',
		Tree: 'Tree',
		TueText: '周二',
		Type: '类别',
		UI: 'UI',
		UnChosenContact: '新增联络人',
		UnfinishedText: '未完成挂号',
		UnFinishTxt: '未完诊',
		UnknownPeriod: '不明时段',
		UnSay: '未说明',
		Update: '更新',
		UpdateFailed: '更新失败',
		UpdateSuccessful: '更新成功',
		Upload: '医疗档案上传',
		UploadBtn: '上传',
		uploadDICOM: 'DICOM',
		uploadEar: '耳朵',
		uploadECG: '心电图',
		uploadECG2: '心电图',
		uploadFace: '五官镜',
		uploadfile: '上传文件',
		UploadFinished: '上传完成',
		uploadFunduscope: '眼底镜图片',
		Uploading: '上传中',
		uploadNasal: '鼻腔',
		uploadOral: '口腔',
		uploadPhoto: '患部照片',
		uploadReport: '检验报告(PDF)',
		uploadSearchTitle: '输入注册会员之帐号',
		uploadSkin: '皮肤',
		uploadSingle:'医疗档案上传(单一会员)',
		uploadMultiple:'医疗档案上传(多会员)',
		uploadHealthdata:'健康数据上传(多会员)',
		uploadSuperSound: '超音波照片',
		uploadThroat: '咽喉',
		UploadTimeLabel: '上传时间',
		uploadVideo: '一般影片',
		UseMic: '无法进行通讯，请允许使用麦克风与摄影机?',
		User_Guide: '使用教学',
		VerificationCodeRequired: '手机验证码必填',
		Video: '视讯',
		videobtn: '使用影像',
		videoplay: '影片播放',
		view_details: '查看详细资料',
		vipMember: 'VIP会员',
		Voice: '语音',
		voicebtn: '使用声音',
		VolTxt: '志工师',
		VolunteerOnline: '为弱势民众视讯义诊',
		Vuesax: 'Vuesax',
		Vuexy: 'Vuexy',
		WarningText1: '本人身为医护人员充分了解医师法第11条及政府主管机关对远距诊察的相关法令和规范，本人并同意遵守之。本人了解除了主管机关核准从事远距医疗的特定地区，民众及情况外，远距视讯咨询可做为会员到医疗院所就医前和就医后的辅助咨询工具，提供会员更即时和方便的保健咨询服务，以提升医病关系并恊助建立家庭医师制度。 ',
		WarningText1Title: '警语:',
		WarningText2: '本人了解并同意贵公司谨提供通讯平台供本人和客户做咨询服务，不负责处理任何因此咨询服务所产生本人与客户的纠纷，亦不必分摊或给付任何纠纷的赔偿。 ',
		WarningText2Title: '平台免责权:',
		WarningText3: '本人了解并同意贵公司系统在咨询过程执行全程的录音或录影，做为收取咨询费用之依据参考。 ',
		WarningText3Title: '咨询过程录音录影:',
		WarningText4: '本公司将会代医护人员依其自订之​​费率及服务秒数向会员收取咨询费用，收款须先扣除3%的金流费用后，本公司将收取余额的30%做为的平台服务费并开给发票，另70%转帐给医护人员。若会员不付款，本公司仍会保证支付给医护人员以确保您们的权益，并将会在次月的10号结算前一个月份的咨询费用，以银行转帐的方式汇款到医护人员注册时所登记的银行帐户，但银行将会由其中收取15至30元不等的汇款费用。 ',
		WarningText4Title: '咨询费及平台服务费:',
		WarningText5: '医护人员了解并同意会员一旦与医护人员预约做健康咨询，即代表该会员授权该医护人员及其所属医疗院所相关医护人员查看其健康咨询相关的个资。并授权该医护人员视必要性将其健康咨询相关的个资转授权给指定之转诊及会诊的医护人员及其所属医疗院所。但医护人员不得将会员相关的个资复制，传送或是销售给未经会员授权或非必要转诊及会诊的其他第三者。 ',
		WarningText5Title: '客户个资之使用:',
		WarningText6: '本公司为维护服务之品质, 对于不具有医护人员执照和资格的会员，以及不当或恶意使用本服务之医护人员，保有单方面取消该会员资格之决定权利。 ',
		WarningText6Title: '取消医护人员会员权利:',
		WarningText7: '关于本公司对于您个人资料保护，请详细阅读本服务的隐私权声明',
		WarningText71: '医生马上看为健康联网资讯服务股份有限公司所经营，为了维护个人隐私权与支持个人资料保护，本公司谨以下列声明向您说明所收集个人资料之目的，类别，利用范围及方式，以及您所得行使之权利等事项；如果对于本服务的隐私权声明，以下相关告知事项，或是与个人资料保护有关之相关事项有任何疑问，可以和本公司客服中心连络，本公司将尽快回覆说明。 ',
		WarningText72: '适用范围：本服务隐私权声明及其所包含的告知事项，仅适用于服务所拥有及经营的网站。本服务网站可能包括许多连结、或是其他合作伙伴及商家，其有关隐私权声明及个资保护有关之告知事项，请参阅各该连结网站、合作伙伴或是商家之网站。 ',
		WarningText73: '个人资料搜集之目的与类别：本公司为了提供电子商务服务、售后服务、履行法定或合约义务、保护当事人及相关利害关系人之权益、行销、客户管理与服务、以及和与营​​业登记项目或组织章程锁定之业务等目的，依照各服务之性质，有可能会收集您的姓名、联络方式（包含但不限于电话、email及地址等）、未完成收款或付款所需之资料、IP地址、所在地点、上传的病历、医疗图像、健康检查、影音以及其他得以直接或间接辨识使用者身分之个人资料。此外，为提升服务品质，本公司会依照所提供服务之性质，记录使用者的IP地址、及在本服务相关网站或App内的浏览活动（例如，使用者说使用的软硬体、所点选的网页、下载的APP）等资料，但是这些资料经工作流量分析和网路行为调查，以便于改善本服务的相关网站的服务品质，不会和特定个人相联系。 ',
		WarningText74: '个人资料的利用：服务所搜集的足以识别使用者身份的个人资料，包含但不限于商业登记资料、法定代理人姓名、地址、身分证字号；若为自然人时，则为个人姓名、地址、身分证字号等相关资料，仅提供本公司于内部依照搜集之目的进行处理和利用，除非事先说明、或为完成提供服务或履行合约义务之必要、或依照相关法令规定或有权主管机关之命令或要求，否则本公司不会将涉及使用者身分的个人资料提供给第三人，包括境内及境外，或作收集目的之外之使用。在法令所定应保存之期限内，本公司会持续保管、处理及利用相关资料。 ',
		WarningText75: '资料安全：本公司将以与产业标准之合理技术和程序，维护个人资料之安全。 ',
		WarningText76: '资料当事人之权利：资料当事人可以请求查询、阅覧本人的个资或要求给予复制本。若您的个资有变更、或发现您的个资不正确，可以向本公司要求修改或更正。当收集个人资料之目的消失或期限届满时，应可要求删除、停止处理或利用个人资料。但因本公司执行业务所得必须者，不在此限。 ',
		WarningText77: 'Cookie : 为了便利使用者，本公司网站可能会读取储存在使用者电脑中的cookie资料。使用者可经由浏览器的设定、取消、或限制此项功能，但可能因此无法使用部分网站功能。若你想知道如何取消、或限制此项功能，请与本公司客服中心连络。 ',
		WarningText78: '影响：如果您不愿提供各项服务或交易所要求的相关个人资料予本公司，并且同意本公司就该等个人资料依法令规定、以及隐私权声明、及其相关告知内容为相关之个人资料搜集、处理、利用及国际传输，本公司将尊重您的决定，但依照各该服务之性质或条件，您可能因此无法使用该等服务或完成相关交易，本公司并保留是否同意提供该等相关服务或完成相关交易之权利。 ',
		WarningText79: '修订之权利：本公司有权随时修订本隐私权声明及相关告知事项，于变更与修订后公布在本公司之网站及APP内，不另行个别通知，您可以随时在公司网站及APP内详阅修订后的隐私权声明及相关告知事项。',
		WarningText7Title: '个资安全及隐私权声明:',
		WeChatCall: '微信通话',
		WedText: '周三',
		week: '本周',
		Week: '本周',
		WishList: 'Wish List',
		Woman: '女',
		word: '字数 300 字以内',
		WrongData: '资料错误',
		WrongPassword: '密码错误',
		WrongPasswordOverFour: '密码输入错误超过4次，请等待15分钟，在尝试登入。',
		year: '本年',
		YouCopyTitle: '拷贝档案路径',
		YouCopyTxt: '你已拷贝档案路径',
		medicalStaffNoGroup: '此医事人员无所属照护组别',
		localeBank: '本国银行',
		foreignBank: '国外银行',
		AllPatientList: '所有患者名单列表',
		PE_Report: '健检报告',
		resetPasswordOrNot: '是否前往重新设定新密码',
		closeWindow: '关闭视窗',
		sendInvitationSuccess: '已传送相关资料给会诊医师',
		invalidVerifyCodeTimeLimit: '手机验证码有效期限为5分钟',
		unbindLineWarningText1: '是否解除绑定『LINE通知』功能',
		unbindLineWarningText2: '此操作将解除二次验证功能，您将无法透过LINE收到任何通知',
		bindSuccess: '绑定成功',
		bindNotYet: '尚未绑定',
		bindLINE: '绑定Line',
		unbindLINE: '解除绑定',
		recieveNewMyTodoList: '收到新的待办事项',
		todoListDone: '有完成的交办事项',
		loading: '加载中',
		noMore: '没有更多',
		changeShiftWarningText1: '此次班表更动将会影响',
		changeShiftWarningText2: '诊挂号，您将需要自行通知已挂号民众做取消挂号的动作，是否仍要更动班表？',
		doctorLeave: '医师请假',
		plan: '方案',
		divisions: '科别',
		contactAgent: '联系代理人',
		sex: '性别',
		age: '年龄',
		noThisPatientAuthorization: '尚未取得该民众的授权',
		openNewWindow: '开新分页',
		dontOpenNewWindow: '不开新分页',
		doctorCallBackObject: '医师回拨对象',
		PingChrisTitle: '屏东基督教医院',
		notFoundService: '找不到服务列表类型',
        serviceType: '服务列表类型',
        serviceSetting: '服务列表设定',
		patientNoAuth: '民众未授权',
		DelContactRemind:'你确定要删除此联络人',
		CamiansaintTitle: '罗东圣母医院',
		choosePartnerGroup: '请选择医事机构',
		partnerWhichMemberBelong: '此会员所隶属的医院或机构',
		joinEnterprise: '加入单位',
		advancedMember: '高级会员',
		medicationHistory: '药历查询',
		checkImage: '检查影像',
		radiography: '放射影像',
		patientList: '患者名单',
		changePartner: '切换机构',
		todayAppointment: '今日待诊',
		chatRoomMsg: '聊天室讯息',
		todayGeneralIdea: '本日概况',
		weekGeneralIdea: '本周概况',
		monthGeneralIdea: '本月概况',
		todayAppointmentUint: '诊',
		chatRoomMsgUint: '则',
		toDoUint: '项',
		Ask:'交办',
		apply: '套用',
		noSetWord: '没有常用字句可使用',
		createNow: '马上建立',
		pastRecords: '过去建议纪录',
		Authorize:'新增授权',
		ManageShift:'管理班表',
		ContactPatient:'与挂号民众通话',
		ITRI_AI: '工研院_AI',
		fertilityReport: '生殖检验报告',
		Weight:'体重(KG)',
		language: {
			"zh_tw": '繁体中文',
			"en": '英文',
			"zh_cn": '简体中文'
		},
		mobilecode: {
			"0": '未验证',
			"1": '已验证'
		},
		divisions1: [
			'家庭医学科',
			'呼吸胸腔科',
			'肾脏内科',
			'肝脏内科',
			'心脏内科',
			'肠胃内科',
			'神经内科',
			'血液肿瘤科',
			'风湿免疫科',
			'内分泌科'
		],
		DocdorEnableText: {
			0: {
				0: '欢迎加入医生马上看，请完成个人资料的填写，并等待专员审核上线，谢谢您!',
				1: '欢迎加入医生马上看，请完成个人资料的填写，并等待专员审核上线，谢谢您!',
				32: '欢迎加入基隆长庚，请完成个人资料的填写，并等待专员审核上线，谢谢您!',
				1575: '欢迎加入智慧之爱公益联盟，请完成个人资料的填写，并等待专员审核上线，谢谢您!',
				1577: '欢迎加入振兴医疗，请完成个人资料的填写，并等待专员审核上线，谢谢您!',
				2085: '欢迎加入振兴医疗，请完成个人资料的填写，并等待专员审核上线，谢谢您!'
			},
			1: {
				0: '欢迎加入医生马上看，请完成手机号码验证，谢谢您!',
				1: '欢迎加入医生马上看，请完成手机号码验证，谢谢您!',
				32: '欢迎加入基隆长庚，请完成手机号码验证，谢谢您!',
				1575: '欢迎加入智慧之爱公益联盟，请完成手机号码验证，谢谢您!',
				1577: '欢迎加入振兴医疗，请完成手机号码验证，谢谢您!',
				2085: '欢迎加入振兴医疗，请完成手机号码验证，谢谢您!'
			}
		},
		errormessage: {
			recycleMsg: '已收回此笔资料',
			confirmrecycleMsg: '收回此则讯息将无法复原!是否确认收回此笔资料？'
		},
		el: {
			colorpicker: {
				confirm: '确定',
				clear: '清空'
			},
			datepicker: {
				now: '此刻',
				today: '今天',
				cancel: '取消',
				clear: '清空',
				confirm: '确定',
				selectDate: '选择日期',
				selectTime: '选择时间',
				selectDateTime:'选择日期时间',
				startDate: '开始日期',
				startTime: '开始时间',
				endDate: '结束日期',
				endTime: '结束时间',
				prevYear: '前一年',
				nextYear: '后一年',
				prevMonth: '上个月',
				nextMonth: '下个月',
				year: '年',
				month1: '1 月',
				month2: '2 月',
				month3: '3 月',
				month4: '4 月',
				month5: '5 月',
				month6: '6 月',
				month7: '7 月',
				month8: '8 月',
				month9: '9 月',
				month10: '10 月',
				month11: '11 月',
				month12: '12 月',
				week: '周次',
				weeks: {
					sun: '日',
					mon: '一',
					tue: '二',
					wed: '三',
					thu: '四',
					fri: '五',
					sat: '六'
				},
				months: {
					jan: '一月',
					feb: '二月',
					mar: '三月',
					apr: '四月',
					may: '五月',
					jun: '六月',
					jul: '七月',
					aug: '八月',
					sep: '九月',
					oct: '十月',
					nov: '十一月',
					dec: '十二月'
				}
			},
			select: {
				loading: '加载中',
				noMatch: '无匹配数据',
				noData: '无数据',
				placeholder: '请选择'
			},
			cascader: {
				noMatch: '无匹配数据',
				loading: '加载中',
				placeholder: '请选择',
				noData: '暂无数据'
			},
			pagination: {
				goto: '前往',
				pagesize: '条/页',
				total: '共 {total} 条',
				pageClassifier: '页'
			},
			messagebox: {
				title: '提示',
				confirm: '确定',
				cancel: '取消',
				error: '输入的数据不合法!'
			},
			upload: {
				deleteTip: '按 delete 键可删除',
				delete: '删除',
				preview: '查看图片',
				continue: '继续上传'
			},
			table: {
				emptyText: '暂无数据',
				confirmFilter: '筛选',
				resetFilter: '重置',
				clearFilter: '全部',
				sumText: '合计'
			},
			tree: {
			  	emptyText: '暂无数据'
			},
			transfer: {
				noMatch: '无匹配数据',
				noData: '无数据',
				titles: ['列表 1', '列表 2'],
				filterPlaceholder: '请输入搜索内容',
				noCheckedFormat: '共 {total} 项',
				hasCheckedFormat: '已选 {checked}/{total} 项'
			},
			image: {
			  	error: '加载失败'
			},
			pageHeader: {
			  	title: '返回'
			},
			popconfirm: {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}
		},
		message: {
			data_not_input: '资料未输入',
			upload_success: '上传成功',
			upload_error: '上传失败',
			upload_file_error: '上传失败!请检查档案大小或档案格式!',
			data_apdate_success: '资料更新成功',
			search_full_message: '请于搜寻列表输入完整的身分证/电话/居留证!',
			confirm_delete_record: '确认删除此笔资料',
			send_success: '传送成功',
			delete_success: '删除成功',
			send_error: '传送失败',
			delete_error: '删除失败',
			register_again: '重新挂号',
			register_success: '挂号成功',
			register_error: '挂号失败',
			register_finish: '完成看诊',
		},
		upload:{
			report_batch:{
				rule:{
					1: 'A12XXXX789_20220225.jpg，以登入帐号为开头，并使用底线"_"与原档名分开',
					2: 'A12XXXX789_20220225.pdf，以登入帐号为开头，并使用底线"_"与原档名分开，需为pdf档',
					3: 'A12XXXX789_20220225.DCM，以登入帐号为开头，并使用底线"_"与原档名分开，需为DCM档',
					4: 'A12XXXX789_L_20220225.jpg，以登入帐号为开头，左右眼为第二资讯(L:左眼 R:右眼)，并分别使用底线"_"与原档名分开',
					14: 'A12XXXX789_20220225.mov，以登入帐号为开头，并使用底线"_"与原档名分开，需为mov档',
				}
			},
			healthdata_batch: {
				title_note: '档案可以重新上传;若有相同人与相同量测日期的资料、会以新资料为准，不会重复上传',
				table_title_note: '部分资料未上传，提醒如下',
				title:{
					'sheet': '工作表名称',
					'row': '行数',
					'col': '列数',
					'warning': '未上传原因'
				},
				examplenote:'(原民会专用)'
			}
		},
	}
}