import Vue from 'vue'
import i18n from '@/i18n/i18n.js'
class common {
    // 取語系
    getLang(data) {
        const statusMap = {
            tw: data.tw,
            cn: data.cn,
            en: data.en,
        }
        return !statusMap[i18n.locale] ? data.default : statusMap[i18n.locale]
    }
    // 取語系
    getLangDB(data) {
        const statusMap = {
            tw: data.name,
            cn: data.name_cn,
            en: data.name_en,
        }
        return !statusMap[i18n.locale] ? data.name : statusMap[i18n.locale]
    }
    // 取性別
    getGender(val) {
        const statusMap = {
            1: {
                tw: '男',
                cn: '男',
                en: 'Male',
                default: '男',
            },
            2: {
                tw: '女',
                cn: '女',
                en: 'Female',
                default: '女',
            },
        }
        return !statusMap[val] ? '-' : this.getLang(statusMap[val])
    }
    // 取年齡
    getAge(val) {
        if (val === '0000-00-00' || !val) {
            return '--'
        } else {
            const ageMS = Date.parse(Date()) - Date.parse(val)
            const age = new Date()
            age.setTime(ageMS)
            const ageYear = age.getFullYear() - 1970
            return ageYear
        }
    }
    // 抓日期
    getCurrentDate(index = 0) {
        const timestamp = _.now()
        const date = new Date(timestamp)

        // 修改日期
        date.setDate(date.getDate() + index)

        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')

        return `${year}-${month}-${day}`
    }
    // 掛號註記
    getPMemo(val) {
        if (!val) return '無'
        return val.substr(-1) == ',' ? val.slice(0, -1) : val
    }
    // 取 時段 文字
    getInterval(interval) {
        if (interval == 1) {
            return i18n.t('apptMgmt.am')
        } else if (interval == 2) {
            return i18n.t('apptMgmt.pm')
        } else if (interval == 3) {
            return i18n.t('apptMgmt.night')
        } else if (interval == 0) {
            return i18n.t('apptMgmt.emergency')
        } else {
            return ''
        }
    }
    // 跳通知
    notify(data) {
        const default_params = {
            duration: 5000,
            position: 'top-center',
            color: 'grey',
        }
        const payload = _.merge({}, default_params, data)
        Vue.prototype.$vs.notify(payload)
    }
    arrayToDecimal(array) {
        if (!array) return null

        return array.reduce((acc, val) => acc + (1 << val), 0)
    }
    decimalToArray(index) {
        const atemp = Array.from({ length: 14 }, (_, i) => i)
        const array = []

        for (let i = 0; index > 0; i++, index >>= 1) {
            if (index & 1) {
                array.push(atemp[i])
            }
        }

        return array
    }
}

export default new common()
