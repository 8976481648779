export default {
  actionIcon: 'StarIcon',
  highlightColor: 'warning',
  data: [
    {index: 0, label: '儀錶板1',   url: '/',      labelIcon: 'HomeIcon',     highlightAction: false},
    {index: 1, label: '掛號管理', url: '/reservation', labelIcon: 'FileIcon', highlightAction: false},
    {index: 2, label: '會員管理', url: '/members', labelIcon: 'FileIcon', highlightAction: false},
    {index: 3, label: '諮詢紀錄', url: '/consultation', labelIcon: 'FileIcon', highlightAction: false},
    {index: 4, label: '訊息管理', url: '/message', labelIcon: 'FileIcon', highlightAction: false},
    {index: 5, label: '系統設定', url: '/setting', labelIcon: 'FileIcon', highlightAction: false},
  ]
}
